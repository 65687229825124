import React from 'react'
import { BoxInfo, DataList } from 'components'
import useColumns from 'pages/familyGroup/list/components/boxStatusFamilyGroup/hooks/useColumns'
import { CollectionStatusFamilyGroup } from 'pages/familyGroup/list/hooks/useList'
import { Text } from 'vkit/lib/components'

type BoxStatusFamilyGroupProps = {
  collectionStatusFamilyGroup?: CollectionStatusFamilyGroup
  loading?: boolean
}

export const BoxStatusFamilyGroup: React.FC<BoxStatusFamilyGroupProps> = (
  {
    collectionStatusFamilyGroup,
    loading
  }
) => (
  <BoxInfo
    header={
      <Text
        color='default'
        icon='people-outline'
        iconColor='default'
        rounded
        size='small'
        title
        value={!loading && collectionStatusFamilyGroup?.title ? collectionStatusFamilyGroup.title : ''}
      />
    }
  >
    <DataList
      columns={useColumns()}
      data={
        !loading && collectionStatusFamilyGroup?.title
          ? collectionStatusFamilyGroup.familyGroup
          : []
      }
      linesLoader={3}
      noPagination
    />
  </BoxInfo>
)

export default BoxStatusFamilyGroup
