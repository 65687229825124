import React from 'react'
import { BoxInfo, DataTable } from 'components'
import ButtonDownload from './components/buttonDownload'
import ICONS from 'shareds/constants/icons'
import { MoveAttachment } from 'services/moves/endpoints/moves'

interface AttachmentsProps {
  attachments?: MoveAttachment[]
}

const MoveAttachmentsTable: React.FC<AttachmentsProps> = (
  {
    attachments
  }
) => (
  <BoxInfo
    title='Anexos'
    icon={ICONS.ATTACHMENTS}
  >
    <DataTable
      noPagination
      loading={!attachments}
      data={attachments}
      elevation={0}

      columns={[
        {
          title: 'NOME DO ARQUIVO',
          path: 'name',
        }
      ]}

      actionsByLine={(attachment: MoveAttachment) => (
        <ButtonDownload
          label='Baixar'
          solo
          outlined
          attachment={attachment}
          iconColor='default'
        />
      )}
    />
  </BoxInfo>
)

export default MoveAttachmentsTable
