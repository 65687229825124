import { checkPermission, PERMISSIONS } from 'utils/checkPermission'

interface ObjectWithPermissions {
  [key: string]: any
  permissions?: PERMISSIONS[]
}

const verifyPermissions = (objectWithPermissions: ObjectWithPermissions): boolean => {
  if (!objectWithPermissions.permissions || objectWithPermissions.permissions.length === 0) {
    return true
  }

  return objectWithPermissions.permissions.some((permission) => checkPermission(permission))
}

export default verifyPermissions
