import React from 'react'
import { Row } from 'vkit/lib/context'
import { Text } from 'vkit/lib/components'
import { Column } from 'components/dataList/hooks/useContentList'
import { ObjectType } from 'shareds/types'
import { Negotiation } from 'services/negotiations/endpoints/negotiations'

import ListActions from '../Components/ListActions/ListActions';

type useContentListProps = {
  onDelete?: Function
  loading?: ObjectType
  contractCode?: string
}

const useColumns = ({ onDelete, loading, contractCode }: useContentListProps): Column[] => ([
  {
    title: 'PRODUTO',
    showOnMobile: true,
    value: function renderColumn({ product }: Negotiation) {
      return (
        <Row style={{ padding: 0 }}>
          <Text value={product?.name || 'Produto não informado'} size="small"/>
        </Row>
      )
    },
  },
  {
    title: 'CENTRO DE CUSTO',
    value: function renderColumn({ costCenter }: Negotiation) {
      return (
        <Row style={{ padding: 0 }}>
          <Text value={costCenter?.name || 'Centro de custo não informado'} size="small"/>
        </Row>
      )
    },
  },
  {
    title: 'BENEFÍCIO',
    width: '15%',
    value: function renderColumn({ product }: Negotiation) {
      return (
        <Row style={{ padding: 0 }}>
          <Text value={product?.benefit?.name || 'Benefício não informado'} size="small"/>
        </Row>
      )
    },
  },
  {
    title: '',
    align: 'right',
    width: '90px',
    showOnMobile: true,
    value: function renderColumn({ id, product, costCenter }: ObjectType) {
      return (
        <ListActions
          idNegotiation={ id }
          productName={ product?.name }
          contractCode={ contractCode }
          costCenterName={ costCenter?.name }
          onDelete={ () => { onDelete?.(id) } }
          loading={ loading?.[id] }
        />
      )
    },
  }
])

export default useColumns
