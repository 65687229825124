import React, { useMemo } from 'react'
import {
  ChipSubstitle,
  DataTable,
  GridGroup,
  SelectValidate,
  TextFieldValidate,
  WrapperPage,
} from 'components'
import { Text } from 'vkit/lib/components'
import { Contract, contractsService } from 'services/negotiations/endpoints/contracts'
import { addMinutes, isBefore, parseISO } from 'date-fns'
import { getToken } from 'utils/authentication'

interface ChipStatusProps {
  contract: Contract
}

const isExperied = (validUntil: string): boolean => {
  const parsedDate = parseISO(validUntil)
  return isBefore(addMinutes(parsedDate, parsedDate.getTimezoneOffset()), new Date())
}

const ChipStatus: React.FC<ChipStatusProps> = ({ contract }) => {
  const status = useMemo(() => {
    const isCancelled = contract.status === 'cancelled'

    if (isExperied(contract.validUntil!)) {
      return 'expired'
    }

    if (isCancelled) {
      return 'cancelled'
    }

    return 'active'
  }, [contract])

  return (
    <ChipSubstitle
      term={status}
      substitles={{
        active: {
          text: 'Ativo',
          color: 'success',
        },
        cancelled: {
          text: 'Cancelado',
          color: 'danger',
        },
        expired: {
          text: 'Expirado',
          color: 'warning',
        }
      }}
    />
  )
}

const List: React.FC = () => {
  return (
    <WrapperPage>
      <DataTable
        requestResponseRootPath='results'
        fnRequest={contractsService}

        filter={{
          searchField: ({ useValues, onChange }) => (
            <GridGroup
              body={[
                {
                  component: (
                    <TextFieldValidate
                      small
                      name='q'
                      iconDir='right'
                      icon='search-outline'
                      label='Buscar'
                      useValues={useValues}
                      onChange={onChange}
                    />
                  )
                },
                {
                  component: (
                    <TextFieldValidate
                      small
                      icon='search-outline'
                      iconDir='right'
                      name='code'
                      onChange={onChange}
                      label='Código'
                      useValues={useValues}
                    />
                  )
                },
                {
                  component: (
                    <SelectValidate
                      small
                      autoRequest
                      label='Modelo de contrato'
                      name='modelId'
                      onChange={onChange}
                      requestResponseRootPath='data'
                      requestResponseText='name'
                      requestResponseValue='id'
                      requestRouter='/contract-models'
                      requestUri={process.env.REACT_APP_API_NEGOTIATIONS_URL}
                      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
                      useValues={useValues}
                    />
                  )
                }
              ]}
            />
          )
        }}

        columns={[
          {
            custom: ({ code, note }: Contract) => (
              <>
                {code}
                {note && (<Text padding='5px 0 0' value={note} />)}
              </>
            ),
            path: 'code',
            sortable: true,
            title: 'Código',
          },
          {
            path: 'model.name',
            title: 'Modelo',
          },
          {
            custom: (contract: Contract) => (
              <ChipStatus contract={contract} />
            ),
            title: 'Status',
            width: '10%',
          },
          {
            path: 'validFrom',
            sortable: true,
            title: 'Início da vigência',
            type: 'dateWithouTZ',
          },
          {
            path: 'validUntil',
            sortable: true,
            title: 'Fim da vigência',
            type: 'dateWithouTZ',
          },
          {
            path: 'createdAt',
            sortable: true,
            title: 'Criado em',
            type: 'dateTime',
          },
        ]}
      />
    </WrapperPage>
  )
}

export default List
