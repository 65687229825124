import React from 'react'

import { Radio, Text } from 'vkit/lib/components'
import { Grid } from 'vkit/lib/context'

import { UploadContextType } from '../../types/UploadContext.types';
import { UploadContext } from '../../context/UploadContext';

import { MOVES_TYPES } from '../../enum/MovesType';
import { FormDataSteps } from '../../enum/FormDataSteps';

const SelectType: React.FC<React.ReactNode> = () => {

  const { setData, formData, isLoading } = React.useContext(UploadContext) as UploadContextType;

  const [options, setOptions] = React.useState<React.ReactChild[]>();

  const setSelecteTypeOptions = React.useCallback(() => {
    const opt = Object.entries(MOVES_TYPES).map(([value, label]) => {
      return (
        <Radio
          disabled={ isLoading }
          key={ value }
          label={ label }
          checked={formData?.movimentationType === value}
          onChange={() => setData(FormDataSteps.movimentationType, value)}
          value={ value }
        />
      );
    });
    setOptions(opt);
  }, [formData, setData, isLoading]);

  React.useEffect(() => {
    setSelecteTypeOptions();
  }, [setSelecteTypeOptions])

  return (
    <div>
      <Text
        value='Selecione o tipo de movimentação'
        title
        padding='0 0 18px'
      />
      <Grid>
        { options }
      </Grid>
    </div>
  )
};

export default SelectType;
