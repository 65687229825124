import restfulBase from 'services/accessControl/base'

export interface AuthPasswordRecovery {
  email: string
}

export const authPasswordRecoveryInitState = {
  email: '',
}

export const authPasswordRecoveryService = restfulBase<AuthPasswordRecovery>('auth/password-recovery')
