import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { ObjectType } from 'shareds/types'
import { removeInvalids } from 'utils'
import { useHistoryNavigator } from 'navigation'

export type TableType = 'lines' | 'boxes'

export interface RESTProtocolPaginatedResponse<T = ObjectType> {
  currentPage?: number
  data: T[]
  totalPages: number
  [key: string]: any
}

const RESTProtocolResponseInitialState = {
  data: [],
  totalPages: 1,
}

export interface RESTProtocol<T = ObjectType> {
  index: (filter: ObjectType) => Promise<RESTProtocolPaginatedResponse<T>>
}

interface UseDataListProps {
  fnRequest?: RESTProtocol
  totalPages?: number
  tableType?: TableType
  requestResponseRootPath?: string
}

interface IUseDataList {
  dataTableRef: MutableRefObject<HTMLDivElement | null>
  refreshData: (updateFilters?: ObjectType) => Promise<void>
  onChangeFilter: Function
  onChangeSort: (value?: string | null) => void
  onPaginate: Function
  setFilters: Function
  useData: ObjectType[]
  useFilters: ObjectType
  useLoading: boolean
  useTotalPages?: number
  useTotalRows?: number
}

export interface QueryString {
  filters?: ObjectType
  page: string
  pageSize: string
  sort?: string
}

const queryStringInitialValues = {
  filters: {},
  page: '1',
  pageSize: '10',
  sort: '',
}

const useDataList = (
  {
    fnRequest,
    requestResponseRootPath,
  }: UseDataListProps
): IUseDataList => {
  const [useLoading, setLoading] = useState(false)
  const [useFilters, setFilters] = useState<ObjectType>({})
  const [useOldQuery, setOldQuery] = useState<ObjectType>({})
  const [useData, setData] = useState<ObjectType[]>([])
  const [useTotalRows, setTotalRows] = useState(0)
  const [useTotalPages, setTotalPages] = useState(0)
  const { search, pathname } = useLocation()
  const navigate = useHistoryNavigator()
  const timeoutRef = useRef(0)
  const lastPathname = useRef(pathname)
  const dataTableRef = useRef<HTMLDivElement>(null)

  const getQuery = useCallback((): QueryString => {
    if (!search) return queryStringInitialValues
    const queryString = search.substring(1)
    const foundQuery = qs.parse(queryString, { comma: true, allowDots: true })

    return {
      ...queryStringInitialValues,
      ...foundQuery,
    }
  }, [search])

  const {
    filters,
    page,
    pageSize,
    sort
  } = useMemo(getQuery, [getQuery]) as QueryString

  const getData = useCallback(async (updateFilters): Promise<RESTProtocolPaginatedResponse> => {
    const filters = removeInvalids(updateFilters)
    return fnRequest?.index?.(filters) || RESTProtocolResponseInitialState
  }, [fnRequest])

  const loadData = useCallback(async (updateFilters = {}): Promise<void> => {
    try {
      if ([1,2].includes(updateFilters.q?.length)) {
        return
      }

      setLoading(true)
      const response = await getData(updateFilters)
      const data = response[requestResponseRootPath || 'data']
      setData(data || [])
      setTotalRows(response.totalRows)
      setTotalPages(response.totalPages)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [getData, requestResponseRootPath])

  const refreshData = useCallback(async (updateFilters?: ObjectType): Promise<void> =>
    loadData({
      ...filters,
      page,
      pageSize,
      sort,
      ...updateFilters,
    }), [
      loadData,
      filters,
      page,
      pageSize,
      sort
    ])

  const addQueryString = useCallback((params: ObjectType): void => {
    const queryString = getQuery()
    const search = {
      ...queryString,
      ...params,
    }
    navigate.search(search)
  }, [navigate, getQuery])

  const addQueryStringDebounce = useCallback((params: ObjectType) => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = window.setTimeout(() => {
      addQueryString(params)
    }, 500)
  }, [addQueryString])

  const onPaginate = (option: ObjectType): void => {
    addQueryString({
      page,
      pageSize,
      ...option,
    })
  }

  const onChangeFilter = (updateFilters: ObjectType): void => {
    const filters = removeInvalids(updateFilters)
    setFilters(filters)

    addQueryStringDebounce({
      filters,
      page: 1,
    })
  }

  const onChangeSort = (value?: string | null): void => {
    addQueryString({
      sort: value,
    })
  }

  useEffect(() => {
    const params: ObjectType = {
      ...filters,
      page,
      pageSize,
      sort,
    }

    const isDiff = Object
      .entries({
        ...params,
        ...useOldQuery,
      })
      .some(([key, value]) => useOldQuery[key] !== value || params[key] !== value)

    if (lastPathname.current !== pathname || !isDiff) {
      return
    }

    if (page !== useOldQuery.page || pageSize !== useOldQuery.pageSize) {
      const scrollbar = document.querySelector('[class*="page_pages"] [class*="scrollbar_content"]')
      if (scrollbar && dataTableRef.current) {
        const clientDataTable = dataTableRef.current.getBoundingClientRect()
        const clientScroolbarChildren = scrollbar?.children?.[0].getBoundingClientRect()
        const scrollTop =
          Math.abs(clientScroolbarChildren?.top) - Math.abs(clientDataTable.top) - 30

        if (clientScroolbarChildren?.top && clientDataTable.top < 0) {
          scrollbar.scrollTop = scrollTop
        }
      }
    }

    setOldQuery(params)
    loadData?.(params)
  }, [
    filters,
    page,
    pageSize,
    sort,
    loadData,
    pathname,
    useOldQuery,
  ])

  useEffect(() => {
    if (!filters) {
      return
    }

    setFilters(filters)
  }, [filters])

  return {
    dataTableRef,
    onChangeFilter,
    onChangeSort,
    onPaginate,
    refreshData,
    setFilters,
    useData,
    useFilters,
    useLoading,
    useTotalPages,
    useTotalRows,
  }
}

export default useDataList
