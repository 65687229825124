import React from 'react'
import {
  BoxInfo,
  FormValidate,
  GridGroup,
  SelectValidate, ShowHide,
  TextFieldValidate,
  ToastStatusGlobal,
  WrapperPage,
} from 'components'
import { Grid, Row } from 'vkit/lib/context'
import { Button } from 'vkit/lib/components'
import { SelectOption } from 'shareds/types'
import { getToken } from 'utils/authentication'
import { List } from './components'
import useCreateOrUpdate from './hooks/useCreateOrUpdate'

const CreateOrUpdate: React.FC = () => {
  const { toast } = ToastStatusGlobal()

  const {
    addFinancialAdjustment,
    addSelected,
    create,
    deleteFinancialAdjustment,
    onChangeForm,
    useFinancialAdjustments,
    useForm,
    useLoading,
  } = useCreateOrUpdate()

  return (
    <WrapperPage>
      <BoxInfo
        icon='shopping-bag-outline'
        title='Dados do ajuste financeiro'
      >
        <FormValidate
          resource={useForm}
          fields={{
            contractId: ['required'],
            description: ['required'],
            countMonths: [],
            portionType: ['required'],
            unit: ['required'],
            date: ['required'],
            value: ['required'],
          }}
          onChangeForm={onChangeForm}
          onError={() => { toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning') }}
          onSubmit={addFinancialAdjustment}
          body={({ onBlur, onChange, useErrors, useValues }) => (
            <GridGroup
              body={[
                {
                  component: (
                    <SelectValidate
                      key={JSON.stringify(useForm.contract?.contractId || 'key')}
                      autoRequest
                      label='Contrato'
                      name='contractId'
                      onBlur={onBlur}
                      onChange={
                        (name: string, value: string, option: SelectOption) => {
                          onChange(name, value)
                          addSelected('contracts', option)
                        }
                      }
                      placeholder='Selecione um contrato'
                      requestPageParam='page'
                      requestResponseRootPath='data.results'
                      requestResponseText='code'
                      requestResponseValue='id'
                      requestRouter='/contracts'
                      requestSearchParam='q'
                      requestUri={process.env.REACT_APP_API_NEGOTIATIONS_URL}
                      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
                      required
                      searchable
                      useErrors={useErrors}
                      useValues={useValues}
                    />
                  ),
                },

                {
                  component: (
                    <TextFieldValidate
                      label='Data'
                      name='date'
                      onBlur={onBlur}
                      onChange={onChange}
                      useErrors={useErrors}
                      useValues={useValues}
                      type='date'
                      required
                    />
                  ),
                },

                {
                  component: (
                    <TextFieldValidate
                      label='Quantidade de meses'
                      name='countMonths'
                      onBlur={onBlur}
                      onChange={onChange}
                      useErrors={useErrors}
                      useValues={useValues}
                      mask='99'
                      placeholder='1'
                    />
                  ),
                },

                {
                  component: (
                    <SelectValidate
                      name='portionType'
                      label='Tipo de ajuste'
                      placeholder='Selecione um tipo de ajuste'
                      useValues={useValues}
                      onChange={onChange}
                      useErrors={useErrors}
                      data={[
                        {
                          text: 'Desconto',
                          value: 'discount',
                        },
                        {
                          text: 'Acréscimo',
                          value: 'charge',
                        },
                      ]}
                      required
                    />
                  ),
                },

                {
                  component: (
                    <SelectValidate
                      name='unit'
                      label='Tipo de valor'
                      placeholder='Selecione um tipo de valor'
                      useValues={useValues}
                      onChange={onChange}
                      useErrors={useErrors}
                      data={[
                        {
                          text: 'Porcentagem',
                          value: 'percentage',
                        },
                        {
                          text: 'Moeda',
                          value: 'currency',
                        },
                      ]}
                      required
                    />
                  ),
                },

                {
                  component: (
                    <TextFieldValidate
                      label='Valor'
                      name='value'
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder={useValues.unit === 'currency' ? 'R$ 0,00' : '0%'}
                      useErrors={useErrors}
                      useValues={useValues}
                      money={useValues.unit === 'currency'}
                      required
                      mask={
                        useValues.unit === 'currency'
                          ? 'R$ 99.999,99'
                          : '999999999'
                      }
                    />
                  ),
                },

                {
                  middle: 100,
                  default: 100,
                  component: (
                    <TextFieldValidate
                      label='Descrição'
                      name='description'
                      onBlur={onBlur}
                      onChange={onChange}
                      useErrors={useErrors}
                      useValues={useValues}
                      multiline
                      maxLength={256}
                      required
                    />
                  ),
                },
              ]}
            />
          )}
          buttons={{
            submit: {
              loading: useLoading.addNegotiastion,
              text: 'Adicionar ajuste financeiro',
            },
          }}
        />
      </BoxInfo>

      <ShowHide transition='slideToDown' visible={!!useFinancialAdjustments.length}>
        <List
          useData={useFinancialAdjustments}
          onDelete={deleteFinancialAdjustment}
        />

        <Row style={{ padding: '36px 0 0' }}>
          <Grid alignContent='right'>
            <Button
              icon='save-outline'
              label='Salvar'
              size='larger'
              submit
              onClick={() => create(useFinancialAdjustments)}
              loading={useLoading.create}
            />
          </Grid>
        </Row>
      </ShowHide>
    </WrapperPage>
  )
}

export default CreateOrUpdate
