import React from 'react'
import { WrapperPage } from 'components'
import { Row } from 'vkit/lib/context'
import useContracts from './hooks/useContracts'
import { useToastStatus } from 'shareds/hooks'
import ContractList from 'modules/contracts/list'

const Index: React.FC = () => {
  const { useToast, toast } = useToastStatus()

  const {
    useLoading,
    useData,
  } = useContracts({
    onCallToast: useToast
  })

  return (
    <WrapperPage>
      {toast}

      <Row style={{ padding: 0, height: window.innerHeight - 70 }}>
        <ContractList
          data={useData}
          useLoading={useLoading}
        />
      </Row>
    </WrapperPage>
  )
}

export default Index
