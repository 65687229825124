import React, { ReactNode, useCallback } from 'react'
import { TextField } from 'vkit/lib/components'
import { getCurrencyFormat, maskText } from 'utils/format'

enum StatusEnum {
  success = 'success',
  error = 'error',
}

type ObjectType = { [key: string]: string | null }

interface TextFieldValidateInterface {
  disabled?: boolean
  header?: ReactNode
  icon?: string
  iconDir?: 'left' | 'right'
  label?: string
  loading?: boolean
  mask?: string
  maxLength?: number
  money?: boolean
  multiline?: boolean
  name: string
  onBlur?: Function
  onChange?: Function
  placeholder?: string
  required?: boolean
  small?: boolean
  type?: 'number' | 'text' | 'cep' | 'cnpj' | 'cpf' | 'date' | 'password' | 'range' | 'phone'
  useErrors?: ObjectType
  useValues: ObjectType
}

const FieldTextValidate: React.FC<TextFieldValidateInterface> = (
  {
    disabled,
    header,
    icon,
    iconDir,
    label,
    loading,
    mask,
    maxLength,
    money,
    multiline,
    name,
    onBlur,
    onChange,
    placeholder,
    required,
    small,
    type = 'text',
    useErrors,
    useValues,
  }
) => {
  const formatTextField = useCallback(
    (value: string, key: string): string => {
      if (!value) {
        return value
      }

      if (key === 'phone') {
        const newValue = value.replace(/^55/g, '')
        return mask ? maskText(newValue, mask) : newValue
      }

      if (money) {
        const newValue = String(value).replace(/\D/g, '')
        return getCurrencyFormat(+newValue, 'BRL', true)
      }

      return mask ? maskText(`${value}`, mask) : value
    },
    [mask, money],
  )

  const formatTextPayload = useCallback(
    (value: any, key: string): string => {
      if (!value) {
        return value
      }

      if (key === 'phone') {
        const hasDDD = /^55/.test(value)
        const newValue = value.replace(/\D/g, '')
        return hasDDD ? newValue : `55${newValue}`
      }

      if (money) {
        const newValue = value.replace(/\D/g, '')
        let newValueSplited = String(newValue).split('')
        if (newValueSplited.length === 1) newValueSplited.unshift('0')
        newValueSplited.splice(-2, 0, '.')
        return String((+newValueSplited.join('')).toFixed(2))
      }

      return mask ? value.replace(/\D/g, '') : value
    },
    [mask, money],
  )

  return (
    <TextField
      clearable
      disabled={disabled}
      icon={icon}
      iconDir={iconDir}
      header={header}
      label={label}
      loading={loading}
      mask={mask}
      maxLength={maxLength}
      multiline={multiline}
      onBlur={(value: string) => !disabled && onBlur?.(name, formatTextPayload(value, type))}
      onChange={(value: string) =>  !disabled && onChange?.(name, formatTextPayload(value, type))}
      placeholder={placeholder}
      required={required}
      small={small}
      status={!disabled && useErrors?.[name] ? StatusEnum.error : undefined}
      textHelper={!disabled ? useErrors?.[name] ?? '' : undefined}
      value={!disabled ? formatTextField(useValues[name] ?? '', type) : ''}
      {...(type !== 'phone' ? { type } : {})}
    />
  )
}

export default FieldTextValidate
