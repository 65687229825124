import React from 'react'

import { Text } from 'vkit/lib/components'

import { MarkupsContext } from 'modules/markups/context/MarkupsContext'
import { MarkupContextType, Markup } from 'modules/markups/types'
import MarkupItem from '../MarkupItem/MarkupItem'
import style from './MarkupsList.module.scss';

const MarkupsList: React.FC = () => {
  const { markups, loading } = React.useContext(MarkupsContext) as MarkupContextType;

  if (!markups.length && !loading) {
    return (
      <>
        <Text
          value='Atenção'
          title
          size='larger'
          iconColor='danger'
          color='danger'
        />
        <Text
          value='Aconteceu algum problema e não conseguimos recuperar a lista de markups neste momento.'
          size='medium'
        />
        <Text
          value='Por favor tente novamente em instantes.'
          size='medium'
        />
      </>
    )
  }

  return (
    <div className={ style.MarkupList }>
      {
        markups.map((item: Markup, index: number) => (
          <MarkupItem
            isFirstItem={ index === 0 ? true : false }
            key={ item.id }
            id={ item.id }
            ageBegin={ item.ageBegin }
            ageEnd={ item.ageEnd }
            markup={ item.markup }
          />
        ))
      }
    </div>
  );
}

export default MarkupsList;
