
export const getFileNameByType = (moveType: string): string => {
  if (moveType === 'creation') {
    return 'inclusao'
  }
  if (moveType === 'removal') {
    return 'exclusao'
  }
  return 'alteracao'
}
