import React from 'react'
import { MovesValidationErrorDetail } from 'services/fileMoves/endpoints/movesValidation'
import { DataList, Space } from 'components'
import { Button, Text } from 'vkit/lib/components'
import translateRulesValidator from 'utils/translateRulesValidator'
import { Grid } from 'vkit/lib/context'

export interface ErrosProps {
  details: MovesValidationErrorDetail[]
  setUploadFormStatus: Function
}

const Errors: React.FC<ErrosProps> = (
  {
    details,
    setUploadFormStatus,
  }
) => {
  return (
    <>
      <Text
        applyBaseColor
        color="danger"
        fontWeight="bold"
        icon='alert-triangle-outline'
        iconColor='danger'
        margin='0 0 16px'
        padding='8px 16px'
        rounded
        size="larger"
        value={`Não foi possível realizar a operação.
          O sistema detectou inconsistência em um ou mais campos.
          Por favor, corrija os dados da planilha e tente novamente.`}
      />

      <DataList
        noPagination
        elevation={4}
        data={details}
        columns={[
          {
            darken: true,
            title: 'Linha',
            value: ({ field }: MovesValidationErrorDetail) => field.split('.')[0],
          },
          {
            darken: true,
            title: 'Coluna',
            value: ({ field }: MovesValidationErrorDetail) => field.split('.')[1],
          },
          {
            darken: true,
            title: 'Valor encontrado',
            value: 'value',
          },
          {
            darken: true,
            title: 'Valor esperado',
            value: ({ rule, details }: MovesValidationErrorDetail) =>
              translateRulesValidator(rule, details?.expected),
          },
        ]}
      />

      <Space horizontal />

      <Grid alignContent='right'>
        <Button
          label='Enviar novo arquivo'
          onClick={() => setUploadFormStatus('form')}
        />
      </Grid>
    </>
  )
}

export default Errors
