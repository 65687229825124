import restfulService from 'utils/restful'

type Param = { [key: string]: any }

export const restfulBase = (
  service: string,
): {
  send: Function,
} => ({
  send: async (params: Param): Promise<any> => {
    const api = restfulService({
      uri: process.env.REACT_APP_API_FILES_URL || '',
      service
    })

    api.apiInstance.interceptors.request.use((config) => ({
      ...config,
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }))

    return api.post(params)
  },
})

export default restfulBase
