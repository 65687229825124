import React from 'react'

import { Text } from 'vkit/lib/components'
import { FileUpload } from 'components'

import { UploadContextType } from '../../types/UploadContext.types';
import { UploadContext } from '../../context/UploadContext';
import { ObjectType } from 'shareds/types';
import { FormDataSteps } from '../../enum/FormDataSteps';

type UploadFileProps = {
  isAttachment?: boolean
}

type FileUploadConfig = {
  name: string,
  extensions: string[]
}

const SelectType: React.FC<UploadFileProps> = ({
  isAttachment
}) => {

  const [ fileUploadConfig ] = React.useState<FileUploadConfig>({
    name: isAttachment ? FormDataSteps.attachment : FormDataSteps.files,
    extensions: isAttachment ? ['zip'] : ['xls', 'xlsx']
  })

  const useTitle = React.useMemo((): string => {
    return isAttachment ? 'Selecione o arquivo de anexo' : 'Selecione a planilha correspondente'
  }, [isAttachment]);

  const useTextHelper = React.useMemo((): string => {
    return isAttachment ?
      'de anexo a sua movimentação <br /> <b> para atualização.</b>'
      : 'de movimentação <br /> <b> para atualização.</b>'
  }, [isAttachment])

  const { setData } = React.useContext(UploadContext) as UploadContextType;

  const useError = React.useState<ObjectType>({})

  const onChangeFileUpload = (name: string, value: string, isValid: boolean): void => {
    if (isValid) {
      setData(name, value);
    }
  }

  return (
    <div>
      <Text
        value={ useTitle }
        title
        padding='0 0 18px'
      />
      <FileUpload
        name={ fileUploadConfig.name }
        textHelper={ useTextHelper }
        allowedExtensions={ fileUploadConfig.extensions }
        onChange={ onChangeFileUpload }
        formValidatorErrors={ useError }
      />
    </div>
  )
};

export default SelectType;
