import React from 'react'
import Colors from 'shareds/types/colors.type'
import { Chip } from 'vkit/lib/components'
import { Grid } from 'vkit/lib/context'

interface ChipSubstitleProps {
  term: string
  substitles: {
    [key: string]: {
      text: string
      color: Colors
    }
  }
}

const ChipSubstitle: React.FC<ChipSubstitleProps> = (
  {
    term,
    substitles = {}
  }
) => (
  <Grid>
    <Chip
      rounded
      size='small'
      label={substitles[term]?.text}
      color={substitles[term]?.color}
    />
  </Grid>
)

export default ChipSubstitle
