import { getFormattedDate, maskMobileNumber, maskPhoneNumber, maskText } from 'utils/format'
import translate from 'components/textData/utils'

export type FunctionKey =
  | 'boolean'
  | 'cardCode'
  | 'cep'
  | 'cnpj'
  | 'cpf'
  | 'date'
  | 'dateWithouTZ'
  | 'dateTime'
  | 'marital'
  | 'mobileNumber'
  | 'phoneNumber'
  | 'relationship'
  | 'rg'
  | 'sex'
  | 'text'

const formatText = (value: any, key: FunctionKey, textDefault = 'Não encontrado'): string => {
  if (!value && !['boolean', 'number'].includes(typeof value)) {
    return ''
  }

  return {
    boolean: translate.boolean,
    cardCode: (value: any) => maskText(value, '9999 '.repeat(20)),
    cep: (value: any) => maskText(value, '99999-999'),
    cnpj: (value: any) => maskText(value, '99.999.999/9999-99'),
    cpf: (value: any) => maskText(value, '999.999.999-99'),
    date: (value: any) => getFormattedDate(value),
    dateWithouTZ: (value: any) => getFormattedDate(value, { discardTimezone: true }),
    dateTime: (value: any) => getFormattedDate(value, { showHours: true }),
    marital: translate.marital,
    mobileNumber: maskMobileNumber,
    phoneNumber: maskPhoneNumber,
    relationship: translate.relationship,
    rg: (value: any) => maskText(value, '99.999.999-9'),
    sex: translate.sex,
    text: (value: any) => value,
  }[key](value) || textDefault
}

export default formatText
