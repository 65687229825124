import { RefObject, useRef, useState } from 'react'
import { FormValidateHandler } from 'components/webForm/formValidate/types/formValidate.type'
import {
  MovesValidationError,
  movesValidationErrorInitialState,
  pcValidationService,
} from 'services/fileMoves/endpoints/movesValidation'
import { useHistoryParams } from 'navigation'
import { ToastStatusGlobal } from 'components'
import { getMessageError } from '../../../../utils/getMessageError'
import { get } from 'lodash'

type FormStatusTypes = 'error' | 'form' | 'success'

type FileUploadForm = {
  negotiationId: string
  file: string
}

export type FileError = {
  message: string
  data: any
}

interface FileUpload {
  useFileUploadFormValues: FileUploadForm
  setFileUploadForm: Function
  onSubmit: () => void
  formRef: RefObject<FormValidateHandler>
  useFileUploadFormStatus: FormStatusTypes
  setUploadFormStatus: Function
  useIsLoading: boolean
  useError: MovesValidationError
  resetSubmitError: () => void
}

const useCadastralPositionForm = (): FileUpload => {
  const paramsInitial = {
    negotiationId: '',
    file: '',
  }

  const { toast } = ToastStatusGlobal()
  const { companyId } = useHistoryParams()
  const formRef = useRef<FormValidateHandler>(null)

  const [useFileUploadFormStatus, setFileUploadFormStatus] = useState<FormStatusTypes>('form')
  const [useFileUploadFormValues, setFileUploadForm] = useState<FileUploadForm>(paramsInitial)
  const [useIsLoading, setIsLoading] = useState(false)
  const [useError, setError] = useState<MovesValidationError>(movesValidationErrorInitialState)

  const setUploadFormStatus = (status: FormStatusTypes): void => {
    setFileUploadFormStatus(status)
  }

  const mountFormData = async (): Promise<FormData> => {
    const formData = new FormData()

    formData.append('files', useFileUploadFormValues.file)
    formData.append('negotiationId', useFileUploadFormValues.negotiationId)

    return formData
  }

  const submitFormData = async (): Promise<any> => {
    try {
      const formData = await mountFormData()
      setIsLoading(true)
      await pcValidationService.send(formData)

      setFileUploadFormStatus('success')
    } catch (error) {
      console.error(error)
      const messageError = getMessageError({
        error,
        messageDefault: 'Falha ao fazer upload do arquivo!',
      })
      toast('Oops!', messageError, 'error')
      const errorResponse = get(error, 'response.data', movesValidationErrorInitialState)
      if (errorResponse.details.length) {
        setFileUploadFormStatus('error')
        setError(errorResponse)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = async (): Promise<void> => {
    if (!useIsLoading && companyId) {
      const isValid = await formRef.current?.submit()

      if (isValid) {
        await submitFormData()
      }
    }
  }

  const resetSubmitError = (): void => {
    setError(movesValidationErrorInitialState)
    setFileUploadFormStatus('form')
    setFileUploadForm({
      negotiationId: useFileUploadFormValues.negotiationId,
      file: '',
    })
  }

  return {
    useIsLoading,
    useFileUploadFormStatus,
    setUploadFormStatus,
    onSubmit,
    useError,
    formRef,
    useFileUploadFormValues,
    setFileUploadForm,
    resetSubmitError,
  }
}

export default useCadastralPositionForm
