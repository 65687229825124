import { useCallback, useEffect, useState } from 'react'
import { ObjectType } from 'shareds/types'
import { companiesService } from 'services/negotiations'

const useGetCompany = (companyId: string): {
  useCompany: ObjectType
} => {
  const [useCompany, setCompany] = useState<ObjectType>({})

  const companyDTO = (company: ObjectType): ObjectType => ({
    id: company.id,
    cnpj: company.cnpj || 'Não informado',
    name: company.name || 'Não informado',
    groupId: company.companyGroupId,
  })

  const loadCompany = useCallback(async (): Promise<void> => {
    if (!companyId) {
      return
    }

    const company = (await companiesService.find(companyId)) || {}

    setCompany(companyDTO(company))
  }, [companyId])

  useEffect(() => {
    (async () => loadCompany())()
  }, [loadCompany])

  return {
    useCompany,
  }
}

export default useGetCompany
