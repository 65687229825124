import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { Text } from 'vkit/lib/components'
import { DataType } from 'vkit/lib/shared/components/list/types/list.type'
import { getCurrencyFormat, getFormattedDate } from 'utils/format'
import StatusSaveNegotiation from '../components/statusSaveNegotiation'
import { Column } from 'components/dataList/hooks/useContentList'
import { FiedsForm } from 'pages/financialAdjustments/createOrUpdate/hooks/useCreateOrUpdate'

export type UseContentType = {
  onDelete: Function
}

const useContentList = ({
  onDelete,
}: UseContentType): {
  useDataList: DataType[]
  useListRef: MutableRefObject<HTMLDivElement | null>
} => {
  const [useDataList, setDataList] = useState<DataType[]>([])
  const useListRef = useRef(null)

  const getColumns = useCallback((): Column[] => ([
    {
      title: 'DATA',
      showOnMobile: true,
      value: function renderColumn({ date }: FiedsForm) {
        return (
          <Text value={getFormattedDate(date) || 'Data não informada'} title size='small' />
        )
      },
    },
    {
      title: 'VALOR',
      value: function renderColumn({ value, unit }: FiedsForm) {
        return (
          unit === 'percentage'
            ? <Text value={`${value}%`} size='small' />
            : <Text value={getCurrencyFormat(value, 'BRL')} size='small' />
        )
      },
    },
    {
      title: 'CONTRATO',
      value: function renderColumn({ contract }: FiedsForm) {
        return (
          <Text value={contract?.code || 'Contrato não informada'} size='small' />
        )
      },
    },
    {
      title: 'DESCRIÇÃO',
      value: function renderColumn({ description }: FiedsForm) {
        return (
          <Text value={description || 'Descrição não informada'} size='small' />
        )
      },
    },
    {
      title: 'AÇÕES',
      width: '90px',
      align: 'center',
      value: function renderColumn({ id, status }: FiedsForm) {
        return (
          <StatusSaveNegotiation
            status={status}
            onClick={() => onDelete(id)}
          />
        )
      },
    },
  ]), [
    onDelete,
  ])

  useEffect(() => {
    const columns = getColumns()
    setDataList(columns)
  }, [getColumns])

  return {
    useDataList,
    useListRef,
  }
}

export default useContentList
