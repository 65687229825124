import alertBrokerPortalDiscontinuation from 'pages/alertBrokerPortalDiscontinuation/alertBrokerPortalDiscontinuation';
import React from 'react'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { PublicRoute } from 'modules'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute restricted={false} component={alertBrokerPortalDiscontinuation} path='/alert-broker-portal-discontinuation' />
        <PublicRoute component={() => <Redirect to='/alert-broker-portal-discontinuation' />} path='/' />
      </Switch>
    </BrowserRouter>
  )
}

export default App
