import React, { useLayoutEffect, useRef, useState, ReactNode } from 'react'
import classesBuilder from 'utils/classesBuilder'
import style from './style/showHide.module.scss'

type ShowHideType = {
  absolute?: boolean
  children: ReactNode
  displayNone?: boolean
  order?: number
  transition?: 'slideToLeft' | 'slideToDown' | 'fade'
  visible: boolean
}

const ShowHide: React.FC<ShowHideType> = (
  {
    absolute,
    visible = false,
    displayNone,
    children,
    order = 1,
    transition = 'slideToLeft',
  }
) => {
  const [useShow, setShow] = useState(false)
  const [useVisibleOpacity, setVisibleOpacity] = useState(false)

  const showHideRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const timer = useRef(0)

  useLayoutEffect(() => {
    clearTimeout(timer.current)

    if (visible) {
      setShow(true)

      if (showHideRef?.current) {
        showHideRef.current.style.height = '0px'
      }

      window.setTimeout(() => {
        if (showHideRef?.current && contentRef?.current) {
          setVisibleOpacity(true)
          showHideRef.current.style.height = `${contentRef.current.offsetHeight}px`
        }
      }, (order - 1) * 100)

      window.setTimeout(() => {
        if (showHideRef?.current) {
          showHideRef.current.style.height = 'auto'
        }
      }, (order - 1) * 100 + 400)
    } else {
      if (showHideRef.current) {
        setVisibleOpacity(false)

        if (contentRef?.current) {
          showHideRef.current.style.height = `${contentRef.current.offsetHeight}px`
        }

        window.setTimeout(() => {
          if (showHideRef?.current && contentRef?.current) {
            showHideRef.current.style.height = '0px'
          }
        })
      }

      timer.current = window.setTimeout(() => setShow(false), 400)
    }
  }, [visible, order])

  return (
    <>
      {(useShow || displayNone) && (
        <div
          style={{ display: useShow ? 'block' : 'none' }}
          ref={showHideRef}
          className={classesBuilder(style, {
            absolute,
            showHide: true,
            visible: useVisibleOpacity,
            [transition]: transition,
          })}
        >
          <div ref={contentRef} className={style.contentShowHide}>
            {children}
          </div>
        </div>
      )}
    </>
  )
}

export default ShowHide
