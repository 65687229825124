import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import TextItemData from './components/textItemData'
import style from './style/textData.module.scss'
import { TextDataType } from './types/textData.type'

const TextData: React.FC<TextDataType> = (
  {
    data,
    loading,
    requestFn
  }
) => {
  const [useData, setData] = useState<unknown>()

  const getText = (values: unknown, path: string): string => {
    const value = get(values, path, '-');
    return value || value === 0 ? value : '-';
  }

  useEffect(() => {
    if (!requestFn) {
      return
    }

    (async () => {
      const data = await requestFn() || {}
      setData(data)
    })()
  }, [requestFn])

  return  (
    <div className={style.textData}>
      {data.map((item) => (
        !item.hidden ? (<TextItemData
          key={item.label}
          label={item.label}
          loading={loading || requestFn && !useData}
          text={requestFn && item.path ? getText(useData, item.path) : item.text ?? '-'}
          type={item.type}
          sizeDefault={item.sizeDefault}
          sizeMedium={item.sizeMedium}
          sizeMobile={item.sizeMobile}
          data={item.data}
          selected={item.selected}
          onSelectectChange={item.onSelectectChange}
          disabled={item.disabled}
        />): null
      ))}
    </div>
  )
}

export default TextData
