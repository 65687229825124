import { decodedJwtToken } from 'utils/authentication'

export enum PERMISSIONS {
  CONTRACT_CANCEL = 'contract:cancel',
  CONTRACT_REMOVE_FROM_COMPANY = 'contract:remove_from_company',
  MOVE_CREATE = 'movimentation:create',
  BROKER_USER_CREATE = 'broker_user:create',
  BROKER_USER_READ = 'broker_user:read',
  BROKER_USER_UPDATE = 'broker_user:update',
  BROKER_USER_DELETE = 'broker_user:delete',
  COMPANY_USER_CREATE = 'company_user:create',
  COMPANY_USER_READ = 'company_user:read',
  COMPANY_USER_UPDATE = 'company_user:update',
  COMPANY_USER_DELETE = 'company_user:delete',
  COMPANY_GROUP_UPDATE = 'company_group:update',
}

export const checkPermission = (permission: string): boolean => {
  const { user } = decodedJwtToken() || {}
  return (user?.permissions || []).includes(permission)
}
