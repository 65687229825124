import React, { ReactNode } from 'react'
import { Box, Grid } from 'vkit/lib/context'
import { Icon, Text } from 'vkit/lib/components'
import style from './style/boxInfo.module.scss'
import { classesBuilder } from 'utils'

type BoxInfoType = {
  children?: ReactNode
  header?: ReactNode
  footer?: ReactNode
  title?: any
  icon?: string
  margin?: string | number
  elevation?: number
  titleSticky?: boolean
}

const BoxInfo: React.FC<BoxInfoType> = (
  {
    children,
    elevation = 4,
    header,
    footer,
    icon ,
    margin,
    title,
    titleSticky,
  }
) => (
  <div
    className={style.boxInfo}
    style={{
      margin
    }}
  >
    <Box elevation={elevation} type='sheet' outlined rounded>
      <div className={style.content}>
        {
          (title || header) && (
            <>
              <div className={classesBuilder(style, {
                title: true,
                sticky: titleSticky,
              })}>
                <Grid alignItems='center'>
                  {icon && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 35,
                        height: 35,
                        marginRight: 8,
                        borderRadius: 6,
                        backgroundColor: 'rgba(0, 123, 255, 0.1)'
                      }}
                    >
                      <Icon
                        name={icon}
                        size={24}
                        color='default'
                      />
                    </div>
                  )}

                  {typeof title === 'string' ? (
                    <Text
                      color='default'
                      iconColor='default'
                      size='small'
                      title
                      value={title}
                    />
                  ): title}
                </Grid>

                {header}
              </div>
            </>
          )
        }

        <div className={style.childrenContent}>
          {children}
        </div>

        {footer && (
          <Grid padding='0 20px 20px' alignContent='right' alignItems='center'>
            {footer}
          </Grid>
        )}
      </div>
    </Box>
  </div>
)

export default BoxInfo
