import * as Contracts from 'pages/contracts'
import ICONS from 'shareds/constants/icons'

const route = {
  path: '/contracts',
  nameMenu: 'Contratos',
  icon: ICONS.CONTRACT,
  exact: true,
  component: Contracts.List,
}

export default route
