import React from 'react'
import { Row } from 'vkit/lib/context'
import { Button, Image, Text } from 'vkit/lib/components'
import { BoxInfo } from 'components'
import { useHistoryNavigator } from 'navigation'
import fileUploadImage from 'assets/images/file-upload.png'

interface SuccessFormFileUploadProps {
  callbackUrl?: string
  title?: string
  detail?: string
}

const SuccessFormFileUpload: React.FC<SuccessFormFileUploadProps> = (props) => {
  const useNavigation = useHistoryNavigator()

  return (
    <BoxInfo>
      <Row style={{ alignItems: 'center' }}>
        <Image
          src={fileUploadImage}
          height='86px'
          width='77px'
          type='rounded'
          alt='Ícone de arquivo de upload'
        />
        <Text title color='default' value={props.title || 'Tudo certo'} margin='16px 0 8px' />
        <Text
          fontWeight='bold'
          value={
            props.detail ||
            'O upload foi realizado com sucesso e<br />em alguns minutos seu arquivos estará disponível na listagem.'
          }
          textAlign='center'
          margin='0 0 16px'
        />
        {props.callbackUrl?.length && (
          <Button
            onClick={() => useNavigation.push(props.callbackUrl)}
            label='Ir para listagem'
            bold
          />
        )}
      </Row>
    </BoxInfo>
  )
}

export default SuccessFormFileUpload
