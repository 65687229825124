import * as CompanyGroups from 'pages/companyGroups'
import * as FinancialAdjustments from 'pages/financialAdjustments'
import { companyGroupsService } from 'services/negotiations'
import ICONS from 'shareds/constants/icons'

const route = {
  path: '/company-groups',
  nameMenu: 'Grupos de empresas',
  icon: 'grid-outline',
  exact: true,
  component: CompanyGroups.List,
  children: [
    {
      path: '/:companyGroupId',
      nameMenu: 'Grupo de empresas',
      icon: ICONS.COMPANY_GROUP,
      exact: true,
      component: CompanyGroups.List,
      breadcrumb: {
        accessId: 'companyGroups',
        requestFindLabelFn: companyGroupsService.find,
        requestFindLabelParamIdName: 'companyGroupId',
        requestFindLabelResponsePathText: 'name',
      },
      children: [
        {
          component: FinancialAdjustments.List,
          exact: true,
          icon: ICONS.FINANCIAL_ADJUSTMENTS,
          nameMenu: 'Ajustes financeiros',
          path: '/financial-adjustments',
          children: [
            {
              component: FinancialAdjustments.CreateOrUpdate,
              exact: true,
              icon: ICONS.FINANCIAL_ADJUSTMENTS,
              nameMenu: 'Criar ajuste financeiro',
              path: '/actions/create',
            },
          ]
        }
      ]
    }
  ]
}

export default route
