import React, { useCallback, useState } from 'react'
import { Button } from 'vkit/lib/components'
import ButtonType from 'vkit/lib/shared/components/button/types/button.type'
import { ToastStatusGlobal } from 'components/index'
import { MoveAttachment } from 'services/moves/endpoints/moves'

interface ButtonDownloadProps extends ButtonType {
  attachment: MoveAttachment
}

const ButtonDownload: React.FC<ButtonDownloadProps> = (
  {
   attachment,
    ...props
  }
) => {
  const [loading, setLoading] = useState(false)
  const { toast } = ToastStatusGlobal()
  
  const downloadFileLink = async (link: string, name: string): Promise<void> => {
    const linkElement = document.createElement('a')
    linkElement.download = name
    linkElement.href = link
    linkElement.target = '_blank'
    document.body.appendChild(linkElement)
    linkElement.click()
    document.body.removeChild(linkElement)
  }
  
  const download = useCallback(async () => {
    try {
      setLoading(true)
      await downloadFileLink(attachment.link, attachment.name)
    } catch (error) {
      console.error(error)
      toast('Oops', 'Falha no download do arquivo.', 'error')
    } finally {
      setLoading(false)
    }
  }, [attachment, toast])
  
  return (
    <Button
      loading={loading}
      onClick={download}
      {...props}
    />
  )
}

export default ButtonDownload
