import restfulService from 'utils/restful'
import { ObjectType } from 'shareds/types'
import { RESTProtocolPaginatedResponse } from 'components/dataTable/hooks/useDataList'
import { Param } from 'services/beneficiaries/base'

type ParamType = { [key: string]: any }

export interface RESTProtocolResponse<T = ObjectType> {
  data: T
}

export const restfulBase = <T = ObjectType>(
  resource: string,
): {
  index: (params: ParamType, uri?: string) => Promise<RESTProtocolPaginatedResponse<T>>
  findOne: (uri: string) => Promise<RESTProtocolResponse<T>>
  update: (id: string, params: Param) => Promise<any>
} => ({
  index: async (params: ParamType, uri?: string): Promise<any> => {
    let errorResponse = null

    const handleError = (error: Response): void => {
      errorResponse = error
    }

    const jwt = localStorage.getItem('token');
    const { get } = restfulService({
      uri: process.env.REACT_APP_API_MONITORING_URL || '',
      service: resource,
      headers: {
        authorization: `Bearer ${jwt}`,
      }
    })

    const response = await get(params, uri, handleError)

    if (!response) {
      throw new Error('Falha na requisição')
    }

    if (errorResponse) return errorResponse

    return {
      data: response.data,
      totalPages: response.totalPages,
    }
  },
  findOne: async (uri: string): Promise<RESTProtocolResponse<T>> => {
    let errorResponse = null

    const handleError = (error: Response): void => {
      errorResponse = error
    }

    const jwt = localStorage.getItem('token');
    const { get } = restfulService({
      uri: process.env.REACT_APP_API_MONITORING_URL || '',
      service: resource,
      headers: {
        authorization: `Bearer ${jwt}`,
      }
    })

    const response = await get({}, uri, handleError)

    if (!response) {
      throw new Error('Falha na requisição')
    }

    if (errorResponse) return errorResponse

    return {
      data: response.data,
    }
  },
  update: async (id: string, params: Param): Promise<any> => {
    const jwt = localStorage.getItem('token');
    const { update } = restfulService({
      uri: process.env.REACT_APP_API_MONITORING_URL || '',
      service: resource,
      headers: {
        authorization: `Bearer ${jwt}`,
      }
    })

    return update(params, id)
  }
})

export default restfulBase
