import restfulBase from 'services/monitoring/core/base'
import { BaseEntity } from 'shareds/types/baseEntity.type'

export interface MonitoringFileAttachment extends Omit<BaseEntity, 'deletedAt' | 'deletionReason'> {
  fileId: string
  name: string
  extension: string
  link: string
}
export interface MonitoringFileUser extends BaseEntity {
  name?: string
}

export enum MOVES_UPDATE_STATUS {
  NOT_INITIALIZED = 'not-initialized',
  IN_PROCESS = 'in-process',
  PROCESS_ERROR = 'process-error',
  PROCESSED_PROVIDER_ERROR = 'process-provider-error',
  PROCESSED = 'processed',
  REJECTED =  'rejected',
}

export interface MonitoringFile extends BaseEntity {
  companyId: string
  extension: string
  name: string
  lines: number
  link: string
  status: MOVES_UPDATE_STATUS
  type?: 'bradesco:creation' | 'bradesco:removal'
  attachments: MonitoringFileAttachment[]
  user?: MonitoringFileUser
  rejectionReason?: string
  uploadedAt: string
}

export const monitoringFilesInitialState: MonitoringFile = {
  companyId: '',
  extension: '',
  name: '',
  lines: 0,
  link: '',
  status: MOVES_UPDATE_STATUS.NOT_INITIALIZED,
  attachments: [],
  uploadedAt: ''
}

export const monitoringStatusType = {
  'not-initialized': 'Em processamento',
  'in-process': 'Em processamento',
  'process-error': 'Erro de processamento',
  'process-provider-error': 'Erro apontado pela operadora',
  'processed': 'Processado'
}

export const monitoringFilesTypes = {
  'bradesco:creation': 'Criação (Bradesco)',
  'bradesco:removal': 'Exclusão (Bradesco)',
}

export const monitoringFilesService = restfulBase<MonitoringFile>('files')

export default monitoringFilesService
