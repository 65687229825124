import restfulService from 'utils/restful'

type SortedField<T> = string & keyof T | `-${string & keyof T}`;
export type Param<T = any> = Partial<T> & {
  pageSize?: number
  page?: number
  sort?: SortedField<T>[]
}

export interface ListResponse<T> {
  currentPage?: number
  data: T[]
  totalPages: number
}

export const restfulBase = <T = any, F = any>(
  service: string,
): {
  create: Function
  find: Function
  list: Function
  listAll: Function
  remove: Function
  update: Function
  index: (params?: Param<F>, uri?: string) => Promise<ListResponse<T>>
} => {
  const {
    REACT_APP_API_BENEFICIARIES_URL = ''
  } = process.env

  const api = restfulService({
    service,
    uri: REACT_APP_API_BENEFICIARIES_URL,
  })

  api.apiInstance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }))

  return {
    create: async (params: Param, url?: string): Promise<any> => {
      const response = await api.post(params, url)
      return response?.data || response
    },

    find: async (id: string, params?: Param): Promise<any> => {
      const response = await api.get({ id, ...params })
      const list = response?.data?.results || response?.data || response
      return Array.isArray(list) && list.length ? list.pop() : null
    },

    list: async (params: Param, url?: string): Promise<any> => {
      const response = await api.get(params, url)
      return response?.data?.results || response?.data || response
    },

    listAll: async (params: Param, uri?: string): Promise<any> => {
      const restful = restfulBase(service)
      const { totalPages, data } = await restful.index(params, uri) || {}
      const pages = Array.from({ length: totalPages - 1 }, (_, num) => num + 2)

      for (const page of pages) {
        const items = await restful.list({ ...params, page }, uri) || {}
        data.push(...items)
      }

      return data
    },

    index: async (params?: Param<F>, uri?: string): Promise<ListResponse<T>> => {
      const response = await api.get(params, uri)
      return Array.isArray(response?.data) ? response : response?.data
    },

    remove: async (id: string, url?: string): Promise<any> =>
      api.remove(id, url),

    update: async (id: string, params: Param, uri?: string): Promise<any> =>
      api.update(params, id, uri),
  }
}

export default restfulBase
