import React, { ReactNode, useImperativeHandle, ForwardRefRenderFunction, CSSProperties } from 'react'
import useFormValidates from './hooks/useFormValidates'
import { Button } from 'vkit/lib/components'
import { Grid, Row } from 'vkit/lib/context'
import { validateNamesType, FormValidateHandler, useValidateType } from './types/formValidate.type'
import ColorsType from 'shareds/types/colors.type'
import { ObjectType } from 'shareds/types'

type Size = 'minor' | 'small' | 'medium' | 'larger' | 'xLarger';

interface Body {
  onChange: (...item: any) => void
  onBlur: (...item: any) => void
  useErrors: ObjectType
  useValidate?: useValidateType
  useValues: ObjectType
  updateFieldErrors?: (...item: any) => void
}

interface FormValidateProps {
  objectName?: string
  resource: any
  resourceDefault?: any
  fields: {
    [key: string]: (validateNamesType | Function)[]
  }
  body: (item: Body) => ReactNode
  footer?: ReactNode
  onBeforeSubmit?: Function
  onSubmit?: Function
  onChangeForm?: Function
  onBack?: Function
  onError?: Function
  buttons?: {
    style?: CSSProperties
    submit?: {
      color?: ColorsType
      icon?: string
      iconDir?: 'left' | 'right'
      loading?: boolean
      size?: Size
      text?: string
    }
    back?: {
      color?: ColorsType
      icon?: string
      iconDir?: 'left' | 'right'
      size?: Size
      text?: string
    }
  }
  clearNextFields?: boolean
}

const FormValidate: ForwardRefRenderFunction<FormValidateHandler, FormValidateProps> = (
  props,
  forwardedRef,
) => {
  const {
    objectName,
    resource,
    resourceDefault,
    buttons,
    fields,
    body,
    onChangeForm,
    onSubmit,
    onBack,
    onBeforeSubmit,
    onError,
    clearNextFields,
    footer,
  } = props

  const {
    onBlur,
    onChange,
    onValidateForm,
    useErrors,
    useValidate,
    useValues,
    updateFieldErrors
  } = useFormValidates({
    fields,
    fnCallback: onSubmit,
    onChangeForm,
    onError,
    onBeforeValidateForm: onBeforeSubmit,
    objectName,
    resource,
    resourceDefault,
    clearNextFields,
  })

  useImperativeHandle(forwardedRef, () => ({
    async submit() {
      return onValidateForm?.()
    }
  }))

  return (
    <form onSubmit={onValidateForm} autoComplete='off'>
      {body({
        onBlur,
        onChange,
        useErrors,
        useValidate,
        useValues,
        updateFieldErrors
      })}

      {buttons && (
        <Row style={buttons.style || { padding: '36px 0 0' }}>
          <Grid alignContent='right'>
            {buttons.back && (
              <Button
                color={buttons.back.color}
                icon={buttons.back.icon}
                iconDir={buttons.back.iconDir}
                solo
                label={buttons.back.text}
                size={buttons.back.size}
                onClick={() => onBack?.(useValues)}
              />
            )}

            {buttons.submit && (
              <Button
                color={buttons.submit.color}
                icon={buttons.submit.icon}
                iconDir={buttons.submit.iconDir}
                label={buttons.submit.text}
                size={buttons.submit.size}
                submit
                loading={buttons.submit.loading}
              />
            )}
          </Grid>
        </Row>
      )}
  
      {footer}
    </form>
  )
}

export default React.forwardRef(FormValidate)
