import React from 'react'
import { ShowHide, WrapperPage } from 'components'
import useCadastralPositionForm from './hooks/useCadastralPositionForm'
import { Form } from './components'
import { Errors, Success } from '../../moves/upload/components'
import { useHistoryParams } from '../../../navigation'

const CadastralPosition: React.FC = () => {
  const {
    useFileUploadFormValues,
    setFileUploadForm,
    onSubmit,
    formRef,
    useFileUploadFormStatus,
    useIsLoading,
    useError,
    resetSubmitError,
  } = useCadastralPositionForm()
  const { companyId } = useHistoryParams()

  return (
    <WrapperPage>
      <div style={{ position: 'relative' }}>
        <ShowHide absolute visible={useFileUploadFormStatus === 'form'}>
          <Form
            useIsLoading={useIsLoading}
            useFormValues={useFileUploadFormValues}
            setFileUploadForm={setFileUploadForm}
            onSubmit={onSubmit}
            formRef={formRef}
          />
        </ShowHide>

        <ShowHide absolute visible={useFileUploadFormStatus === 'error'}>
          <Errors details={useError.details} setUploadFormStatus={resetSubmitError} />
        </ShowHide>

        <ShowHide absolute visible={useFileUploadFormStatus === 'success'}>
          <Success callbackUrl={`/companies/${companyId}/cadastral-position`} />
        </ShowHide>
      </div>
    </WrapperPage>
  )
}

export default CadastralPosition
