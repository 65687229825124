import React, { useCallback, useMemo, useRef, useState } from 'react'
import { RoutesGroupType } from 'vkit/lib/shared/router/types/router.type'
import useHeaderNav from 'components/headerNav/hooks/useHeaderNav'
import { Breadcrumb, Divider } from 'components'
import { Grid, Row } from 'vkit/lib/context'
import { Button, Icon, Text } from 'vkit/lib/components'
import { useLocation, useParams } from 'react-router-dom'
import { useHistoryNavigator } from 'navigation'
import { decodedJwtToken } from 'utils/authentication'
import { BreadcrumbItem } from 'components/breadcrumb'
import { ObjectType } from 'shareds/types'

interface HeaderNavProps {
  routeGroups: RoutesGroupType[]
}

export interface LastAccess {
  id: string
  text: string
}

const COUNT_LAST_ACCESS = 5

const HeaderNav: React.FC<HeaderNavProps> = (
  {
    routeGroups
  }
) => {
  const [useSubtitles, setSubtitles] = useState<string[]>([])
  const { pathname } = useLocation()
  const pathnameOld = useRef(pathname)
  const navigate = useHistoryNavigator()
  const params = useParams<ObjectType>()
  const paramsRef = useRef(params)
  const userId = useMemo(() => {
    const { sub } = decodedJwtToken()
    return sub
  }, [])

  const {
    useCurrentRoute
  } = useHeaderNav({
    routeGroups
  })

  const {
    breadcrumb = [],
    title,
    icon,
  } = useCurrentRoute

  const addLastAccess = useCallback((label: string, breadcrumbItem: BreadcrumbItem): void => {
    if (
      !breadcrumbItem.accessId
      || !breadcrumbItem.requestFindLabelParamIdName
      || pathname !== pathnameOld.current
    ) {
      return
    }

    const { lastAccesses } = JSON.parse(localStorage.getItem(userId) || '{ "lastAccesses": {} }')
    const lastAccessesByAccessId = (lastAccesses[breadcrumbItem.accessId] || []) as LastAccess[]

    const currentId = paramsRef.current[breadcrumbItem.requestFindLabelParamIdName]
    const companyIndex = lastAccessesByAccessId
      .findIndex((item: { id: string }) => item.id === currentId)

    if (companyIndex > -1) {
      lastAccessesByAccessId.splice(companyIndex, 1)
    }

    lastAccessesByAccessId.unshift({
      id: currentId,
      text: label,
    })

    const newLastAccesses = lastAccessesByAccessId.slice(0, COUNT_LAST_ACCESS)
    localStorage.setItem(userId, JSON.stringify({
      lastAccesses: {
        ...lastAccesses,
        [breadcrumbItem.accessId]: newLastAccesses
      }
    }))
  }, [pathname, userId])

  const addSubtitle = useCallback((subtitle: string): void => {
    if (pathname !== pathnameOld.current) {
      setSubtitles([subtitle])
      return
    }

    pathnameOld.current = pathname
    setSubtitles((subtitles) => {
      return [
        ...subtitles,
        subtitle
      ]
    })
  }, [pathname])

  const breadcrumbItems = useMemo(() => {
    return breadcrumb.map((item: BreadcrumbItem) => ({
      ...item,
      requestFindLabelResponseOnLoad: (label: string) => {
        addLastAccess(label, item)
        addSubtitle(label)
      }
    }))
  }, [breadcrumb, addLastAccess, addSubtitle])

  return (
    <>
      {
        breadcrumb.length > 1 && (
          <>
            <Grid alignContent='justify'>
              <Breadcrumb items={breadcrumbItems} />

              <Button
                data-testid="buttonGoBack"
                icon='arrow-ios-back-outline'
                iconColor='default'
                label='Voltar'
                onClick={() => navigate.goBack()}
                invertColor
              />
            </Grid>
            <Divider space={16} />
          </>
        )
      }

      <Row style={{ padding: '0 0 16px' }}>
        <Grid alignItems='center'>
          {
            icon && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 40,
                  height: 40,
                  marginRight: 16,
                  borderRadius: 8,
                  backgroundColor: 'rgba(0, 123, 255, 0.1)'
                }}
              >
                <Icon
                  name={icon}
                  size={25}
                  color='default'
                />
              </div>
            )
          }
          <Row style={{ padding: 0, justifyContent: 'center' }}>
            {
              title && (
                <Text
                  value={title}
                  size='medium'
                  color='default'
                  title
                />
              )
            }

            {
              !!useSubtitles.length && (
                <Text
                  value={useSubtitles.join(' | ')}
                  size='medium'
                />
              )
            }
          </Row>
        </Grid>
      </Row>
    </>
  )
}

export default HeaderNav
