export const calculateListSize = (
  listRef: { current: HTMLDivElement | null },
  innerHeight = 50,
): number => {
  const listMinSize = 5
  const bottomWindow = (window.innerWidth > 768 ? 32 : 16) + 40
  const tabsHeight = 37

  if (listRef.current) {
    const { top: listTop = 0 } = listRef.current.getBoundingClientRect()
    const listHeight = window.innerHeight - listTop - bottomWindow - tabsHeight
    const listSize = Math.floor(listHeight / innerHeight)
    return Math.max(listSize, listMinSize)
  }

  return listMinSize
}

export const downloadFileLink = (uri: string, name?: string): void => {
  const link = document.createElement('a')
  if (name) {
    link.download = name
  }
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const findNestedObject = (obj: any, path: string): object | string =>
  path ? path.split('.').reduce((prev: any, curr: any) => prev ? prev[curr] : null, obj || self) : obj

export const valueFinder = (obj: any, value = '', responseMessage?: string): any => {
  const valueMatch = obj[value]
  if (valueMatch) return valueMatch
  return responseMessage || null
}

export const responseParser = (value = '', responseMessage: string): any => value || responseMessage
