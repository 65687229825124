import React from 'react'
import style from './tooltip.module.scss'
import { classesBuilder } from 'utils'
import { capitalize } from 'lodash'

interface TooltipProps {
  children: React.ReactNode
  title?: string
  align?: 'left' | 'right' | 'center'
}

const Tooltip: React.FC<TooltipProps> = (
  {
    children,
    title ,
    align,
  }
) => (
  <div className={classesBuilder(style, {
    tooltip: true,
    [`align${capitalize(align)}`]: align
  })}>
    {
      title && (
        <div className={style.baseTitle}>
          <div className={style.limitTitle}>
            <div className={style.title}>{title}</div>
          </div>
        </div>
      )
    }
    {children}
  </div>
)

export default Tooltip
