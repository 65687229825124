import React from 'react'
import { Grid, Row } from 'vkit/lib/context'
import { Text } from 'vkit/lib/components'
import emptyImageURL from 'assets/images/safebox.png'
import { classesBuilder } from 'utils'
import style from './style/emptyMessage.module.scss'

export interface EmptyMessageProps {
  title?: string
  description?: string
  vertical?: boolean
}

const EmptyMessage:React.FC<EmptyMessageProps> = (
  {
    title,
    description,
    vertical,
  }
) => (
  <Row>
    <div
      className={classesBuilder(style, {
        emptyMessage: true,
        vertical,
      })}
    >
      <div className={style.content}>
        <div
          className={style.image}
          style={{ backgroundImage: `url(${emptyImageURL})` }}
        />
        {
          vertical ? (
           <>
             {
               title && (
                 <Text
                   color='default'
                   padding='16px 0 0'
                   textAlign='center'
                   title
                   value={title}
                 />
               )
             }

             {
               description && (
                 <Text
                   padding='16px 0 0'
                   size='larger'
                   textAlign='center'
                   value={description}
                 />
               )
             }
           </>
          ):(
            <>
              <Grid growing alignItems='center'>
                <div>
                  {
                    title && (
                      <Text
                        color='default'
                        title
                        value={title}
                        padding='0 0 0 30px'
                      />
                    )
                  }

                  {
                    description && (
                      <Text
                        padding='8px 0 0 30px'
                        size='larger'
                        value={description}
                      />
                    )
                  }
                </div>
              </Grid>
            </>
          )
        }
      </div>
    </div>
  </Row>
)

export default EmptyMessage
