import { useCallback, useEffect, useState } from 'react'
import { ObjectType } from 'shareds/types'
import { negotiationsService } from 'services/negotiations'
import { useHistoryParams } from 'navigation'
import { ToastStatusGlobal } from 'components'

export interface OptionSelect {
  contract: ObjectType
  product: ObjectType
  costCenter: ObjectType
  text: string
  value: string
}

type UseFormType = {
  useNegotiationOptions: OptionSelect[] | null
}

interface IUseForm {
  useFormValues: ObjectType
  setFileUploadForm: Function
}

export const useForm = (
  {
    useFormValues,
    setFileUploadForm,
  }: IUseForm): UseFormType => {
  const [useNegotiationOptions, setNegotiationOptions] = useState<OptionSelect[]|null>([])
  const [useNegotiations, setNegotiations] = useState<ObjectType[]>([])
  const { companyId } = useHistoryParams()
  const { toast } = ToastStatusGlobal()

  const loadNegotiations = useCallback(async (): Promise<void> => {
    if (!companyId) {
      return
    }

    try {
      setNegotiationOptions([])

      const negotiations = await negotiationsService.listAll({ 'costCenter.companyId': companyId }) || []

      setNegotiations(negotiations)

      if (!negotiations.length) {
        setNegotiationOptions(null)
        return
      }

      const negotiationsOptions = negotiations.map(({
        contract,
        product,
        costCenter,
        id: negotiationId
      }: ObjectType) => ({
        contract,
        product,
        costCenter,
        text: `contrato: ${contract.code || 'Contrato não informado'}` +
        ` | centro de custo: ${costCenter.name || 'Centro de custo não informado'}` +
        ` | produto: ${product.name || 'Produto não informado'}`,
        value: negotiationId,
      }))

      setNegotiationOptions(negotiationsOptions)
    } catch (error) {
      console.error(error)
    }
  }, [companyId])

  useEffect(() => {
    loadNegotiations()
  }, [loadNegotiations])

  useEffect(() => {
    if (!useFormValues.negotiation || !useNegotiations?.length) {
      return
    }

    const { contract, costCenter, product } = useNegotiations.find((negotiation: ObjectType) =>
      negotiation.id === useFormValues.negotiation
    ) || {}

    if (!contract || !costCenter) {
      toast('Oops', 'Falha ao identificar negociação.', 'error')
      return
    }

    setFileUploadForm((dataForm: ObjectType) => ({
      ...dataForm,
      contract: contract.code,
      costCenter: costCenter.name,
      contractId: contract.id,
      productId: product.id
    }))
  }, [useFormValues.negotiation, useNegotiations, setFileUploadForm, toast])

  return {
    useNegotiationOptions
  }
}

export default useForm
