import { useMemo } from 'react';
import { IProvider } from '../types/Provider.type';

import { getFileNameByType } from '../utils/utils';

interface IFormData {
  provider: IProvider | null,
  movimentationType: string | null,
  templateDownloaded: boolean,
}

interface IUseDownloadTemplate {
  getDownloadLink: (formData: IFormData) => string | null
}

const {
  REACT_APP_MOVE_TEMPLATE_URL = '',
  REACT_APP_MOVE_TEMPLATE_FILES = '',
} = process.env

const useDownloadTemplate = (): IUseDownloadTemplate  => {

  const templates = useMemo(() => {
    return REACT_APP_MOVE_TEMPLATE_FILES.split(',')
  }, []);

  const getDownloadLink = (formData: IFormData): string | null => {
    const { provider, movimentationType } = formData
    if (provider?.name && movimentationType) {
      const isBradesco = provider.name.toLowerCase().includes('bradesco');
      const fileNameType = getFileNameByType(movimentationType);

      const fileName = templates.find((temp: string) => {
        if (!isBradesco || movimentationType === 'update') {
          return !temp.includes('bradesco') && temp.includes(fileNameType);
        } else {
          return temp.includes('bradesco') && temp.includes(fileNameType);
        }
      })

      return fileName ? `${REACT_APP_MOVE_TEMPLATE_URL}/${fileName}` : null;
    }
    return null;
  }

  return {
    getDownloadLink
  }
}

export default useDownloadTemplate;
