import React from 'react'
import { Grid } from 'vkit/lib/context'
import { Icon, Loader } from 'vkit/lib/components'
import { ButtonDialog } from 'components'

export type StatusSaveNegotiationType = {
  status: 'ok' | 'waiting' | 'loading' | 'pending'
  onClick: Function
}

export const StatusSaveNegotiation: React.FC<StatusSaveNegotiationType> = ({
 status,
 onClick,
}) => (
  <>
    {
      status === 'ok' && (
        <Grid alignContent='center'>
          <Icon name='checkmark-circle-outline' color='success' />
        </Grid>
      )
    }

    {
      status === 'waiting' && (
        <Grid alignContent='center'>
          <Icon name='clock-outline' color='default' />
        </Grid>
      )
    }

    {
      status === 'loading' && (
        <Grid alignContent='center'>
          <Loader/>
        </Grid>
      )
    }

    {
      status === 'pending' && (
        <ButtonDialog
          color='danger'
          label='Excluir'
          onClick={onClick as () => void}
          outlined
          solo
          text='Quer mesmo excluir essa negociação?'
        />
      )
    }
  </>
)

export default StatusSaveNegotiation
