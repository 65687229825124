import React, { useMemo } from 'react'
import { BoxInfo, Divider, GridGroup, Tooltip } from 'components/index'
import { ActionsLayer, Text } from 'vkit/lib/components'
import verifyPermissions from 'utils/verifyPermissions'
import { Action } from 'components/dataTable/components/contentList/boxes'
import { decodedJwtToken } from 'utils/authentication'
import { LastAccess } from 'components/headerNav'
import style from './cardLastAccessList.module.scss'

interface CardLastAccessListProps {
  accessId: string
  actions: (...itens: any) => Action[]
  icon: string
}

const Index: React.FC<CardLastAccessListProps> = (
  {
    accessId,
    actions,
    icon,
  }
) => {
  const userId = useMemo(() => {
    const { sub } = decodedJwtToken()
    return sub
  }, [])

  const lastAccesses = useMemo<LastAccess[]>(() => {
    const { lastAccesses } = JSON.parse(localStorage.getItem(userId) || '{ "lastAccesses": {} }')
    return  lastAccesses[accessId] || []
  }, [userId, accessId])

  return lastAccesses.length ? (
    <div className={style.cardLastAccessList}>
      <Text
        size='small'
        title
        value='Últimos acessos'
        padding='16px 0 8px'
      />

      <GridGroup
        body={
          lastAccesses.map(({ id, text }) => ({
            default: 20,
            component: (
              <BoxInfo
                elevation={4}
                icon={icon}
                margin={0}
                header={
                  <ActionsLayer
                    vertical
                    actions={actions({ id }).filter(verifyPermissions)}
                  />
                }
              >
                <Tooltip title={text} align='left'>
                  <Text
                    color='default'
                    size='minor'
                    title
                    value={text}
                  />
                </Tooltip>
              </BoxInfo>
            )
          }))
        }
      />

      <Divider space={24} />
    </div>
  ) : null
}

export default Index
