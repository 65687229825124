import restfulBase from 'services/negotiations/base'

export type Company = {
  id?: string
  name: string
  cnpj: string
  companyGroupId: string
}

export const initialStateCompany = {
  name: '',
  cnpj: '',
  companyGroupId: '',
}

export const companiesService = restfulBase('companies')

export default companiesService
