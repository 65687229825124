import React, { useMemo } from 'react'
import { isBefore, parseISO } from 'date-fns'
import { Chip, Loader, Text } from 'vkit/lib/components'
import { Grid, Row } from 'vkit/lib/context'
import { Contract } from 'services/negotiations/endpoints/contracts'
import { BoxInfo,  DataList } from 'components'
import { ObjectType } from 'shareds/types'
import useColumns from './hooks/useColumns'
import { getFormattedDate } from '../../utils/format'

type ContractNegotiationsProps = {
  loading?: ObjectType
  onDelete?: {
    negotiation: (id: string) => Promise<void>
    contract: (id: string, validUntil: string) => Promise<void>
  }
  contract?: Contract
  disabledButtons?: boolean
  header?: {
    right?: (
      options: {
        contract?: Contract,
        isCancelled: boolean
        isExperied: boolean,
      }
    ) => React.ReactNode
  }
}

const ContractNegotiations: React.FC<ContractNegotiationsProps> = (
  {
    loading,
    onDelete,
    contract,
    header,
  }: ContractNegotiationsProps
) => {
  const columns = useColumns({
    onDelete: onDelete?.negotiation,
    loading,
    contractCode: contract?.code
  })

  const useData = useMemo(() => {
    if (loading?.negotiations) {
      return []
    }

    return contract?.negotiations?.length ? contract.negotiations : null
  }, [contract?.negotiations, loading?.negotiations])

  const isExperied = useMemo(() =>
    !!contract?.validUntil && isBefore(parseISO(contract.validUntil), new Date()),
    [contract?.validUntil]
  )

  const isCancelled = useMemo(() => contract?.status === 'cancelled', [contract?.status])

  const dateExpiration = useMemo(() =>
    contract?.validUntil
      ? getFormattedDate(contract?.validUntil, { discardTimezone: true })
      : 'Não informado',
    [contract?.validUntil]
  )

  return (
    <BoxInfo
      icon='clipboard-outline'

      title={
        <Text
          color='default'
          size='small'
          title
          value={loading?.negotiations ? 'Carregando...' : contract!.code}
          rounded
        />
      }

      header={
        <>
          <Grid alignItems='center'>
            {
              !loading?.negotiations && (
                <Text value={`Data de expiração do contrato: <b>${dateExpiration}</b>`} padding='0 8px 0' />
              )
            }

            {
              !loading?.negotiations && isCancelled && (
                <Chip
                  color='warning'
                  label='Rescindido'
                  rounded
                  size='medium'
                />
              )
            }

            {
              !loading?.negotiations && !isCancelled && isExperied && (
                <Chip
                  color='danger'
                  label='Expirado'
                  rounded
                  size='medium'
                />
              )
            }

            {
              loading && contract?.id && loading[contract.id] && (
                <Row style={{ padding: '0 0 0 8px' }}>
                  <Loader color='default' />
                </Row>
              )
            }

            {header?.right?.({
              isCancelled,
              contract,
              isExperied,
            })}
          </Grid>
        </>
      }
    >
      <DataList
        noPagination
        data={useData}
        columns={columns}
        elevation={1}
      />
    </BoxInfo>
  )
}

export default ContractNegotiations
