import React from 'react';

import { Text } from 'vkit/lib/components'
import { BoxInfo } from 'components'

import style from './MarkupHeader.module.scss';

export type MarkupHeaderInfo = {
  title: string
  value: string
}

export interface MarkupHeaderProps {
  infos: MarkupHeaderInfo[]
}

const MarkupHeader: React.FC<MarkupHeaderProps> = ({ infos }) => {
  return (
    <header>
      <BoxInfo>
        {
          infos.map((info: MarkupHeaderInfo, index: number) => (
            <div className={ style.MarkupHeaderItem } key={ index }>
              <Text size='larger' fontWeight={600} value={ info.title } />
              <Text size='larger' fontWeight={400} value={ info.value } />
            </div>
          ))
        }
      </BoxInfo>
    </header>
  )
}

export default MarkupHeader
