import React from 'react'

import { Row, Grid } from 'vkit/lib/context'
import { Text, Icon, Button } from 'vkit/lib/components'

import useLogout from './hooks/useLogout'

const Logout: React.FC = () => {
  const { onLogout, useLoading, useMessageError } = useLogout()

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        padding: 0,
      }}
    >
      <Row>
        <Grid alignContent='center'>
          <Icon color='default' size={64} name='log-out-outline' />
        </Grid>
      </Row>

      <Row style={{ textAlign: 'center' }}>
        <Text title color='default' value='Fica, vai ter bolo...' margin='0 0 8px' size='medium' />

        <Text size='larger' value='Tem certeza de que quer sair?' />

        <Row style={{ width: 200, margin: 'auto' }}>
          {useMessageError && (
            <Text value={useMessageError} applyBaseColor color='danger' size='medium' rounded />
          )}
        </Row>
      </Row>

      <Row>
        <Grid alignContent='center'>
          <Button
            loading={useLoading}
            onClick={onLogout}
            label='Quero sair!'
            icon='people-outline'
          />
        </Grid>
      </Row>
    </Row>
  )
}

export default Logout
