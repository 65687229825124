import React from 'react'
import { CardNumberProps, CardNumber } from 'components/cardNumber'
import { GridGroup } from 'components'

type CardNumbersProps = {
  data: CardNumberProps[]
}

const CardNumbers: React.FC<CardNumbersProps> = (
  {
    data
  }
) => (
  <GridGroup
    gap={16}
    body={data.map((
      {
        number,
        text,
        width,
        color,
      }
    ) => (
      {
        default: 25,
        component: (
          <CardNumber
            number={number}
            text={text}
            width={width}
            color={color}
            key={text}
          />
        )
      }
    ))}
  />
)

export default CardNumbers
