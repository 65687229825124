interface SubtitleRules {
  [key: string]: (value: string) => string
}

const subtitleRules: SubtitleRules = {
  dateDDMMYYYY: () => 'Formato DD/MM/YYYY',
  dateFormat: () => 'Formato DD/MM/YYYY',
  email: () => 'Formato de email',
  length: (length: string) => `${length} caracteres`,
  max: (max: string) => `Máximo de ${max}`,
  maxLength: (maxLength: string) => `Máximo de ${maxLength} caracteres`,
  min: (min: string) => `Mínimo de ${min}`,
  minLength: (minLength: string) => `Mínimo de ${minLength} caracteres`,
  number: () => 'Formato numérico',
  pattern: (pattern: string) => `Formato: ${pattern}`,
  required: () => 'Campo preenchido',
  stringMax: (max: string) => `Máximo de ${max} caracteres`,
  stringMin: (min: string) => `Mínimo de ${min} caracteres`,
  stringNumeric: () => 'Formato numérico',
  stringLength: (length: string) => `${length} caracteres`,
  exclusive_translate: (value: string) => `Uma dessas opções: ${value}`,
}

const translateRulesValidator =
  (rule: string, expectedValue: string | string[] | number): string => {
    if (rule in subtitleRules) {
      const value = Array.isArray(expectedValue) ? expectedValue.join(', ') : expectedValue
      return subtitleRules[rule]?.(String(value))
    }

    return ''
  }

export default translateRulesValidator
