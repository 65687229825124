import restfulBase from 'services/moves/core/base'
import { BaseEntity } from 'shareds/types/baseEntity.type'

export interface MoveAttachment {
  link: string
  name: string
}

export interface Move extends BaseEntity {
  companyId: string
  companyName: string
  requesterName: string
  responded: boolean
  response: string
  responseDate: string
  responsibleResponse: string
  uploadId: string
  worksheetHashName: string
  worksheetName: string
  attachments?: MoveAttachment
}

export const initialStateMove: Move = {
  companyId: '',
  companyName: '',
  createdAt: '',
  deletedAt: '',
  deletionReason: '',
  id: '',
  requesterName: '',
  responded: false,
  response: '',
  responseDate: '',
  responsibleResponse: '',
  updatedAt: '',
  uploadId: '',
  worksheetHashName: '',
  worksheetName: '',
}

export const movesService = restfulBase<Move>('movimentations')

export default movesService
