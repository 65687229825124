import React from 'react'
import { Grid } from 'vkit/lib/context'
import { Colors } from 'shareds/types'
import { Text } from 'vkit/lib/components'
import { BoxInfo } from 'components/index'

export type CardNumberProps = {
  number: number
  text: string
  width?: string
  color: Colors
}

export const CardNumber: React.FC<CardNumberProps> = (
  {
    number,
    text,
    width,
    color,
  }: CardNumberProps
) => (
  <div
    style={{
      display: 'flex',
      width
    }}
  >
    <BoxInfo>
      <Grid alignItems='center'>
        <div style={{
          display: 'inline-table',
          paddingRight: 16,
          textAlign: 'center'
        }}>
          <Text
            color={color}
            size='xLarger'
            title
            value={`${number}`}
          />
        </div>
  
        <Text
          size='medium'
          value={text}
        />
      </Grid>
    </BoxInfo>
  </div>
)

export default CardNumber
