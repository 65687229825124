import restfulBase from 'services/fileMoves/base'

export interface MovesValidationErrorDetail {
  field: string
  message: string
  rule: string
  value: string
  details: {
    expected: string[] | string | number
    actual: string
  }
}

export interface MovesValidationError {
  details: MovesValidationErrorDetail[]
  message: string
}

export const movesValidationErrorInitialState: MovesValidationError = {
  details: [],
  message: '',
}

export const movesValidationService = restfulBase('moves')
export const pcValidationService = restfulBase('cadastral-positions')
