import React, { MouseEventHandler } from 'react'

import { Row } from 'vkit/lib/context'
import { Text } from 'vkit/lib/components'

import style from './style/buttonUnderline.module.scss'

import { classesBuilder } from 'utils'

interface ButtonUnderline {
  label: string
  onClick: MouseEventHandler<HTMLButtonElement>
  align?: 'left' | 'right' | 'center'
}

const ButtonUnderline: React.FC<ButtonUnderline> = ({ label, onClick, align = 'center' }) => {
  return (
    <Row
      style={{
        cursor: 'pointer',
        padding: 0,
      }}
    >
      <button onClick={onClick}
        data-testid="buttonUnderline"
        className={classesBuilder(style, {
          button: true,
          ...align ?
            {
              [align]: align
            } : {
              default: true
            },
        })}
      >
        <Text size='medium' value={label} />
      </button>
    </Row>
  )
}

export default ButtonUnderline
