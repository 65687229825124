import { UsersBroker } from 'pages/users'
import { PERMISSIONS } from 'utils/checkPermission'

const route = {
  component: UsersBroker,
  nameMenu: 'Usuários',
  icon: 'people-outline',
  exact: true,
  path: '/users',
  permissions: [
    PERMISSIONS.BROKER_USER_READ
  ],
}

export default route
