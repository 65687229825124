import * as Beneficiaries from 'pages/beneficiaries'
import * as CadastralPosition from 'pages/cadastralPosition'
import * as Companies from 'pages/companies'
import * as FamilyGroups from 'pages/familyGroup'
import * as Monitoring from 'pages/monitoring'
import * as Moves from 'pages/moves'
import * as NegotiationsByContracts from 'pages/negotiationsByContracts'
import { UsersCompany } from 'pages/users'
import { companiesService } from 'services/negotiations'
import { monitoringFilesService } from 'services/monitoring/endpoints/monitoring/files'
import ICONS from 'shareds/constants/icons'
import { PERMISSIONS } from 'utils/checkPermission'

const route = {
  path: '/companies',
  nameMenu: 'Empresas',
  exact: true,
  icon: ICONS.COMPANY,
  component: Companies.List,
  children: [
    {
      component: Companies.Details,
      exact: true,
      icon: 'eye-outline',
      nameMenu: 'Detalhes',
      path: '/:companyId',
      breadcrumb: {
        accessId: 'companies',
        requestFindLabelParamIdName: 'companyId',
        requestFindLabelResponsePathText: 'name',
        requestFindLabelFn: companiesService.find
      },
      children: [
        {
          component: Beneficiaries.List,
          exact: true,
          icon: ICONS.BENEFICIARIES,
          nameMenu: 'Beneficiários',
          path: '/beneficiaries',
        },
        {
          component: Monitoring.List,
          exact: true,
          icon: ICONS.CADASTRAL_POSITION,
          nameMenu: 'Posições Cadastrais',
          path: '/cadastral-position',
          children: [
            {
              component: Monitoring.Details,
              exact: true,
              icon: ICONS.DETAILS,
              nameMenu: 'Detalhes',
              path: '/:cadastralPositionId',
              breadcrumb: {
                requestFindLabelFn: async (id: string) => monitoringFilesService.index({ id }),
                requestFindLabelParamIdName: 'cadastralPositionId',
                requestFindLabelResponsePathText: 'data[0].fileName',
              },
              children: [
                {
                  component: FamilyGroups.List,
                  exact: true,
                  icon: ICONS.FAMILY_GROUP,
                  nameMenu: 'Erros de grupos familiares',
                  path: '/family-groups',
                },
              ]
            },
            {
              component: CadastralPosition.Create,
              exact: true,
              icon: ICONS.CADASTRAL_POSITION,
              nameMenu: 'Atualizar base de beneficiários',
              path: '/actions/create',
            },
          ]
        },
        {
          component: NegotiationsByContracts.List,
          exact: true,
          icon: ICONS.CONTRACT,
          nameMenu: 'Contratos',
          path: '/contracts',
        },
        {
          component: Moves.List,
          exact: true,
          icon: ICONS.MOVE,
          nameMenu: 'Movimentações',
          path: '/moves',
          permissions: [
            PERMISSIONS.MOVE_CREATE
          ],
          children: [
            {
              component: Moves.Details,
              exact: true,
              icon: ICONS.MOVE,
              nameMenu: 'Detalhes',
              path: '/:moveId',
              breadcrumb: {
                requestFindLabelFn: async (id: string) => monitoringFilesService.index({ id }),
                requestFindLabelParamIdName: 'moveId',
                requestFindLabelResponsePathText: 'data[0].fileName',
              },
            },
            {
              component: Moves.Upload,
              exact: true,
              icon: ICONS.UPLOAD,
              nameMenu: 'Upload de movimentações',
              path: '/actions/upload',
            },
          ],
        },
        {
          component: UsersCompany,
          exact: true,
          icon: ICONS.USERS,
          nameMenu: 'Usuários',
          path: '/users',
          permissions: [
            PERMISSIONS.COMPANY_USER_READ
          ],
        },
      ]
    },
  ]
}

export default route
