import React from 'react'
import { checkMobile, classesBuilder } from 'utils'
import style from './wrapperPage.module.scss'
import { HeaderNav } from 'components/index'
import routesGroups from 'routes'

const WrapperPage: React.FC = ({ children }) => (
  <div
    className={classesBuilder(style, {
      wrapperPage: true,
      mobile: checkMobile(),
    })}
  >
    <HeaderNav routeGroups={routesGroups} />
    {children}
  </div>
)

export default WrapperPage
