import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Text, Select } from 'vkit/lib/components'
import { Box, Grid, Row } from 'vkit/lib/context'
import translate from '../utils'
import { maskText, getFormattedDate, maskMobileNumber, maskPhoneNumber } from 'utils/format'
import style from '../style/textData.module.scss'
import { ParsedTextItemDataType } from '../types/textData.type'
import { ButtonCopy } from 'components'

const TextItemData: React.FC<ParsedTextItemDataType> = ({
  label,
  loading,
  text,
  type = 'text',
  sizeDefault,
  sizeMedium,
  sizeMobile,
  data,
  selected,
  onSelectectChange,
  disabled
}) => {
  const [useText, setText] = useState<string>('')
  const [useSize, setSize] = useState(0)

  const formatText = useCallback((value: string, key: string): string => {
    if (!value) {
      return ''
    }

    return (
      {
        cardCode: maskText(value, '9999 '.repeat(20)),
        cep: maskText(value, '99999-999'),
        cpf: maskText(value, '999.999.999-99'),
        cnpj: maskText(value, '99.999.999/9999-99'),
        date: getFormattedDate(value),
        dateWithoutTZ: getFormattedDate(value, { discardTimezone: true }),
        dateTime: getFormattedDate(value, { showHours: true }),
        marital: translate.marital(value),
        mobileNumber: maskMobileNumber(value),
        phoneNumber: maskPhoneNumber(value),
        relationship: translate.relationship(value),
        boolean: translate.boolean(value),
        rg: maskText(value, '99.999.999-9'),
        sex: translate.sex(value),
        text: value,
      }[key] || '-'
    )
  }, [])

  const currentComponent = useMemo(() => {
    if (type === 'select') {
      return loading ? (
        <Text rounded size='larger' value='' />
      ) : (
        <Select
          label={ label }
          data={ data }
          value={ selected || '' }
          onChange={ onSelectectChange }
          disabled={ disabled }
          small
        />
      )
    }
    return (
      <>
        <Grid alignContent='justify'>
          <Text color='default' padding='0 0 6px' size='medium' value={`<b>${label}</b>`} />
          { useText && !loading && (<ButtonCopy text={useText}/>) }
        </Grid>
        <Text rounded size='larger' value={useText} />
      </>
    )
  },[data, disabled, label, loading, onSelectectChange, selected, type, useText]);

  useEffect(() => {
    if (loading) {
      setText('')
    } else {
      setText(formatText(text ?? '', type))
    }
  }, [text, type, loading, formatText])

  useEffect(() => {
    if (sizeMobile && window.innerWidth <= 768) {
      setSize(sizeMobile)
    } else if (sizeMedium && window.innerWidth <= 1024) {
      setSize(sizeMedium)
    } else if (sizeDefault) {
      setSize(sizeDefault)
    }
  }, [sizeMobile, sizeMedium, sizeDefault])

  return (
    <div
      className={style.textItemData}
      style={{
        maxWidth: useSize ? `calc(${useSize}% - 19px)` : undefined,
      }}
    >
      <Box elevation={1} type='sheet' outlined rounded>
        <Row style={{ padding: 12 }}>
          { currentComponent }
        </Row>
      </Box>
    </div>
  )
}

export default TextItemData
