import restfulBase from 'services/negotiations/base'
import { Negotiation } from 'services/negotiations/endpoints/negotiations'

import { BaseEntity } from 'shareds/types/baseEntity.type'

type SortedField<T> = string & keyof T | `-${string & keyof T}`;

export type StatusOptions =
  | 'active'
  | 'cancelled'

export type Contract = BaseEntity & {
  code: string
  companyId: string
  negotiations?: Negotiation[]
  note?: string
  status: StatusOptions
  type: string
  validFrom?: string
  validUntil?: string
}

export type ContractFilter = {
  'negotiations.costCenter.companyId': string
  companyId: string
  code: string
  type: string
  sort: SortedField<Contract>[]
}

export const initialStateContract: Contract = {
  code: '',
  companyId: '',
  negotiations: [],
  note: '',
  status: 'active',
  type: '',
  validFrom: '',
  validUntil: '',
}

export const contractsService = restfulBase('contracts')

export default contractsService
