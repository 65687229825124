import restfulBase from 'services/monitoring/core/base'
import { BaseEntity } from 'shareds/types/baseEntity.type'

export interface MonitoringRow extends BaseEntity {
  line: string
  error: string
  lifeProcessed: boolean
  beneficiaryProcessed: boolean
  content: string
  messages?: MonitoringRowMessage[]
}

export interface MonitoringRowMessage {
  id: string
  type: 'error' | 'success'
  content: Object
  topic: string
}

export interface MonitoringRowFilters extends Partial<MonitoringRow> {
  pageSize?: number
  page?: number
}

export const monitoringRowsService = restfulBase<MonitoringRow>('rows')

export default monitoringRowsService
