import React from 'react'
import { ShowHide, Space, WrapperPage } from 'components'
import { Grid, Row } from 'vkit/lib/context'
import { Button, Text } from 'vkit/lib/components'
import BoxStatusFamilyGroup from 'pages/familyGroup/list/components/boxStatusFamilyGroup'
import useList from './hooks/useList'
import CardNumbers from 'pages/familyGroup/list/components/cardNumbers'

const List: React.FC = () => {
  const {
    changePage,
    useData,
    usePageExists,
    useCardNumbers
  } = useList()

  return (
    <WrapperPage>
      <ShowHide
        visible={!!useCardNumbers?.length}
        transition='slideToDown'
      >
        <CardNumbers data={useCardNumbers}/>
      </ShowHide>

      <ShowHide
        visible={!!useData?.length}
        transition='slideToDown'
      >
        {
          useData?.map((collectionStatusFamilyGroup, index) => (
            <BoxStatusFamilyGroup
              key={`${collectionStatusFamilyGroup.title} | ${index}`}
              collectionStatusFamilyGroup={collectionStatusFamilyGroup}
            />
          ))
        }

        <Row style={{ padding: '0 0 32px' }}>
          <Grid alignContent='right'>
            <Button
              disabled={!usePageExists.prev}
              label='Anterior'
              solo
              onClick={() => changePage(-1)}
            />

            <Space />

            <Button
              disabled={!usePageExists.next}
              label='Próximo'
              onClick={() => changePage(1)}
            />
          </Grid>
        </Row>
      </ShowHide>

      <ShowHide
        visible={useData !== null && !useData?.length}
        transition='slideToDown'
      >
        <BoxStatusFamilyGroup loading />
      </ShowHide>

      <ShowHide
        visible={useData === null}
        transition='slideToDown'
      >
        <Text
          applyBaseColor
          color='warning'
          icon='alert-triangle-outline'
          iconColor='warning'
          title
          value='Nenhum registro encontrado'
          padding='8px 16px'
          rounded
        />
      </ShowHide>
    </WrapperPage>
  )
}

export default List
