import React, { MouseEventHandler, ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { ContentListLines, ContentListBoxes } from './components'
import { BoxInfo, ButtonGroup, GridGroup, ShowHide, Space, TextFieldValidate, Tooltip } from 'components'
import { Grid, Row } from 'vkit/lib/context'
import { Button, Checkbox, Text } from 'vkit/lib/components'
import useDataList, { RESTProtocol, TableType } from './hooks/useDataList'
import { Pagination, SelectPageSize } from 'components'
import { ObjectType } from 'shareds/types'
import { ColumnSort } from 'components/dataTable/components/contentList/lines'
import { GridGroupItemProps } from 'components/webForm/gridGroup'
import style from './dataTable.module.scss'
import { classesBuilder } from 'utils'
import { useHistoryNavigator } from 'navigation'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

interface FieldComponentProps {
  onChange: (name: string, value: any) => void
  useValues: { [key: string]: string }
}

import { PERMISSIONS } from 'utils/checkPermission'
import { FunctionKey } from 'utils/formatText'
import { isEmpty } from 'lodash'

export interface Action {
  label: string
  icon?: string
  onClick: MouseEventHandler<HTMLButtonElement>
  permissions?: PERMISSIONS[]
}

export interface Column {
  align?: 'left' | 'right' | 'center'
  path?: string
  title?: string
  type?: FunctionKey
  width?: string
  custom?: (item: any) => ReactElement | string | number
  sortable?: boolean
}

export interface DataListProps {
  actionsByLine?: (...itens: any) => Action[] | ReactNode
  columns: Column[]
  data?: ObjectType[]
  elevation?: number
  filter?: {
    initialValues?: { [key: string]: string | number }
    fields?: (props: FieldComponentProps) => GridGroupItemProps[]
    searchField?: string | ((props: FieldComponentProps) => ReactNode)
  }
  fnRequest?: RESTProtocol
  header?: {
    right?: ReactNode
    left?: ReactNode
  }
  iconCard?: string
  linesLoader?: string | number
  loading?: boolean
  noPagination?: boolean
  requestResponseRootPath?: string
  showAlternativeType?: boolean
  showSettings?: boolean
  totalPages?: number
  type?: TableType
}

export interface QueryString {
  showSettingColumns: boolean
  showMoreFilters: boolean
  page: string
  pageSize: string
  sort: string
  tableType: TableType
  hideColumns: string[]
}

const queryStrngInitialValues: QueryString = {
  showSettingColumns: false,
  showMoreFilters: false,
  page: '',
  pageSize: '',
  sort: '',
  tableType: 'lines',
  hideColumns: []
}

const DataList: React.FC<DataListProps> = (
  {
    actionsByLine,
    columns,
    data,
    elevation = 4,
    filter,
    fnRequest,
    header,
    iconCard,
    linesLoader,
    loading,
    noPagination,
    requestResponseRootPath,
    showAlternativeType,
    showSettings,
    type ,
  }
) => {
  const {
    dataTableRef,
    refreshData,
    onChangeFilter,
    onChangeSort,
    setFilters,
    useData,
    useFilters,
    useLoading,
    useTotalPages,
    useTotalRows,
  } = useDataList({
    fnRequest,
    tableType: type,
    requestResponseRootPath,
  })
  const [useColumnSort, setColumnSort] = React.useState<ColumnSort>({})
  const { search } = useLocation()
  const navigate = useHistoryNavigator()
  const dataContent = data || useData

  const getQuery = useCallback((): QueryString => {
    if (!search) return queryStrngInitialValues
    const queryString = search.substring(1)
    const foundValues = qs.parse(queryString, { allowDots: true })

    return {
      ...queryStrngInitialValues,
      ...foundValues
    }
  }, [search])

  const {
    showMoreFilters,
    showSettingColumns,
    page = '1',
    pageSize = '10',
    sort,
    hideColumns,
    tableType,
  } = useMemo(getQuery, [getQuery]) as QueryString

  const addQueryString = useCallback((params: ObjectType): void => {
    const queryString = getQuery()
    navigate.search({
      ...queryString,
      ...params,
    })
  }, [navigate, getQuery])

  const settingColumns = useMemo(() =>
    columns.filter((_, index) => !hideColumns.includes(index.toString())), [hideColumns, columns])

  const toggleHideColumns = (index: number): void => {
    const indexString = index.toString()
    const newHideColumns = hideColumns.includes(indexString)
      ? hideColumns.filter((item) => item !== indexString)
      : [...hideColumns, indexString]
    addQueryString({ hideColumns: newHideColumns })
  }

  const addFilter = (key: string, value: any): void => {
    if (!useFilters[key] && !value) {
      return
    }

    onChangeFilter({
      ...useFilters,
      [key]: value
    })
  }

  useEffect(() => {
    if (!sort) {
      setColumnSort({})
      return
    }

    const direction = sort.at(0) === '-' ? 'DESC' : 'ASC'
    const path = sort.replace(/^-/, '')
    setColumnSort({ direction, path })
  }, [sort])

  return (
    <div
      className={classesBuilder(style, {
        'dataTable': true,
        noElevation: !elevation,
        [type || tableType]: true
      })}
      ref={dataTableRef}
    >
      <Grid row margin={!isEmpty(header) || !isEmpty(filter) ? '0 0 16px' : 0} growing>
        <Grid alignContent='justify' alignItems='bottom' wrap growing>
          <Grid alignItems='bottom' growing>
            {header?.left}
            {header?.left && (<Space width={20} />)}

            {
              typeof filter?.searchField === 'string' ? (
                <TextFieldValidate
                  small
                  name={filter.searchField}
                  iconDir='right'
                  icon='search-outline'
                  label='Buscar'
                  useValues={useFilters}
                  onChange={addFilter}
                />
              ) : (
                filter?.searchField?.({
                  useValues: useFilters,
                  onChange: addFilter
                })
              )
            }

            <Space width={20} />

            {
              filter?.fields && (
                <Button
                  iconColor={showMoreFilters ? 'light' : 'clear'}
                  onClick={() => {
                    addQueryString({ showMoreFilters: !showMoreFilters })
                  }}
                  label={showMoreFilters ? 'Menos filtros' : 'Mais filtros'}
                  icon='options-2-outline'
                  color='light'
                  invertColor={showMoreFilters}
                />
              )
            }
          </Grid>

          {header?.right}
        </Grid>
      </Grid>

      {
        filter?.fields && (
          <ShowHide visible={showMoreFilters} transition='slideToDown'>
            <BoxInfo elevation={elevation}>
              <Grid alignContent='justify' alignItems='center' margin='0 0 16px'>
                <Text value='Filtrar por' color='default' title />
                <Button
                  outlined
                  size='small'
                  solo
                  label='Limpar todos os filtros'
                  onClick={() => {
                    setFilters({})
                    addQueryString({ filters: {}  })
                  }}
                />
              </Grid>

              <GridGroup
                body={filter.fields?.({
                  onChange: addFilter,
                  useValues: useFilters,
                }) || []}
              />
            </BoxInfo>
          </ShowHide>
        )
      }

      <BoxInfo elevation={elevation}>
        {
          !!dataContent?.length && (
            <div className={style.caption}>
              <div className={style.captionLeft}>
                {
                  !!useTotalRows && (
                    <Text
                      value={`<b>${useTotalRows}</b> registros encontrados`}
                      size='medium'
                    />
                  )
                }
              </div>

              <div className={style.captionRight}>
                {
                  showAlternativeType && (
                    <ButtonGroup
                      active={['lines', 'boxes'].indexOf(type || tableType)}
                      color='light'
                      buttons={[
                        {
                          icon:'menu-outline',
                          onClick: () => addQueryString({ tableType: 'lines' }),
                          title: 'Linhas',
                        },
                        {
                          icon:'grid-outline',
                          onClick: () => addQueryString({ tableType: 'boxes' }),
                          title: 'Caixas',
                        }
                      ]}
                    />
                  )
                }

                {
                  showSettings && (
                    <Tooltip title='Configuração de colunas'>
                      <Button
                        color='light'
                        icon='settings-outline'
                        iconColor={showSettingColumns ? 'light' : 'clear'}
                        invertColor={showSettingColumns}
                        onClick={() => addQueryString({ showSettingColumns: !showSettingColumns })}
                      />
                    </Tooltip>
                  )
                }
              </div>
            </div>
          )
        }

        <ShowHide displayNone visible={showSettingColumns} transition='slideToDown'>
          <div className={style.settings}>
            <Row>
              <Text
                color='default'
                padding='0 0 16px'
                title
                value='Selecione as colunas que deseja visualizar'
              />

              <GridGroup
                body={columns.map(({ title }, index) => ({
                  default: 20,
                  component: (
                    <Checkbox
                      small
                      checked={!hideColumns.includes(String(index))}
                      label={title}
                      onChange={() => toggleHideColumns(index)}
                    />
                  )
                }))}
              />
            </Row>
          </div>
        </ShowHide>

        <div className={style.contentList}>
          <div className={style.lines}>
            <ContentListLines
              actionsByLine={actionsByLine}
              columnSort={useColumnSort}
              columns={settingColumns}
              data={data || useData}
              linesLoader={Number(linesLoader || pageSize || 10)}
              refreshData={refreshData}
              loading={loading || useLoading}
              onSort={onChangeSort}
            />
          </div>

          <div className={style.boxes}>
            <ContentListBoxes
              actionsByLine={actionsByLine}
              columns={settingColumns}
              data={data || useData}
              icon={iconCard}
              linesLoader={Number(linesLoader || pageSize || 10)}
              refreshData={refreshData}
              loading={loading || useLoading}
            />
          </div>

          {
            !noPagination && !!dataContent?.length && (
              <div className={style.footer}>
                <Grid alignContent='justify' wrap>
                  <SelectPageSize
                    onChangePageSize={(pageSize: number) => addQueryString({ page: 1, pageSize })}
                    pageSize={Number(pageSize || 10)}
                  />

                  <Pagination
                    page={Number(page || 1)}
                    total={useTotalRows || (useTotalPages || 1) * Number(pageSize || 10)}
                    itemsPerPage={Number(pageSize || 10)}
                    onChange={(page: number) => addQueryString({ page })}
                  />
                </Grid>
              </div>
            )
          }
        </div>
      </BoxInfo>
    </div>
  )
}

export default DataList
