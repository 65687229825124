import { ObjectType } from 'shareds/types'

export enum BENEFICIARY_STATUS {
  WAITING = 'waiting',
  PRE_CANCELLED = 'pre-canceled',
  CANCELLED = 'canceled',
  DELETED = 'deleted',
  EXTENDED = 'extended',
  ACTIVE = 'active',
  PRE_ACTIVE = 'pre-active',
}

const status: ObjectType = {
  [BENEFICIARY_STATUS.CANCELLED]: 'Cancelado',
  [BENEFICIARY_STATUS.ACTIVE]: 'Ativo',
}

export const statusList: ObjectType = {
  [BENEFICIARY_STATUS.WAITING]: 'Ativo',
  [BENEFICIARY_STATUS.ACTIVE]: 'Ativo',
  [BENEFICIARY_STATUS.CANCELLED]: 'Cancelado',
  [BENEFICIARY_STATUS.DELETED]: 'Cancelado',
  [BENEFICIARY_STATUS.EXTENDED]: 'Ativo',
  [BENEFICIARY_STATUS.PRE_CANCELLED]: 'Ativo',
  [BENEFICIARY_STATUS.PRE_ACTIVE]: 'Ativo',
}

export default status
