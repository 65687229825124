import restfulBase from 'services/negotiations/base';
import { Markup } from '../types';

const MarkupService = (idNegotiation: string): any => {
  return restfulBase(`negotiations/${idNegotiation}/markups`);
}

const SetMarkupsModel = (markups: Markup[], listMarkups: any[]): Markup[] => {
  return markups.map((item: Markup) => {
    const markupAtual = listMarkups.find((getMarkup: any) => {
      const { ageBegin, ageEnd } = getMarkup;
      const fixedId = `${ageBegin}-${ageEnd}`;
      return fixedId === item.id;
    });
    if (markupAtual) {
      return {
        ...item,
        id: markupAtual.id,
        markup: markupAtual.markup,
        hasChange: false,
      }
    }
    return item;
  })
}

const isInvalidMarkupValue = (value: number): null | string => {
  const regex = /^[0-9]{0,3}(?:[.][0-9]{0,4})?$/;
  if (!value) {
    return 'Este campo é obrigatório.'
  }
  if (value < 0) {
    return 'Valor não pode ser menor que 0';
  }
  if (value > 200) {
    return 'Valor não pode ser maior que 200%';
  }
  if (!regex.test(value.toString())) {
    return 'Valor não pode exceder 4 casas decimais';
  }
  return null;
}

export {
  MarkupService,
  SetMarkupsModel,
  isInvalidMarkupValue
};
