import restfulBase from 'services/beneficiaries/base'
import { HealthCard } from 'services/beneficiaries/endpoints/healthCard'
import { Life } from 'services/beneficiaries/endpoints/life'
import { BaseEntity } from 'shareds/types/baseEntity.type'
import { BeneficiraryHolder } from 'services/beneficiaries/endpoints/beneficiraryHolder'

export interface Beneficiary extends BaseEntity {
  beneficiaryHolder: BeneficiraryHolder
  companyId: string
  healthCard: HealthCard
  life: Life
  lifeId: string
  negotiationId: string
  status: string
  validFrom: string
  validUntil: string
}

export const beneficiariesService = restfulBase('beneficiaries')
