import React from 'react';

import { TextField } from 'vkit/lib/components';

import { MarkupsContext } from '../../context/MarkupsContext';
import { Markup, MarkupContextType } from '../../types';

import style from './MarkupItem.module.scss';

enum StatusEnum {
  success = 'success',
  error = 'error',
}

type MarkupItemProps = Markup & {
  isFirstItem: boolean
}

const MarkupItem: React.FC<MarkupItemProps> = (
  {
    isFirstItem,
    ageBegin,
    ageEnd,
    markup,
  }
) => {
  const {
    loading,
  } = React.useContext(MarkupsContext) as MarkupContextType;

  const [errorMessage] = React.useState<string | null>(null);

  return (
    <>
      <div className={style.GridItems}>
        <div className={ style.GridItems__Column }>
          <TextField
            label={ isFirstItem ? 'De' : undefined }
            readOnly
            type='number'
            value={ageBegin}
            disabled
          />
        </div>
        <div className={ style.GridItems__Column }>
          <TextField
            label={ isFirstItem ? 'Até' : undefined }
            readOnly
            type='number'
            value={ageEnd}
            disabled
          />
        </div>
        <TextField
          label={ isFirstItem ? 'Markup (%)' : undefined }
          type='number'
          min={ 0 }
          max={ 200 }
          maxLength={ 8 }
          value={ markup === 0 ? '0' : markup }
          placeholder='Markup'
          disabled
          readOnly
          status={!loading && errorMessage ? StatusEnum.error : undefined}
          textHelper={!loading ? errorMessage ?? '' : undefined}
        />
      </div>
    </>
  )
}

export default MarkupItem;
