import { useMemo } from 'react';
import { decodedJwtToken } from 'utils/authentication';
import { TFormData } from '../types/FormData.type';

type TFormDataValidator = {
  hasProvider: boolean,
  hasMovimentationType: boolean,
  hasMovimentationFile: boolean,
  isValidFormData: boolean,
  getFormDataValues: () => Promise<FormData>
}

const useFormDataValidator = (formValues: TFormData, companyId: string): TFormDataValidator => {

  const hasProvider = useMemo((): boolean => {
    return formValues?.provider ? true : false;
  }, [formValues]);

  const hasMovimentationType = useMemo((): boolean => {
    return formValues?.movimentationType ? true : false;
  }, [formValues]);

  const hasMovimentationFile = useMemo((): boolean => {
    return formValues?.files ? true : false;
  }, [formValues]);

  const isValidFormData = useMemo((): boolean => {
    return hasProvider && hasMovimentationType && hasMovimentationFile;
  }, [hasProvider, hasMovimentationType, hasMovimentationFile]);

  const getFormDataValues = async (): Promise<FormData> => {
    const { provider, scope, movimentationType, files, attachment } = formValues;
    const formData = new FormData()
    const data = decodedJwtToken()

    formData.append('userName', data.user.name)
    formData.append('userId', data.sub)
    formData.append('userEmail', data.user.email)
    formData.append('companyId', companyId)
    formData.append('scope', scope)
    formData.append('providerId', provider?.id!)
    formData.append('movimentationType', movimentationType!)
    formData.append('files', files!)

    if (attachment) {
      formData.append('attachment', attachment)
    }

    return formData
  }

  return {
    hasProvider,
    hasMovimentationType,
    hasMovimentationFile,
    isValidFormData,
    getFormDataValues
  }
}

export default useFormDataValidator;
