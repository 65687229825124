import { useCallback, useMemo, useRef, useState } from 'react'
import { pick, pickBy } from 'lodash'
import { beneficiariesService, Beneficiary } from 'services/beneficiaries/endpoints/beneficiaries'
import { contractsService } from 'services/negotiations'
import { ObjectType } from 'shareds/types'
import { useHistoryParams } from 'navigation'
import { Negotiation } from 'services/negotiations/endpoints/negotiations'
import { beneficiaryStatus, beneficiaryType } from 'shareds/objects'
import { ListResponse } from 'services/beneficiaries/base'
import { getFormattedDate } from 'utils/format'
import { BENEFICIARY_STATUS } from 'shareds/objects/beneficiaryStatus'

interface SelectOption {
  text: string
  value: string
}

interface UseEntityByNegotiationId {
  contract: ObjectType,
  product: ObjectType
}

interface IUseBeneficiaries {
  getBeneficiaries: (updateFilters: ObjectType) => Promise<ListResponse<Beneficiary>>
  useContracts: Array<any>
  useEntityByNegotiationId: {
    contract: ObjectType,
    product: ObjectType
  }
  useTypeOptions: SelectOption[]
  useStatusOptions: SelectOption[]
}

const useBeneficiaries = (): IUseBeneficiaries => {
  const useTypeOptions = useMemo((): SelectOption[] => {
    return Object.entries(beneficiaryType).map(([value, text]) => ({ text, value }))
  }, [])
  const useStatusOptions = useMemo((): SelectOption[] => {
    return Object.entries(beneficiaryStatus).map(([value, text]) => ({ text, value }))
  }, [])
  const [useContracts, setContracts] = useState<SelectOption[] | null>(null)
  const useNegotiationsRef = useRef<Negotiation[]>([])
  const [useEntityByNegotiationId, setEntityByNegotiationId] = useState<UseEntityByNegotiationId>({
    contract: {},
    product: {},
  })
  const { companyId } = useHistoryParams()

  const loadContracts = useCallback(async (): Promise<void> => {
    if (!companyId) {
      return
    }

    const contracts = await contractsService.listAll({
      'negotiations.costCenter.companyId': companyId
    }) || []

    const contractOptions = []
    const contractByNegotiationId: ObjectType = {}
    const productByNegotiationId: ObjectType = {}

    for (const contract of contracts) {
      contractOptions.push({
        text: contract.code,
        value: contract.id
      })

      const contractNegotiations = contract.negotiations.map((negotiation: ObjectType) => ({
        ...negotiation,
        contractId: negotiation.contract_id
      }))

      useNegotiationsRef.current = [
        ...contractNegotiations,
        ...useNegotiationsRef.current,
      ]

      for (const negotiation of contract.negotiations) {
        contractByNegotiationId[negotiation.id] = contract
        productByNegotiationId[negotiation.id] = negotiation.product
      }
    }

    setEntityByNegotiationId({
      contract: contractByNegotiationId,
      product: productByNegotiationId,
    })

    setContracts(contractOptions)
  }, [companyId])

  const getStatusRefer = (
    status: BENEFICIARY_STATUS.ACTIVE | BENEFICIARY_STATUS.CANCELLED
  ): string[] => ({
    [BENEFICIARY_STATUS.ACTIVE]: [
      BENEFICIARY_STATUS.WAITING,
      BENEFICIARY_STATUS.PRE_CANCELLED,
      BENEFICIARY_STATUS.EXTENDED,
      BENEFICIARY_STATUS.ACTIVE,
      BENEFICIARY_STATUS.PRE_ACTIVE,
    ],

    [BENEFICIARY_STATUS.CANCELLED]: [
      BENEFICIARY_STATUS.CANCELLED,
      BENEFICIARY_STATUS.DELETED,
    ]
  }[status])

  const treatFilter = useCallback((updateFilters = {}): ObjectType => {
    const paramFilters = pickBy(
      pick(
        updateFilters,
        [
          'q',
          'page',
          'pageSize',
        ]
      ),
      Boolean
    )

    return  {
      'life.documents.type': updateFilters.cpf ? 'cpf' : undefined,
      'life.documents.value': updateFilters.cpf ? updateFilters.cpf?.replace(/\D/g, '') : undefined,
      'life.name': updateFilters.name || undefined,
      companyId,
      isHolder: updateFilters.type ? updateFilters.type === 'holder' : undefined,
      validFrom: updateFilters.validFrom ? getFormattedDate(updateFilters.validFrom) : undefined,
      validUntil: updateFilters.validUntil ? getFormattedDate(updateFilters.validUntil) : undefined,
      updatedAt: updateFilters.updatedAt ? getFormattedDate(updateFilters.updatedAt) : undefined,
      status: getStatusRefer(updateFilters.status),
      ...paramFilters,
    }
  }, [companyId])

  const filterNegotiations = useCallback((negotiations: Negotiation[], updateFilters) =>
    negotiations.filter((negotiation) => {
      const { benefitId, contractId } = updateFilters

      if (benefitId && contractId) {
        return negotiation.product?.benefit_id === benefitId &&
          negotiation.contractId === contractId
      }

      if (benefitId) {
        return negotiation.product?.benefit_id === benefitId
      }

      if (contractId) {
        return negotiation.contractId === contractId
      }

      return false
    }), [])

  const getBeneficiaries = useCallback(
    async (updateFilters: ObjectType): Promise<ListResponse<Beneficiary>> => {
      if (useContracts === null) {
        await loadContracts()
      }

      const filters = treatFilter(updateFilters)

      if (updateFilters.benefitId || updateFilters.contractId) {
        const negotiations = filterNegotiations(useNegotiationsRef.current, updateFilters)

        if (!negotiations.length) {
          return { data: [], totalPages: 0 }
        }

        filters.negotiationId = negotiations.map(({ id }) => id)
      }

      return beneficiariesService.index(filters) || {}
    }, [useContracts, treatFilter, loadContracts, filterNegotiations])

  return {
    useContracts: useContracts || [],
    getBeneficiaries,
    useEntityByNegotiationId,
    useTypeOptions,
    useStatusOptions,
  }
}

export default useBeneficiaries
