import React from 'react'

import { CustomList } from 'components'

import { ObjectType } from 'shareds/types'

import useContentList from '../../hooks/useContentList'

const innerHeight = window.innerWidth > 768 ? 60 : 80

type ContentListType = {
  data: ObjectType[] | null
  size: number
  linesLoader?: string | number
}

const ContentList: React.FC<ContentListType> = ({ data, linesLoader }) => {
  const { useDataList, useListRef } = useContentList()

  return (
    <CustomList
      listRef={useListRef}
      linesLoader={linesLoader}
      data={data}
      innerHeight={innerHeight}
      dataList={useDataList}
    />
  )
}

export default ContentList
