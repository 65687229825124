import React, { useImperativeHandle, ForwardRefRenderFunction } from 'react'

import { Text, Image } from 'vkit/lib/components'
import { Row, Grid, Box } from 'vkit/lib/context'

import { ButtonUnderline, Dropzone } from 'components'

import useFileUpload from './hooks/useFileUpload'

import style from './style/fileUpload.module.scss'

import fileUploadImage from 'assets/images/file-upload.png'

import { FileUploadInterface, InputClickRefInterface } from './types/fileUpload.types'

const FileUpload: ForwardRefRenderFunction<InputClickRefInterface, FileUploadInterface> = (
  props,
  forwardedRef
) => {
  const {
    onChange,
    allowedExtensions = [],
    formValidatorErrors,
    name,
    textHelper
  } = props

  const {
    handleFileUpload,
    handleUploadClick,
    inputFileRef,
    onFileDrop,
    setIsDragging,
    useFile,
    useIsDragging,
    getFileName,
    useError,
    useFileNotAccepted
  } = useFileUpload(name, allowedExtensions, onChange)

  useImperativeHandle(forwardedRef, () => ({
    onHandleFileSelect(e: any) {
      return handleUploadClick(e)
    }
  }))

  return (
    <Dropzone
      onDrop={(dropEvent: any) => onFileDrop(dropEvent)}
      onDragChange={(isDragging: boolean) => setIsDragging(isDragging)}
    >
      <Box
        type="sheet"
        {
        ...useError || formValidatorErrors[name] ? ({
          elevation: 2
        }) : ({
          type: 'sheet',
          elevation: 4,
          outlined: true,
          rounded: true,
        })
        }
      >
        <div className={`${style.fileUpload} ${(formValidatorErrors[name] || useError) && !useIsDragging ? style.danger : ''}`}>
          <Row
            style={{
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              alignSelf: 'auto',
              width: '100%',
              height: '100%',
              padding: 0,
            }}>
            <Row
              style={{
                padding: 0,
                width: '180px',
                height: '100%',
                alignItems: 'center',
                alignSelf: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
              }}
            >
              <Image
                height="86px"
                width="77px"
                type="rounded"
                alt="Ícone de arquivo de upload"
                src={fileUploadImage}
              />
            </Row>
            <Row
              style={{
                padding: 0,
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                alignSelf: 'center',
                justifyContent: 'flex-start',
              }}>
              <Grid
                alignItems="center"
                alignContent="center"
                wrap
              >
                <input
                  type='file'
                  ref={inputFileRef}
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                  accept={ allowedExtensions.map((ext) => `.${ext}`).join(',') }
                />

                {
                  !useFile && !useFileNotAccepted && (
                    <Row >
                      <Text
                        value={`${useIsDragging ? 'Solte' : 'Arraste'} aqui o arquivo ${textHelper ? textHelper : ''}`}
                        size="medium"
                      />
                      <Row style={{ marginTop: 20, padding: 0 }}>
                        <ButtonUnderline
                          label="Ou selecione o arquivo em seu computador"
                          align="left"
                          onClick={handleUploadClick}
                        />
                      </Row>
                    </Row>
                  )
                }

                <Row style={{ flex: 1, paddingLeft: 0 }}>
                  {
                    (useFile || useFileNotAccepted) && (
                      <Text
                        value={`<b> Arquivo:</b> ${getFileName(useFile || useFileNotAccepted)}`}
                        size="medium"
                      />
                    )
                  }

                  {useFileNotAccepted && (
                    <Row style={{ padding: '10px 0px 0px 0px' }}>
                      <Text
                        fontWeight="bold"
                        value="Este arquivo não está no formato correto"
                        size="medium"
                        color="danger"
                      />
                      <Text
                        value="Ajuste de acordo com o arquivo modelo e tente novamente"
                        size="medium"
                      />
                      <Row style={{ padding: '10px 0px 0px 0px' }}>
                        <ButtonUnderline
                          label="Alterar arquivo"
                          align="left"
                          onClick={handleUploadClick}
                        />
                      </Row>
                    </Row>
                  )}
                  {
                    useFile && (
                      <Row style={{ marginTop: '30px', padding: 0 }}>
                        <ButtonUnderline
                          label="Alterar arquivo"
                          align="left"
                          onClick={handleUploadClick}
                        />
                      </Row>
                    )
                  }
                </Row>
              </Grid>
            </Row>
          </Row>
        </div >
      </Box>
    </Dropzone>
  )
}

export default React.forwardRef(FileUpload)
