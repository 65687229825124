import React, { ReactNode } from 'react'
import { BoxInfo, TextData } from 'components'
import { valueFinder, responseParser } from 'utils/common'
import { monitoringStatus } from 'shareds/objects'
import { MonitoringFile } from 'services/monitoring/endpoints/monitoring/files'

type MonitoringDetailsType = {
  useLoading: boolean
  useMonitoring?: MonitoringFile | null
  header?: ReactNode
}

const MonitoringDetails: React.FC<MonitoringDetailsType> = ({
  useLoading,
  useMonitoring ,
  header
}) => (
  <BoxInfo
    icon='layers-outline'
    title='Detalhes da PC'
    header={header}
  >
    <TextData
      loading={useLoading}
      data={[
        {
          label: 'Nome do arquivo',
          text: responseParser(useMonitoring?.name, 'Nome não informado'),
          sizeDefault: 100,
          sizeMedium: 100,
        },
        {
          label: 'Formato do arquivo',
          text: responseParser(useMonitoring?.extension, 'Formato não informado'),
          sizeDefault: 33.33,
          sizeMedium: 100,
        },
        {
          label: 'Data de upload',
          text: responseParser(useMonitoring?.uploadedAt, 'Data não informado'),
          type: 'date',
          sizeDefault: 33.33,
          sizeMedium: 100,
        },
        {
          label: 'Status',
          text: valueFinder(monitoringStatus, useMonitoring?.status, 'Status não informado'),
          sizeDefault: 33.33,
          sizeMedium: 100,
        },
      ]}
    />
  </BoxInfo>
)

export default MonitoringDetails
