import { RoutesGroupType, RouteType } from 'vkit/lib/shared/router/types/router.type'
import { match, matchPath, useLocation } from 'react-router-dom'
import { ActionOption, BreadcrumbItem } from 'components/breadcrumb'
import { useCallback, useEffect, useState } from 'react'

interface RouteBreadcrumb extends RouteType {
  breadcrumb?: BreadcrumbItem
  showLastAccesses?: string
}

interface UseHeaderNavProps {
  routeGroups: RoutesGroupType[]
}

interface CurrentRoute {
  breadcrumb?: BreadcrumbItem[]
  route?: match
  title?: string
  icon?: string
  showLastAccesses?: string
}

interface UseHeaderNav {
  useCurrentRoute: CurrentRoute
}

const useHeaderNav = (
  {
    routeGroups
  }: UseHeaderNavProps
): UseHeaderNav => {
  const { pathname } = useLocation()
  const [useCurrentRoute, setCurrentRoute] = useState<CurrentRoute>({})
  const getBreadcrumbItemDTO =
    useCallback((route: RouteBreadcrumb, path: string): BreadcrumbItem => ({
      requestFindLabelFn: route.breadcrumb?.requestFindLabelFn,
      requestFindLabelResponsePathText: route.breadcrumb?.requestFindLabelResponsePathText,
      requestFindLabelParamIdName: route.breadcrumb?.requestFindLabelParamIdName,
      label: route.breadcrumb?.label || route.nameMenu,
      path,
      accessId: route.breadcrumb?.accessId,
      actionOptions: route.children?.reduce((acc: ActionOption[], child) => {
        if (!child.path.includes(':')) {
          acc.push({
            label: child.nameMenu,
            icon: child.icon,
            path: `${path}${child.path}`
          })
        }

        return acc
      }, [])
    }), [])

  const getCurrentRouteByRoutes = useCallback((
    routes: RouteBreadcrumb[],
    pathParent: string = '',
    breadcrumbParent: BreadcrumbItem[] = []
  ): CurrentRoute | null => {
    for (const route of routes) {
      const path = `${pathParent}${route.path}`
      const breadcrumb: BreadcrumbItem[] = [
        ...breadcrumbParent,
        getBreadcrumbItemDTO(route, path)
      ]

      const routeFound = matchPath(pathname, { path, exact: true })
      if (routeFound) {
        return {
          breadcrumb,
          route: routeFound,
          title: route.nameMenu,
          icon: route.icon,
          showLastAccesses: route.showLastAccesses
        }
      }

      const currentRouteChildren
        = route.children && getCurrentRouteByRoutes(route.children, path, breadcrumb)
      if (currentRouteChildren) {
        return currentRouteChildren
      }
    }

    return null
  }, [pathname, getBreadcrumbItemDTO])

  const getCurrentRouteByRouteGroups =
    useCallback((routeGroupPages: RoutesGroupType[]): CurrentRoute => {
      for (const { routes } of routeGroupPages) {
        const currentRoute = getCurrentRouteByRoutes(routes)
        if (currentRoute) {
          return currentRoute
        }
      }

      return {}
    }, [getCurrentRouteByRoutes])

  useEffect(() => {
    if (useCurrentRoute.breadcrumb) {
      return
    }
    const currentRoute = getCurrentRouteByRouteGroups(routeGroups)
    setCurrentRoute(currentRoute)
  }, [getCurrentRouteByRouteGroups, routeGroups, useCurrentRoute])

  return {
    useCurrentRoute
  }
}

export default useHeaderNav
