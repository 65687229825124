import restfulBase from 'services/negotiations/base'

export type Contact = {
  id?: string
  broker_id: string
  email: string
  name: string
  phone: string
  companyId: string
  role?: string
  isMainContact?: boolean
}

export const contactService = restfulBase<Contact>('contacts')

export default contactService
