import React from 'react'
import { Button } from 'vkit/lib/components'
import ColorsType from 'shareds/types/colors.type'
import SizeType from 'shareds/types/size.type'
import { classesBuilder } from 'utils'
import { Tooltip } from 'components/index'
import style from './buttonGroup.module.scss'

interface ButtonItem {
  icon?: string
  label?: string
  onClick?: () => void
  disabled?: boolean
  title?: string
}

interface ButtonGroupProps {
  active?: number
  buttons: ButtonItem[]
  color?: ColorsType;
  size?: SizeType
  solo?: boolean
}

const Index: React.FC<ButtonGroupProps> = (
  {
    active,
    color,
    size,
    buttons,
    solo,
  }
) => (
  <div className={classesBuilder(style, {
    buttonGroup: true,
    solo,
  })}>
    {
      buttons.map((button, index) => (
        <Tooltip title={button.title} key={index}>
          <Button
            iconColor={active !== index ? color : 'clear'}
            invertColor={active !== index}
            size={size}
            color={color}
            label={button.label}
            onClick={button.onClick}
            icon={button.icon}
            disabled={button.disabled}
            solo={solo && active !== index}
          />
        </Tooltip>
      ))
    }
  </div>
)

export default Index
