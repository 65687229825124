import restfulBase from 'services/accessControl/base'

export interface AuthTOTPConfirm {
  email: string
  password: string
  otp: string
}

export interface AuthTOTPConfirmResponse {
  token: string
}

export const authTOTPConfirmInitState = {
  email: '',
  password: '',
  otp: '',
}

export const authTOTPConfirmErrors = {
  Unauthorized: 'Usuário não autorizado',
  'User doesn\'t have mfa pending': 'Usuário não tem conta pendente',
}

export const authTOTPConfirmService =
  restfulBase<AuthTOTPConfirm, AuthTOTPConfirmResponse>('auth/mfa/totp/confirm')
