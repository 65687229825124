import React, { useEffect, useRef, useState } from 'react'
import SizeType from 'shareds/types/size.type'
import { Button, Icon } from 'vkit/lib/components'
import { Tooltip } from 'components'
import { Grid, Row } from 'vkit/lib/context'

interface ButtonCopyProps {
  text: string
  size?: SizeType
}

const ButtonCopy: React.FC<ButtonCopyProps> = (
  {
    text,
    size,
  },
) => {
  const [copied, setCopied] = useState(false)
  const timerRef = useRef(0)

  const handleCopy = async (): Promise<void> => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
  }

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = window.setTimeout(() => {
      setCopied(false)
    }, 1000)
  }, [copied])

  return (
    <Grid>
      <Tooltip title={copied ? 'Copiado' : 'Copiar'}>
        <Button
          color={copied ? 'success' : 'light'}
          invertColor
          onClick={handleCopy}
          size={size}
        >
          <Row style={{ padding: 3 }}>
            {
              copied ? (
                <Icon color='success' key='checkmark' name='checkmark' />
              ) : (
                <Icon key='copy' name='copy' />
              )
            }
          </Row>
        </Button>
      </Tooltip>
    </Grid>
  )
}

export default ButtonCopy
