import React, { useMemo, useRef, useState } from 'react'
import { get } from 'lodash'
import { BoxInfo, DataList, TextData, ToastStatusGlobal, Tooltip, WrapperPage } from 'components'
import useDetails from 'pages/moves/details/hooks/useDetails'
import { Button, Text, Toggle } from 'vkit/lib/components'
import { MonitoringRow, MonitoringRowMessage } from 'services/monitoring/endpoints/monitoring/rows'
import { MOVES_UPDATE_STATUS } from 'services/monitoring/endpoints/monitoring/files'
import { monitoringStatus } from 'shareds/objects'
import useConfirm from 'shareds/hooks/useConfirm'
import { Grid } from 'vkit/lib/context'
import { movesUpdateStatus } from 'services/monitoring/endpoints/monitoring/updateStatus'
import MoveAttachmentsTable from '../../../modules/moveAttachmentsTable/moveAttachmentsTable'
import DialogConfirmUpdateStatusForm, {
  DialogConfirmResponse,
} from './components/dialogConfirmUpdateStatusForm/dialogConfirmUpdateStatusForm'

interface JsonViewProps {
  data: string
  excerpt?: string
}

const JsonView: React.FC<JsonViewProps> = ({ data, excerpt }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const stringifyJson = (data: string): string => {
    if (!data) {
      return ''
    }

    try {
      return JSON.stringify(JSON.parse(data), null, 2)
    } catch (e) {
      return data
    }
  }

  if (!data) {
    return null
  }

  const content = isOpen ? stringifyJson(data) : (excerpt || data).substring(0, 45).padEnd(45, ' ')
  const tooltipTitle = isOpen ? 'Fechar' : 'Abrir'

  return data ? (
    <Grid alignContent='justify' alignItems='center'>
      <pre>{content}</pre>
      <Tooltip title={tooltipTitle}>
        <Button key='collapse-outline' onClick={() => setIsOpen(!isOpen)} icon='collapse-outline' />
      </Tooltip>
    </Grid>
  ) : null
}

function getErrorMessage(messages: MonitoringRowMessage[]): string {
  return JSON.stringify(
    messages
      .filter((message) => message.type === 'error')
      .map((message) => get(message, 'content.data.error', '')),
    null,
    4,
  )
}

const Details: React.FC = () => {
  const { toast } = ToastStatusGlobal();
  const { loadRows, useMove, useRows, useTotalPages, dataListRef, updateStatusMove } = useDetails()
  const { confirm, dialogConfirm } = useConfirm()
  const [openDialogConfirmUpdateStatusForm, setOpenDialogConfirmUpdateStatusForm] = useState(false)
  const fnCallback = useRef<(response: DialogConfirmResponse) => void>()

  const selectableStatus = useMemo(() => {
    return Object.entries(monitoringStatus).map(([key, value]) => {
      return {
        text: value,
        value: key
      }
    })
  }, [])

  const confirmUpdateStatusMoveAndGetReason = async (status: string) => {
    let confirmUpdate = true
    let reason = ''

    if (status === MOVES_UPDATE_STATUS.PROCESSED) {
      confirmUpdate = await confirm(
        'Atenção',
        `Ao alterar o status para <b>Processado</b> você não
            poderá alterá-lo novamente. Quer mesmo fazer isso?`
      )
    }

    if (status === MOVES_UPDATE_STATUS.REJECTED) {
      await new Promise((resolve) => {
        fnCallback.current = (response: DialogConfirmResponse): void => {
          setOpenDialogConfirmUpdateStatusForm(false)
          confirmUpdate = response.confirm
          reason = response.reason
          resolve(response)
        }

        setOpenDialogConfirmUpdateStatusForm(true)
      })
    }

    return {
      confirmUpdate,
      reason,
    }
  }

  const onChangeStatus = async (status: MOVES_UPDATE_STATUS): Promise<void> => {
    if (useMove.id && status !== useMove.status) {
      try {
        const {
          confirmUpdate,
          reason,
        } = await confirmUpdateStatusMoveAndGetReason(status)

        if (confirmUpdate) {
          await movesUpdateStatus.update(useMove.id, { status, rejectionReason: reason })
          updateStatusMove(status, reason);
          toast('Feito!', 'Status do arquivo alterado com sucesso!', 'success')
        }
      } catch (e) {
        toast('Oops!', 'Não foi possível alterar o status do arquivo.', 'error')
        console.error('error', e);
      }
    }
  }

  return (
    <WrapperPage>
      { dialogConfirm }

      <DialogConfirmUpdateStatusForm
        open={openDialogConfirmUpdateStatusForm}
        fnCallback={fnCallback.current}
      />

      <BoxInfo
        title='Dados da movimentação'
        icon='info-outline'
        header={
          <Button
            disabled={!useMove.link}
            label='Download da planilha'
            onClick={() => window.open(useMove.link)}
            icon='download-outline'
          />
        }
      >
        <TextData
          loading={!useMove.id}
          data={[
            {
              label: 'Nome do arquivo',
              text: useMove?.name,
              sizeDefault: 100,
              sizeMedium: 100,
            },
            {
              label: 'Formato do arquivo',
              text: useMove?.extension,
              sizeDefault: 33.33,
              sizeMedium: 100,
            },
            {
              label: 'Criada em',
              text: useMove?.uploadedAt,
              type: 'dateTime',
              sizeDefault: 33.33,
              sizeMedium: 100,
            },
            {
              label: 'Status',
              sizeDefault: 33.33,
              sizeMedium: 100,
              type: 'select',
              data: selectableStatus,
              selected: useMove?.status,
              onSelectectChange: onChangeStatus,
              disabled: [
                MOVES_UPDATE_STATUS.PROCESSED,
                MOVES_UPDATE_STATUS.REJECTED,
              ].includes(useMove.status),
            },
            {
              hidden: !useMove?.rejectionReason,
              label: 'Motivo da rejeição',
              text: useMove?.rejectionReason,
              sizeDefault: 100,
              sizeMedium: 100,
            },
            {
              label: 'Código do arquivo',
              text: useMove?.id,
              sizeDefault: 75,
              sizeMedium: 75,
            },
            {
              label: 'Quantidade de beneficiários',
              text: useMove?.lines.toString(),
              sizeDefault: 25,
              sizeMedium: 25,
            },
          ]}
        />
      </BoxInfo>

      <div ref={dataListRef}>
        <DataList
          filter={{
            initialValues: {
              onlyErrors: 1,
            },
            searchField: ({ debounceLoad, onChangeData, values }) => (
              <Grid row margin='0 0 8px' alignItems='center'>
                <Toggle
                  checked={+values?.onlyErrors === 1}
                  handleChange={() => {
                    const onlyErrors = values?.onlyErrors === 1 ? 0 : 1
                    onChangeData('onlyErrors', onlyErrors)
                    debounceLoad({ onlyErrors: onlyErrors })
                  }}
                  small
                />
                <Text size='medium' margin='0 0 0 8px' value='Mostrar apenas linhas com erro.' />
              </Grid>
            ),
          }}
          totalPages={useTotalPages}
          onLoadData={loadRows}
          elevation={4}
          data={useRows}
          columns={[
            {
              title: 'LINHA',
              width: '50px',
              showOnMobile: true,
              value: ({ line }: MonitoringRow) => (
                <Text value={line || 'Linha não informada'} title size='small' />
              ),
            },
            {
              title: 'Dados',
              darken: true,
              showOnMobile: true,
              value: ({ content }: MonitoringRow) => content && <JsonView data={content} />,
            },
            {
              title: 'Erro',
              darken: true,
              showOnMobile: true,
              value: ({ error, messages }: MonitoringRow) =>
                error && <JsonView data={getErrorMessage(messages || [])} excerpt={error} />,
            },
          ]}
        />
      </div>

      <MoveAttachmentsTable attachments={useMove.attachments} />
    </WrapperPage>
  )
}

export default Details
