import { useState, useMemo, useEffect } from 'react';
import { get } from 'lodash'

import { useHistoryParams } from 'navigation';
import {
  MovesValidationError,
  movesValidationErrorInitialState,
  movesValidationService,
} from 'services/fileMoves/endpoints/movesValidation'

import { TFormData } from '../types/FormData.type';

import { IProvider } from '../types/Provider.type';

import { useFormDataValidator } from './'

import { getMessageError } from 'utils/getMessageError'

type FormDataType = IProvider | string | boolean | null;

interface IUseFormData {
  setData: (key: string, value: FormDataType) => void
  formValues: TFormData,
  isDisabledSubmit: boolean,
  handleSubmit: () => void,
  isLoading: boolean,
  useError: MovesValidationError,
  hasSubmitError: boolean,
  resetSubmitError: () => void,
  hasSubmitSuccess: boolean,
}

const initialFormData: TFormData = {
  scope: 'broker',
  provider: null,
  movimentationType: null,
  templateDownloaded: false,
  files: null,
  attachment: null,
};

const useFormData = (toast: Function): IUseFormData => {

  const { companyId } = useHistoryParams()
  const [formValues, setFormValues] = useState<TFormData>(initialFormData)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [useError, setError] = useState<MovesValidationError>(movesValidationErrorInitialState)
  const [hasSubmitError, setSubmitError] = useState<boolean>(false);
  const [hasSubmitSuccess, setSubmitSucces] = useState<boolean>(false);

  const {
    isValidFormData,
    getFormDataValues
  } = useFormDataValidator(formValues, companyId);

  const setData = (key: string, value: FormDataType): void => {
    const current = formValues;
    setFormValues({
      ...current,
      [key]: value,
    })
  }

  const handleSubmit = async (): Promise<void> => {
    if (!isValidFormData) {
      return;
    }
    setLoading(true);
    try {
      const formData = await getFormDataValues()
      await movesValidationService.send(formData)
      toast('Feito!', 'O arquivo foi enviado.', 'success')
      setSubmitSucces(true);
      setFormValues(initialFormData);
    } catch (error) {
      console.error(error)
      const messageError = getMessageError({
        error,
        messageDefault: 'Falha ao fazer upload do arquivo!'
      })
      toast('Oops!', messageError, 'error')
      const errorResponse = get(error, 'response.data', movesValidationErrorInitialState)
      if (errorResponse.details.length) {
        setError(errorResponse);
        setSubmitError(true);
        setFormValues(initialFormData);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetSubmitError = (): void => {
    setSubmitError(false);
    setError(movesValidationErrorInitialState);
  }

  const isDisabledSubmit = useMemo((): boolean => {
    return !isValidFormData && !isLoading;
  }, [isLoading, isValidFormData])

  useEffect(() => {
    const initial = initialFormData;
    initial.provider = formValues.provider;
    setFormValues(initial);
  }, [formValues.provider])

  return {
    setData,
    formValues,
    isDisabledSubmit,
    handleSubmit,
    isLoading,
    useError,
    hasSubmitError,
    resetSubmitError,
    hasSubmitSuccess,
  }
};

export default useFormData;
