import * as React from 'react'

import { ToastStatus, BoxInfo, ShowHide } from 'components'

import { UploadContextType } from '../types/UploadContext.types'

import {
  Action,
  Errors,
  SelectProvider,
  SelectType,
  Success,
  TemplateDownload,
  UploadFile,
} from '../components'

import { useFormData, useComponentsVisibility } from '../hooks'
import { useHistoryParams } from '../../../../navigation'

export const UploadContext = React.createContext<UploadContextType | null>(null)

const UploadProvider: React.FC = () => {
  const { toast, useToast: triggerToast } = ToastStatus()
  const { companyId } = useHistoryParams()
  const {
    formValues,
    setData,
    isDisabledSubmit,
    handleSubmit,
    isLoading,
    useError,
    hasSubmitError,
    resetSubmitError,
    hasSubmitSuccess,
  } = useFormData(triggerToast)

  const {
    showMovimentationType,
    showDownloadUploadField,
    showAttachmentField,
    showFormComponents,
    showSubmitError,
    showSubmitSuccess,
  } = useComponentsVisibility({
    formData: formValues,
    hasError: hasSubmitError,
    hasSuccess: hasSubmitSuccess,
  })

  return (
    <UploadContext.Provider
      value={{
        formData: formValues,
        setData,
        triggerToast,
        isLoading,
      }}
    >
      <ShowHide visible={showFormComponents}>
        <BoxInfo>
          <form onSubmit={() => handleSubmit()}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
              <SelectProvider />

              <ShowHide visible={showMovimentationType}>
                <SelectType />
              </ShowHide>

              <ShowHide visible={showDownloadUploadField}>
                <TemplateDownload />

                <UploadFile />
              </ShowHide>

              <ShowHide visible={showAttachmentField}>
                <UploadFile isAttachment={true} />
              </ShowHide>

              <ShowHide visible={showDownloadUploadField}>
                <Action
                  isDisabled={isDisabledSubmit}
                  isLoading={isLoading}
                  onClick={() => handleSubmit()}
                />
              </ShowHide>
            </div>
          </form>
        </BoxInfo>
      </ShowHide>

      <ShowHide visible={showSubmitError}>
        <Errors details={useError.details} setUploadFormStatus={resetSubmitError} />
      </ShowHide>

      <ShowHide visible={showSubmitSuccess}>
        <Success callbackUrl={`/companies/${companyId}/moves`} />
      </ShowHide>

      {toast}
    </UploadContext.Provider>
  )
}

export default UploadProvider
