import { useState, useRef, MutableRefObject, MouseEventHandler, ChangeEventHandler } from 'react'

interface UseFileUpload {
  useIsDragging: boolean
  setIsDragging: Function
  setFile: Function
  onFileDrop: Function
  handleFileUpload: ChangeEventHandler<HTMLInputElement>
  handleUploadClick: MouseEventHandler<HTMLButtonElement>
  inputFileRef: MutableRefObject<HTMLInputElement | null>
  getFileName: Function
  useError: boolean
  useFile: any
  useFileNotAccepted: any
}

const useFileUpload = (
  name: string,
  allowedExtensions: Array<string>,
  onChange: Function,
): UseFileUpload => {
  const inputFileRef = useRef<HTMLInputElement>(null)

  const [useFile, setFile] = useState<any>(null);
  const [useIsDragging, setIsDragging] = useState(false)
  const [useError, setError] = useState(false)
  const [useFileNotAccepted, setFileNotAccepted] = useState<any>(null)

  const getFileName = (fileName: any): string =>
    fileName.name ? fileName.name.replace(/^.*[\\\/]/, '') : fileName.replace(/^.*[\\\/]/, '')

  const getFileExt = (fileName: any): string => fileName.split('.').pop()

  const getFileIsValid = (fileExt: string): boolean => allowedExtensions.includes(fileExt)

  const onFileDrop = (e: any): void => {
    const file = e.dataTransfer.files[0]

    const fileExt = getFileExt(file.name)
    const isValid = getFileIsValid(fileExt)

    if (!isValid) {
      setError(true)
      setFileNotAccepted(file.name)
      setFile(null)
    } else {
      setError(false)
      setFileNotAccepted(null)
      setFile(file.name)
    }

    onChange(name, file, isValid)
  }

  const handleFileUpload = (): any => {
    const file = inputFileRef.current?.files ? inputFileRef.current?.files[0] : null

    if (file) {
      const fileExt = getFileExt(file.name)
      const isValid = getFileIsValid(fileExt)

      if (!isValid) {
        setError(true)
        setFileNotAccepted(file.name)
        setFile(null)
      } else {
        setError(false)
        setFileNotAccepted(null)
        setFile(file.name)
      }

      onChange(name, file, isValid)
    }
  }

  const handleUploadClick = (e: any | null): void => {
    e?.preventDefault()

    inputFileRef?.current?.click()
  };

  return {
    useFileNotAccepted,
    useError,
    getFileName,
    inputFileRef,
    useIsDragging,
    setIsDragging,
    useFile,
    setFile,
    onFileDrop,
    handleFileUpload,
    handleUploadClick,
  }
}

export default useFileUpload
