import imageUrl from 'assets/images/vwing.svg';
import React from 'react';
import { Button, Image, Text } from 'vkit/lib/components';
import { Box, Grid, Row } from 'vkit/lib/context';
import { StructureAuth } from 'vkit/lib/modules';

const AlertBrokerPortalDiscontinuation: React.FC = () => {
  return (
    <StructureAuth>
      <div style={{ width: '100vw' }}>
        <Grid alignContent='center'>
          <Row>
            <Row style={{ padding: 0 }}>
              <Grid alignContent='center'>
                <Image
                  width='83px'
                  height='36px'
                  src={imageUrl}
                />
              </Grid>
              <Row>
                <Grid alignContent='center'>
                  <Text value='Portal da corretora' title />
                </Grid>
              </Row>
            </Row>
            <Box elevation={10} type='card' outlined>
              <Grid column padding={30} alignItems='center' gap={32}>
                <Text title size='medium' value='Descontinuação do portal da corretora' applyBaseColor rounded padding='8px 16px' color='warning' />
                <Text textAlign='center' size='larger' value='Informamos que o acesso ao Portal da Corretora foi descontinuado.<br />Todas as funcionalidades agora estão disponíveis no Portal do RH.<br />Para explorar e utilizar as ferramentas, clique no botão abaixo.' />
                <Button
                  size='larger'
                  label='Acessar portal do RH'
                  onClick={ () => window.location.replace('https://v2.empresas.vitta.com.br/auth/login') }
                />
              </Grid>
            </Box>
            <Row>
              <Grid alignContent='center'>
                <Text
                  value={`${new Date().getFullYear()} © Vitta Coordenação em saúde`}
                  color='light'
                />
              </Grid>
            </Row>
          </Row>
        </Grid>
      </div>
    </StructureAuth>
  );
};

export default AlertBrokerPortalDiscontinuation;
