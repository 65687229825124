import apiService from 'vkit/lib/services/apiService'

type ObjectType = { [key: string]: any }
type RequestConfigType = (...item: any) => Promise<ObjectType | undefined>

interface IRestfulService {
  get: RequestConfigType
}

const restfulService = (): IRestfulService => {
  const apiConfig = {
    uri: process.env.REACT_APP_API_SEARCH_ADDRESS,
    timeout: 60000,
    headers: new Headers(),
  }

  apiService.config(apiConfig)

  const get = async (
    cep: object,
    onError: (Response: Response) => any,
  ): Promise<ObjectType | undefined> =>
    apiService.request({
      router: `/ws/${cep}/json`,
      onError,
    })

  return {
    get,
  }
}

export default restfulService
