import { useState } from 'react'
import { removeStoredData } from 'utils/authentication'

interface UseAuthInterface {
  onLogout: () => void
  useLoading: boolean
  useMessageError: string
}

const UseLogout = (): UseAuthInterface => {
  const [useLoading, setLoading] = useState(false)
  const [useMessageError, setMessageError] = useState('')

  const onLogout = async (): Promise<void> => {
    setLoading(true)

    try {
      removeStoredData()
      window.location.replace('login')
    } catch (error) {
      setMessageError('Erro ao sair da sessão.')
    } finally {
      setLoading(false)
    }
  }

  return {
    onLogout,
    useLoading,
    useMessageError,
  }
}

export default UseLogout
