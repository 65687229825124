import { useCallback, useState } from 'react'
import { ObjectType, SelectOption } from 'shareds/types'
import { randomKey } from 'utils'
import {
  FinancialAdjustment,
  financialAdjustmentsService,
} from 'services/negotiations/endpoints/financialAdjustments'
import { useHistoryNavigator, useHistoryParams } from 'navigation'
import { addMonths, format, parseISO  } from 'date-fns'
import { AxiosError } from 'axios'
import { ToastStatusGlobal } from 'components'

export interface IUseFinancialAdjustmentForm {
  addFinancialAdjustment: Function
  addSelected: Function
  create: Function
  deleteFinancialAdjustment: (id: string) => void
  onChangeForm: Function
  useFinancialAdjustments: FinancialAdjustment[]
  useForm: ObjectType
  useLoading: ObjectType
  useSelecteds: ObjectType
}

type StatusRegister = 'waiting' | 'loading' | 'ok' | 'pending'
type PortionType = 'discount' | 'charge'

export interface FiedsForm extends FinancialAdjustment {
  countMonths: number
  status: StatusRegister
  portionType: PortionType
}

const useCreateOrUpdate = ():IUseFinancialAdjustmentForm => {
  const { toast } = ToastStatusGlobal()
  const [useLoading, setLoading] = useState<ObjectType>({})
  const [useFinancialAdjustments, setFinancialAdjustments] = useState<FiedsForm[]>([])
  const [useForm, setForm] = useState<Partial<FiedsForm>>({})
  const [useSelecteds, setSelecteds] = useState<ObjectType>({
    contracts: {},
  })
  const { companyGroupId } = useHistoryParams()
  const navigate = useHistoryNavigator()

  const addSelected = (entityName: string, selectOption: SelectOption): void =>
    setSelecteds((selecteds) => {
      if (!entityName || !selectOption) {
        return selecteds
      }

      const { text, value } = selectOption
      selecteds[entityName][value] = text

      return selecteds
    })

  const financialAdjustmentDTO = useCallback(
    (financialAdjustment: FinancialAdjustment): FinancialAdjustment => {
      const date = parseISO(financialAdjustment.date)
      return {
        companyGroupId,
        contractId: financialAdjustment.contractId,
        description: financialAdjustment.description,
        unit: financialAdjustment.unit,
        date: format(date, 'dd/MM/yyyy'),
        value: financialAdjustment.value,
      }
    }, [companyGroupId])

  const addFinancialAdjustment = async (fields: FiedsForm): Promise<void> => {
    try {
      const newFinancialAdjustments = Array.from({
        length: +fields.countMonths || 1
      }).map((_, countMonth) => {
        const date = parseISO(fields.date)
        return {
          id: String(randomKey()),
          companyGroupId,
          contractId: fields.contractId,
          description: fields.description,
          unit: fields.unit,
          date: format(addMonths(date, countMonth),'yyyy-MM-dd'),
          value: fields.portionType === 'charge'
            ? Math.abs(fields.value)
            : -Math.abs(fields.value),
          contract: {
            code: useSelecteds.contracts[fields.contractId]
          },
          status: 'pending',
        }
      }) as FiedsForm[]

      setFinancialAdjustments((financialAdjustments) => [
        ...financialAdjustments,
        ...newFinancialAdjustments,
      ])

      setForm({})
    } catch (error) {
      const {
        message
      } = (error as AxiosError).response?.data
      const messageError = message || 'Falha ao adicionar o ajuste financeiro.'
      toast('Oops!', messageError, 'error')
      console.error(error)
    } finally {
      setLoading({ addFinancialAdjustment: false })
    }
  }

  const onChangeForm = (data: FiedsForm): void =>
    setForm((form) => ({
      ...form,
      ...data
    }))

  const deleteFinancialAdjustment = (id: string): void =>
    setFinancialAdjustments((financialAdjustments) => {
      const index = financialAdjustments
        .findIndex((financialAdjustment) => financialAdjustment.id === id)
      if (index > -1) financialAdjustments.splice(index, 1)
      return [ ...financialAdjustments ]
    })

  const statusFinancialAdjustment = (key: string, status: StatusRegister): void =>
    setFinancialAdjustments((financialAdjustments) => {
      const index = financialAdjustments
        .findIndex((financialAdjustment) => financialAdjustment.id === key)
      if (index > -1) financialAdjustments[index].status = status
      return [ ...financialAdjustments ]
    })

  const create =
    useCallback(async (newFinancialAdjustments: FinancialAdjustment[]): Promise<void> => {
      try {
        setLoading({ create: true })
        newFinancialAdjustments.forEach(({ id }) => statusFinancialAdjustment(id!, 'waiting'))

        for (const newFinancialAdjustment of newFinancialAdjustments) {
          statusFinancialAdjustment(newFinancialAdjustment.id!, 'loading')
          const financialAdjustment = financialAdjustmentDTO(newFinancialAdjustment)
          await financialAdjustmentsService.create(financialAdjustment)
          statusFinancialAdjustment(newFinancialAdjustment.id!, 'ok')
        }

        toast('Feito', 'Ajustes financeiros adicionados.', 'success')
        navigate.push(`/company-groups/${companyGroupId}/financial-adjustments`)
      } catch (error) {
        const {
          message
        } = (error as AxiosError).response?.data
        const messageError = message || 'Falha ao criar o ajuste financeiro.'
        toast('Oops!', messageError, 'error')
        console.error(error)
      } finally {
        setLoading({ create: false })
      }
    }, [navigate, companyGroupId, financialAdjustmentDTO, toast])

  return {
    addFinancialAdjustment,
    addSelected,
    create,
    deleteFinancialAdjustment,
    onChangeForm,
    useFinancialAdjustments,
    useForm,
    useLoading,
    useSelecteds,
  }
}

export default useCreateOrUpdate
