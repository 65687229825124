import { useHistory, useLocation, useParams } from 'react-router-dom'
import { removeInvalids } from 'utils'
import qs from 'qs'

export const useHistoryLocation = (): any => useLocation()
export const useHistoryParams = (): any => useParams()
export const useHistoryQuery = (): any => {
  const { search } = useLocation()
  if (!search) return {}
  const queryString = search.substring(1)
  return qs.parse(queryString)
}
export const useHistoryNavigator = (): {
  goBack: Function
  push: Function
  replace: Function
  search: (...args: any) => any
} => {
  const history = useHistory()

  return {
    replace: history.replace,
    goBack: () => history.go(-1),
    push: (to: string, props: any) => history.push(to, { ...props }),
    search: (params: any) => {
      const validParams = removeInvalids(params)
      const searchString = qs.stringify(validParams, { arrayFormat: 'brackets', allowDots: true })
      history.replace({ search: searchString })
    },
  }
}
