import restfulBase from 'services/accessControl/base'
import { ObjectType } from 'shareds/types'

export interface AuthResetPasswordConfirmation {
  code: string
  newPassword: string
  userId: string
}

export const authResetPasswordConfirmationInitState = {
  code: '',
  newPassword: '',
  userId: '',
}

export const authResetPasswordConfirmationError: ObjectType = {
  'Already confirmed.': 'Senha já alterada, para alterar a senha novamente, por favor, clique em <b>Enviar um novo código</b>.',
}

export const authResetPasswordConfirmationService =
  restfulBase<AuthResetPasswordConfirmation>('auth/password-recovery/confirmation')
