import restfulBase from 'services/dataTeam/base'

export type FamilyGroupMember = {
  nome: string
}

export type StatusFamilyGroup = {
  erro: string
  mensagem: string
  status: 'invalid' | 'valid'
}

export type StatusFamilyGroupFilter = {
  limit: number
  pageNumber: number
}

export const initialStateStatusFamilyGroup = {
  error: {
    titular: [],
    dependentes: []
  },
  message: {
    titular: {},
    dependentes: []
  },
  status: false
}

export const statusFamilyGroupService =
  restfulBase<StatusFamilyGroup, StatusFamilyGroupFilter>('posicao-cadastral/:cadastralPositionId/grupos-familiares')
