import React, { ReactNode } from 'react'
import { ContentList, Filter } from './components'
import { BoxInfo, Pagination, SelectPageSize } from 'components'
import { Row, Grid } from 'vkit/lib/context'
import useDataList from './hooks/useDataList'
import { ObjectType } from 'shareds/types'
import { Column } from './hooks/useContentList'
import Space from 'components/space'
import Divider from '../divider'

type DataListType = {
  loading?: boolean
  onLoadData?: Function
  noPagination?: boolean
  filter?: {
    initialValues?: ObjectType
    searchField?: string | ((...itens: any) => ReactNode)
    fields?: (...itens: any) => ReactNode
  }
  header?: {
    right?: ReactNode
    left?: ReactNode
  }
  data: ObjectType[] | null
  columns: Column[]
  totalPages?: number
  linesLoader?: string | number
  elevation?: number
}

const DataList: React.FC<DataListType> = (
  {
    loading,
    onLoadData,
    filter,
    header,
    noPagination,
    data,
    columns,
    totalPages,
    linesLoader,
    elevation,
  }
) => {
  const {
    onChangeFilter,
    setListBlur,
    setPaginate,
    useListBlur,
    usePagination,
  } = useDataList({
    loadData: onLoadData,
    totalPages,
    initialValues: filter?.initialValues
  })

  return (
    <>
      <Row style={{ padding: 0 }}>
        <Grid alignContent='justify' alignItems='center' wrap>
          {header?.left}

          {
            (filter?.fields || filter?.searchField) && (
              <Filter
                loading={loading}
                onChange={onChangeFilter}
                onShow={setListBlur}
                initialValues={filter.initialValues}
                fields={filter.fields}
                label={filter.searchField ? 'MAIS FILTROS' : 'FILTROS'}
                searchField={filter.searchField}
              />
            )
          }

          <Space />
          {header?.right}
        </Grid>
      </Row>

      <Row style={{ padding: 0 }}>
        <BoxInfo elevation={elevation}>
          <Row style={{ padding: 0, margin: -20 }}>
            <ContentList
              linesLoader={linesLoader || usePagination.pageSize}
              columns={columns}
              data={data}
              blur={useListBlur}
            />
          </Row>

          {
            !noPagination && (
              <Row style={{ padding: 0, margin: '10px -20px 0' }}>
                <Divider />
                <Grid margin='10px 10px 0' alignContent='justify'>
                  <SelectPageSize
                    onChangePageSize={(pageSize: number) => setPaginate({ page: 1, pageSize })}
                    pageSize={usePagination.pageSize}
                  />

                  <Pagination
                    page={usePagination.page}
                    total={usePagination.total}
                    itemsPerPage={usePagination.pageSize}
                    onChange={(page: number) => setPaginate({ page })}
                  />
                </Grid>
              </Row>
            )
          }
        </BoxInfo>
      </Row>
    </>
  )
}

export default DataList
