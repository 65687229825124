import React from 'react'
import { ContractNegotiations, ShowHide } from 'components'
import { Text } from 'vkit/lib/components'
import { Row } from 'vkit/lib/context'
import { Contract } from 'services/negotiations/endpoints/contracts'
import { ObjectType } from 'shareds/types'

export interface ContractsProps{
  data: Contract[] | null
  useLoading: ObjectType
  onDelete?: {
    negotiation: (id: string) => Promise<void>
    contract: (id: string, validUntil: string) => Promise<void>
  }
  item?: {
    header?: {
      right?: (
        options: {
          contract?: Contract,
          isCancelled: boolean
          isExperied: boolean,
        }
      ) => React.ReactNode
    }
  }
}

const ContractList: React.FC<ContractsProps> = (
  {
    data,
    useLoading,
    onDelete,
    item,
  }
) => (
  <Row style={{ padding: 0 }}>
    <ShowHide
      transition='slideToDown'
      visible={Array.isArray(data) && !!data.length}
    >
      {
        data?.map((contract) => (
          <ContractNegotiations
            contract={contract}
            header={item?.header}
            key={contract.id}
            loading={useLoading}
            onDelete={onDelete}
          />
        ))
      }
    </ShowHide>

    <ShowHide
      transition='slideToDown'
      visible={Array.isArray(data) && !data.length}
    >
      <ContractNegotiations loading={{ negotiations: true }}/>
    </ShowHide>

    <ShowHide
      transition='slideToDown'
      visible={!Array.isArray(data)}
    >
      <Text
        applyBaseColor
        color='warning'
        icon='alert-triangle-outline'
        iconColor='warning'
        padding='8px 12px'
        rounded
        size='medium'
        title
        value='Nenhum resultado encontrado.'
      />
    </ShowHide>
  </Row>
)

export default ContractList
