import React, { ReactNode } from 'react'

import { Select } from 'vkit/lib/components'
import { ObjectType } from 'shareds/types'

enum StatusEnum {
  success = 'success',
  error = 'error',
}

export interface SelectOption {
  text: string
  value: string | number
}

export interface ColumnSelectOption {
  title: string
  path: string
  custom?: (option: ObjectType) => ReactNode
}

interface TextFieldValidate {
  autoRequest?: boolean
  columns?: ColumnSelectOption[]
  data?: SelectOption[] | null
  disabled?: boolean
  label?: string
  loading?: boolean
  multiple?: boolean
  name: string
  onBlur?: Function
  onChange?: Function
  placeholder?: string
  requestHeaders?: { [key: string]: string; };
  requestPageParam?: string
  requestParams?: { [key: string]: string; };
  requestResponseRootPath?: string
  requestResponseText?: string
  requestResponseValue?: string
  requestRouter?: string
  requestSearchParam?: string
  requestUri?: string
  required?: boolean
  searchable?: boolean
  small?: boolean
  useErrors?: ObjectType
  useValues: ObjectType
  customOption?: (option: SelectOption) => ReactNode
}

const SelectValidate: React.FC<TextFieldValidate> = (
    {
    autoRequest,
    columns,
    data,
    disabled,
    label,
    loading,
    multiple,
    name,
    onBlur,
    onChange,
    placeholder,
    requestHeaders,
    requestPageParam,
    requestParams,
    requestResponseRootPath,
    requestResponseText,
    requestResponseValue,
    requestRouter,
    requestSearchParam,
    requestUri,
    required,
    searchable,
    small,
    useErrors,
    useValues,
    customOption,
  }
) => (
  <Select
    autoRequest={autoRequest}
    columns={columns}
    clearable
    customOption={customOption}
    data={data}
    disabled={disabled}
    label={label}
    loading={loading}
    multiple={multiple}
    onBlur={(value) => !disabled && onBlur?.(name, value)}
    onChange={(value, selected: SelectOption) => !disabled && onChange?.(name, value, selected)}
    placeholder={placeholder}
    requestHeaders={requestHeaders}
    requestPageParam={requestPageParam}
    requestParams={requestParams}
    requestResponseRootPath={requestResponseRootPath}
    requestResponseText={requestResponseText}
    requestResponseValue={requestResponseValue}
    requestRouter={requestRouter}
    requestSearchParam={requestSearchParam}
    requestUri={requestUri}
    requiredSign={required}
    searchable={searchable}
    small={small}
    status={!disabled && useErrors?.[name] ? StatusEnum.error : undefined}
    textHelper={!disabled ? useErrors?.[name] ?? '' : undefined}
    value={!disabled ? useValues[name] ?? '' : ''}
  />
)

export default SelectValidate
