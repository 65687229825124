import React from 'react'
import { IFrameUsers, WrapperPage } from 'components'

const {
  REACT_APP_BROKER_ID = ''
} = process.env

const List: React.FC = () => (
  <WrapperPage>
    <IFrameUsers
      title='Usuários'
      scopeType='broker'
      scopeId={REACT_APP_BROKER_ID}
    />
  </WrapperPage>
)

export default List
