import React, { useCallback, useEffect, useState } from 'react'
import { MonitoringDetails } from './components'
import { WrapperPage, DataTable } from 'components'
import { Button, Chip } from 'vkit/lib/components'
import { downloadFileLink } from 'utils/common'
import { MonitoringFile } from 'services/monitoring/endpoints/monitoring/files'
import { useHistoryParams } from 'navigation'
import { monitoringFilesService, monitoringRowsService } from 'services/monitoring'
import { Grid } from 'vkit/lib/context'

const Details: React.FC = () => {
  const [useLoading, setLoading] = useState(false)
  const [useMonitoring, setMonitoring] = useState<MonitoringFile | null>(null)
  const { companyId, cadastralPositionId } = useHistoryParams()

  const loadMonitoring = useCallback(async (): Promise<void> => {
    setLoading(true)

    try {
      const { data: monitoringData } = await monitoringFilesService.index({
        companyId,
        id: cadastralPositionId
      })

      if (monitoringData.length) {
        setMonitoring(monitoringData[0] || null)
      }
    } catch {
      setMonitoring(null)
    } finally {
      setLoading(false)
    }
  }, [cadastralPositionId, companyId])

  useEffect(() => {
    if (!companyId || !cadastralPositionId) {
      return
    }

    (async () => loadMonitoring())()
  }, [loadMonitoring, companyId, cadastralPositionId])

  return (
    <WrapperPage>
      <MonitoringDetails
        header={
          <Button
            disabled={!useMonitoring?.link}
            onClick={() => useMonitoring?.link && downloadFileLink(useMonitoring.link, '')}
            label='Baixar arquivo'
            color='default'
            icon='download-outline'
          />
        }
        useLoading={useLoading}
        useMonitoring={useMonitoring}
      />

      <DataTable
        fnRequest={{
          index: (filers) => monitoringRowsService.index({
            ...filers,
            sort: 'line',
            fileId: cadastralPositionId
          })
        }}

        columns={[
          {
            title: 'Linha',
            path: 'line',
            sortable: true,
          },
          {
            custom: ({ lifeProcessed }) => (
              <Grid>
                <Chip
                  rounded
                  size='small'
                  label={lifeProcessed ? 'Processado' : 'Não processado'}
                  color={lifeProcessed ? 'success' : 'info'}
                />
              </Grid>
            ),
            path: 'lifeProcessed',
            title: 'STATUS (VIDA)',
          },
          {
            custom: ({ beneficiaryProcessed }) => (
              <Grid>
                <Chip
                  rounded
                  size='small'
                  label={beneficiaryProcessed ? 'Processado' : 'Não processado'}
                  color={beneficiaryProcessed ? 'success' : 'info'}
                />
              </Grid>
            ),
            path: 'beneficiaryProcessed',
            title: 'STATUS (BENEFICIÁRIO)',
          },
        ]}
      />
    </WrapperPage >
  )
}

export default Details
