import restfulBase from 'services/negotiations/base'
import { Contract } from 'services/negotiations/endpoints/contracts'
import { BaseEntity } from 'shareds/types/baseEntity.type'

export interface FinancialAdjustment extends BaseEntity {
  companyGroupId: string
  contractId: string
  description: string
  unit: 'percentage' | 'currency'
  date: string
  value: number
  contract?: Contract
}

export interface FinancialAdjustmentFilters extends Partial<FinancialAdjustment> {
  pageSize?: number
  page?: number
}

export const financialAdjustmentInitialState: FinancialAdjustment = {
  companyGroupId: '',
  contractId: '',
  description: '',
  unit: 'currency',
  date: '',
  value: 0,
}

export const financialAdjustmentsService = restfulBase<FinancialAdjustment, FinancialAdjustmentFilters>('financial-adjustments')
export default financialAdjustmentsService
