import { ObjectType } from 'shareds/types'

const status: ObjectType = {
  'not-initialized': 'Não iniciado',
  'in-process': 'Em processamento',
  'process-error': 'Erro de processamento',
  'process-provider-error': 'Erro apontado pela operadora',
  'processed': 'Processado',
  'rejected': 'Rejeitado',
}

export default status
