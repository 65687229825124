import React, { useState, useRef } from 'react'
import { Button } from 'vkit/lib/components'
import { Row } from 'vkit/lib/context'
import {
  FormValidate,
  GridGroup,
  SelectValidate,
  FileUpload,
  BoxInfo,
  ToastStatusGlobal,
} from 'components'
import { downloadFileLink } from 'utils/common'
import { InputClickRefInterface } from 'components/fileUpload/types/fileUpload.types'
import useForm from './hooks/useForm'
import ICONS from 'shareds/constants/icons'

interface DialogFileUploadForm {
  useFormValues: any
  formRef: any
  useIsLoading: boolean
  onSubmit: () => void
  setFileUploadForm: Function
}

const FileUploadForm: React.FC<DialogFileUploadForm> = ({
  formRef,
  onSubmit,
  useFormValues,
  setFileUploadForm,
  useIsLoading,
}) => {
  const [useFileOnChangeIsInvalid, setFileOnChangeIsInvalid] = useState(false)
  const { toast } = ToastStatusGlobal()

  const { useNegotiationOptions } = useForm({
    useFormValues,
    setFileUploadForm,
  })

  const downloadTemplateFile = (): void => {
    const cadastralPositonTemplate = process.env.REACT_APP_API_CADASTRAL_POSITION_TEMPLATE_FILE_URL
    if (cadastralPositonTemplate) {
      downloadFileLink(cadastralPositonTemplate, 'posicao-cadastral-template.csv')
    } else {
      toast('Oops!', 'Falha ao baixar arquivo.', 'error')
    }
  }

  const fileUploadRef = useRef<InputClickRefInterface>(null)

  return (
    <React.Fragment>
      <BoxInfo
        title='Importar base'
        icon={ICONS.UPLOAD}
        header={
          <Button bold invertColor onClick={downloadTemplateFile} label='Baixar arquivo modelo' />
        }
      >
        <FormValidate
          ref={formRef}
          resource={useFormValues}
          fields={{
            negotiationId: ['required'],
            file: ['required'],
          }}
          onChangeForm={setFileUploadForm}
          onError={() => {
            toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning', 5)
          }}
          body={({ onBlur, onChange, useErrors, useValues, updateFieldErrors }) => (
            <GridGroup
              body={[
                {
                  middle: 100,
                  default: 100,
                  component: (
                    <SelectValidate
                      columns={[
                        {
                          title: 'Contrato',
                          path: 'contract.code',
                        },
                        {
                          title: 'Centro de custo',
                          path: 'costCenter.name',
                        },
                        {
                          title: 'Produto',
                          path: 'product.name',
                        },
                      ]}
                      label='Selecione uma negociação'
                      onChange={onChange}
                      onBlur={onBlur}
                      useErrors={useErrors}
                      useValues={useValues}
                      name='negotiationId'
                      autoRequest
                      required
                      data={useNegotiationOptions?.length ? useNegotiationOptions : null}
                      searchable
                    />
                  ),
                },
                {
                  middle: 100,
                  default: 100,
                  component: (
                    <FileUpload
                      ref={fileUploadRef}
                      name='file'
                      textHelper='de posição cadastral <br /> <b> para atualização da base de beneficiários</b>'
                      onChange={(name: string, value: string, isValid: boolean) => {
                        const fieldValue = isValid ? value : ''
                        updateFieldErrors?.(name, fieldValue)
                        if (isValid) {
                          onChange(name, fieldValue)
                          setFileOnChangeIsInvalid(false)
                        } else {
                          setFileOnChangeIsInvalid(true)
                        }
                      }}
                      allowedExtensions={['csv', 'xls', 'xlsx']}
                      formValidatorErrors={useErrors}
                    />
                  ),
                },
              ]}
            />
          )}
        />
        <Row
          style={{ flexDirection: 'row', justifyContent: 'flex-end', padding: '30px 0px 0px 0px' }}
        >
          <Button
            bold
            loading={useIsLoading}
            onClick={() => {
              if (useFileOnChangeIsInvalid) {
                fileUploadRef.current?.onHandleFileSelect()
              } else {
                onSubmit()
              }
            }}
            label={useFileOnChangeIsInvalid ? 'Alterar arquivo' : 'Enviar arquivo de base'}
          />
        </Row>
      </BoxInfo>
    </React.Fragment>
  )
}
export default FileUploadForm
