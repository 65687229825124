import restfulService from 'utils/restful'

type ParamType<T> = Partial<T> & {
  pageSize?: number
  page?: number
}

type ListResponse<T> = {
  currentPage: number
  data: T[]
  totalPages: number
}

export const restfulBase = <T>(
  service: string,
): {
  create: (params: T, url?: string) => Promise<T>
  find: (id: string, params?: ParamType<T>) => Promise<T>
  list: (params: ParamType<T>, url?: string) => Promise<T[]>
  listAll: (params: ParamType<T>, url?: string) => Promise<T[]>
  remove: (id: string, url?: string) => Promise<any>
  update: (id: string, params: Partial<T>, uri?: string) => Promise<any>
  index: (params: ParamType<T>, uri?: string) => Promise<ListResponse<T>>
} => {
  const {
    REACT_APP_API_MOVES_URL = ''
  } = process.env
  
  const api = restfulService({
    service,
    uri: REACT_APP_API_MOVES_URL,
  })
  
  api.apiInstance.interceptors.request.use((config) => ({
    ...config,
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }))
  
  return {
    create: async (params: T, url?: string): Promise<T> => {
      const response = await api.post(params, url)
      return response?.data || response
    },
    
    find: async (id: string, params?: ParamType<T>): Promise<T> => {
      const response = await api.get(params, id)
      return response?.data?.results || response?.data || response
    },
    
    list: async (params: ParamType<T>, url?: string): Promise<T[]> => {
      const response = await api.get(params, url)
      return response?.data?.results || response?.data || response
    },
    
    listAll: async (params: ParamType<T>, uri?: string): Promise<T[]> => {
      const restful = restfulBase<T>(service)
      const { totalPages, data } = await restful.index(params, uri) || {}
      const pages = Array.from({ length: totalPages - 1 }, (_, num) => num + 2)
      
      for (const page of pages) {
        const items = await restful.list({ ...params, page }, uri) || {}
        data.push(...items)
      }
      
      return data
    },
    
    index: async (params: ParamType<T>, uri?: string): Promise<ListResponse<T>> => {
      const response = await api.get(params, uri)
      return Array.isArray(response?.data) ? response : response?.data
    },
    
    remove: async (id: string, url?: string): Promise<any> =>
      api.remove(id, url),
    
    update: async (id: string, params: Partial<T>, uri?: string): Promise<any> =>
      api.update(params, id, uri),
  }
}

export default restfulBase
