import restfulBase from 'services/dataTeam/base'

export type StatusCadastralPosition = {
  totalMessagesWithError: number
  totalMessagesProcessed: number
  totalBeneficiariesInsertedOrUpdatedInDatabase: number
  updatedAt: string
  createdAt: string
  totalBeneficiariesBySov: number
  idUpload: string
  totalPeopleInsertedOrUpdatedInDatabase: number
}

export const initialStateStatusCadastralPosition = {
  totalMessagesWithError: 0,
  totalMessagesProcessed: 0,
  totalBeneficiariesInsertedOrUpdatedInDatabase: 0,
  updatedAt: '',
  createdAt: '',
  totalBeneficiariesBySov: 0,
  idUpload: '',
  totalPeopleInsertedOrUpdatedInDatabase: 0,
}

export const statusCadastralPositionService =
  restfulBase<StatusCadastralPosition>('posicao-cadastral')
