import React from 'react'
import { Button } from 'vkit/lib/components'
import { ChipSubstitle, DataTable, GridGroup, SelectValidate, TextFieldValidate, WrapperPage } from 'components'
import { useHistoryNavigator, useHistoryParams } from 'navigation'
import { MonitoringFile } from 'services/monitoring/endpoints/monitoring/files'
import { monitoringFilesService } from 'services/monitoring'

const List: React.FC = () => {
  const { companyId } = useHistoryParams()
  const navigate = useHistoryNavigator()

  return (
    <WrapperPage>
      <DataTable
        fnRequest={{
          index: (filters) => monitoringFilesService.index({
            sort:'-uploadedAt',
            ...filters,
            companyId,
            type: [
                'bradesco:creation',
                'bradesco:removal',
                'bradesco:update',
                'default:creation',
                'default:removal',
                'default:update',
              ].filter((type) => filters.type ? type.includes(filters.type) : true ),
          })
        }}

        filter={{
          searchField: ({ useValues, onChange }) => (
            <GridGroup
              full
              body={[
                {
                  default: 25,
                  component: (
                    <TextFieldValidate
                      small
                      name='q'
                      iconDir='right'
                      icon='search-outline'
                      label='Buscar'
                      useValues={useValues}
                      onChange={onChange}
                    />
                  )
                },
                {
                  default: 25,
                  component: (
                    <SelectValidate
                      data={[
                        {
                          text: 'Inclusão',
                          value: 'creation',
                        },
                        {
                          text: 'Exclusão',
                          value: 'removal',
                        },
                        {
                          text: 'Alteração',
                          value: 'update',
                        },
                      ]}
                      name='type'
                      onChange={onChange}
                      label='Tipo'
                      small
                      useValues={useValues}
                    />
                  )
                },
                {
                  default: 25,
                  component: (
                    <TextFieldValidate
                      small
                      name='createdAtMin'
                      label='Data'
                      useValues={useValues}
                      onChange={onChange}
                      type='date'
                    />
                  )
                },
              ]}
            />
          )
        }}

        header={{
          right: (
            <Button
              onClick={() => navigate.push('moves/actions/upload')}
              label='Enviar arquivo'
              color='default'
              icon='cloud-upload-outline'
            />
          )
        }}

        columns={[
          {
            title: 'NOME DO ARQUIVO',
            path: 'name',
            sortable: true,
          },
          {
            path: 'user.name',
            title: 'RESPONSÁVEL',
          },
          {
            title: 'TIPO',
            custom: ({ type }: MonitoringFile): string => {
              if (type?.includes('creation')) {
                return 'Inclusão'
              }
              if (type?.includes('removal')) {
                return 'Exclusão'
              }
              return 'Alteração'
            }
          },
          {
            title: 'OPERADORA',
            custom: ({ type }: MonitoringFile) => type?.includes('bradesco')
              ? 'Bradesco'
              : 'Outro'
          },
          {
            title: 'STATUS',
            custom: ({ status }: MonitoringFile) => (
              <ChipSubstitle
                term={status}
                substitles={{
                  'not-initialized': {
                    text: 'Não iniciado',
                    color: 'light',
                  },
                  'in-process': {
                    text: 'Em processamento',
                    color: 'light',
                  },
                  'process-error': {
                    text: 'Erro de processamento',
                    color: 'light',
                  },
                  'process-provider-error': {
                    text: 'Erro apontado pela operadora',
                    color: 'light',
                  },
                  'processed': {
                    text: 'Processado',
                    color: 'light',
                  },
                  'rejected': {
                    text: 'Rejeitado',
                    color: 'light',
                  }
                }}
              />
            )
          },
          {
            title: 'CRIADO EM',
            path: 'uploadedAt',
            type: 'dateTime',
            sortable: true,
          }
        ]}

        actionsByLine={({ id: moveId, link }: MonitoringFile) => ([
          {
            label: 'Ver detalhes',
            onClick: () => {
              navigate.push(`moves/${moveId}`)
            },
            icon: 'eye-outline',
          },
          {
            label: 'Baixar planilha',
            onClick: () => {
              window.open(link)
            },
            icon: 'download-outline',
          },
        ])}
      />
    </WrapperPage>
  )
}

export default List
