import restfulBase from 'services/negotiations/base'

const apiService = restfulBase('providers')

export type Provider = {
  id: string;
  name: string;
  cnpj: string | number;
  created_at: string;
  updated_at: string | undefined | null;
  deleted_at: string | undefined | null;
  deletion_reason: string | undefined | null;
}

export default apiService
