import { useHistoryParams } from 'navigation'
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import {
  MonitoringRow,
  MonitoringRowFilters,
  monitoringRowsService,
} from 'services/monitoring/endpoints/monitoring/rows'
import { removeInvalids } from 'utils'
import {
  MonitoringFile,
  monitoringFilesInitialState,
  monitoringFilesService, MOVES_UPDATE_STATUS,
} from 'services/monitoring/endpoints/monitoring/files'

export interface IUseDetails {
  loadRows: (filters: MonitoringRowFilters) => Promise<void>
  useMove: MonitoringFile
  useRows: MonitoringRow[] | null
  useTotalPages: number
  dataListRef: MutableRefObject<HTMLDivElement | null>
  updateStatusMove: (status: MOVES_UPDATE_STATUS, reasonReject: string) => void
}

const useDetails = (): IUseDetails => {
  const { moveId } = useHistoryParams()
  const [useMove, setMove] = useState<MonitoringFile>(monitoringFilesInitialState)
  const [useRows, setRows] = useState<MonitoringRow[] | null>([])
  const [useTotalPages, setTotalPages] = useState(1)
  const dataListRef = useRef<HTMLDivElement>(null)

  const loadMove = useCallback(async (): Promise<void> => {
    if (!moveId) {
      return
    }

    const { data } = await monitoringFilesService.findOne(moveId)

    setMove(data || {})
  }, [moveId])

  const loadRows = useCallback(async (updateFilters = {}): Promise<void> => {
    let rows
    setRows([])

    try {
      const filters = removeInvalids(updateFilters)
      const { data, totalPages } = await monitoringRowsService.index({
        ...filters,
        fileId: moveId,
        sort:'line',
      })

      rows = data?.length ? data : null
      setTotalPages(totalPages)
    } catch {
      rows = null
    } finally {
      setRows(rows || null)
      const scrollbar = document.querySelector('[class*="page_pages"] [class*="scrollbar_content"]')
      if (scrollbar && dataListRef.current) {
        const clientDataTable = dataListRef.current.getBoundingClientRect()
        const clientScroolbarChildren = scrollbar?.children?.[0].getBoundingClientRect()
        const scrollTop =
          Math.abs(clientScroolbarChildren?.top) - Math.abs(clientDataTable.top) - 30

        if (clientScroolbarChildren?.top && clientDataTable.top < 0) {
          scrollbar.scrollTop = scrollTop
        }
      }
    }
  }, [moveId])

  const updateStatusMove = (status: MOVES_UPDATE_STATUS, rejectionReason = ''): void => {
    setMove({
      ...useMove,
      status,
      rejectionReason,
    })
  }

  useEffect(() => {
    (async () => loadMove())()
  }, [loadMove])

  return {
    loadRows,
    useMove,
    useRows,
    useTotalPages,
    dataListRef,
    updateStatusMove,
  }
}

export default useDetails
