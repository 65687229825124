import React from 'react'
import { WrapperPage } from 'components'

import UploadProvider from './context/UploadContext'

const Upload: React.FC = () => {
  return (
    <WrapperPage>
      <div style={{ position: 'relative' }}>
        <UploadProvider />
      </div>
    </WrapperPage>
  )
}

export default Upload
