import React, { ReactNode } from 'react'

import style from './style/dropzone.module.scss'

import useDropzone from './hooks/useDropzone'

type DropZoneType = {
  onDrop: Function
  onDragChange: Function
  pulseWhileDragging?: boolean
  children: ReactNode
}

const DropZone: React.FC<DropZoneType> = ({
  onDrop,
  onDragChange,
  pulseWhileDragging = true,
  children
}) => {
  const {
    onDragEnterDropzone,
    onDragLeaveDropzone,
    onFileDrop,
    useIsDragging
  } = useDropzone(onDrop, onDragChange)

  return (
    <div
      className={`${style.dropzone} ${useIsDragging && pulseWhileDragging ? style.pulse : ''}`}
      onDrop={onFileDrop}
      onDragEnter={onDragEnterDropzone}
      onDragLeave={onDragLeaveDropzone}
    >
      <div className={`${style.children} ${!useIsDragging ? style.pointerEvent : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default DropZone
