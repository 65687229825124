import { ObjectType } from 'shareds/types'

const removeInvalids = (filters: ObjectType): ObjectType =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    if (!value) {
      return acc
    }
    
    return {
      ...acc,
      [key]: value
    }
  }, {})

export default removeInvalids
