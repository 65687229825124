import restfulBase from 'services/negotiations/base'
import { ObjectType } from 'shareds/types'
import { BaseEntity } from 'shareds/types/baseEntity.type'

export type Negotiation = BaseEntity & {
  brokerId: string
  company?: ObjectType
  companyId: string
  contractId: string
  costCenter?: ObjectType
  costCenterId: string
  costCenters?: ObjectType[]
  createdAt?: string
  deletedAt?: string
  deletionReason?: string
  id?: string
  price?: number
  product?: ObjectType
  productId: string
  updatedAt?: string
}

export type NegotiationFilter = {
  'costCenter.companyId': string
  'product.benefitId': string
  brokerId: string
  contractId: string
  costCenterId: string
  id: string
  productId: string
}

export const initialStateNegotiation = {
  brokerId: '',
  company: '',
  company_id: '',
  contract_id: '',
  costCenter: '',
  costCenterId: '',
  costCenters: '',
  createdAt: '',
  deletedAt: '',
  deletionReason: '',
  id: '',
  price: '',
  product: '',
  productId: '',
  updatedAt: '',
}

export const negotiationMessageErrors: ObjectType = {
  'Internal server error': 'Erro ao criar negociação.',
  'CostCenter does not exist': 'Centro de custo não encontrado.',
  'This cost center does not belong to your company': 'Este centro de custo não pertence à sua empresa.',
  'Company not found': 'Empresa não encontrada.',
  'Negotiation not found': 'Negociação não encontrada.',
  'Product not found for id .*': 'Produto não encontrado.',
  'Contract not found for id .*': 'Contrato não encontrado.',
  'CostCenter not found for id .*': 'Centro de custo não encontrado.',
}

export const negotiationsService = restfulBase('negotiations')

export default negotiationsService
