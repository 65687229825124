import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useHistoryNavigator } from 'navigation'

const PublicRoute: React.FC<any> = ({ component: Component, restricted, ...rest }) => {
  const navigator = useHistoryNavigator()

  useEffect(() => {
    const resetValues = (): void => {
      navigator.replace({ state: undefined })
    }

    window.addEventListener('beforeunload', resetValues)
    return () => window.removeEventListener('beforeunload', resetValues)
  }, [navigator])

  return <Route {...rest} render={(props) => <Component {...props} />} />
}

export default PublicRoute
