import { Company } from 'services/negotiations/endpoints/companies';

export enum USER_TYPE {
  BROKER = 'broker',
  COMPANY = 'company'
}

export interface User {
  name: string
  type: USER_TYPE
  scopeId: string
  scopeIds?: string[]
  permissions: string[]
  scopes?: Company[]
}
