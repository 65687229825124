import React, { useCallback } from 'react'

import { DialogConfirm } from 'components'
import { Button } from 'vkit/lib/components'
import useDialogConfirm from 'components/dialogConfirm/hooks/useDialogConfirm'

import { Colors, Size } from 'shareds/types'

interface ButtonDialogProps {
  color?: Colors
  disabled?: boolean
  label?: string
  onClick?: (...item: any) => void
  outlined?: boolean
  size?: Size
  solo?: boolean
  text: string
  invertColor?: boolean
  loading?: boolean
}

const ButtonDialog: React.FC<ButtonDialogProps> = (
  {
    color,
    disabled,
    label,
    onClick,
    outlined,
    size,
    solo,
    text,
    invertColor,
    loading,
  }
) => {
  const {
    useTitle: useDialogConfirmTitle,
    useText: useDialogConfirmText,
    useOpen: useDialogConfirmOpen,
    useFnCallback,
    useConfirm: confirm,
  } = useDialogConfirm()

  const onConfirmAction = useCallback(
    async (...args): Promise<void> => {
      const canDelete = await confirm('Atenção', text)

      if (canDelete) {
        onClick?.(...args)
      }
    },
    [onClick, confirm, text],
  )

  return (
    <>
      <DialogConfirm
        fnCallback={useFnCallback}
        open={useDialogConfirmOpen}
        text={useDialogConfirmText}
        title={useDialogConfirmTitle}
      />

      <Button
        color={color}
        disabled={disabled}
        invertColor={invertColor}
        label={label}
        loading={loading}
        onClick={onConfirmAction}
        outlined={outlined}
        size={size}
        solo={solo}
      />
    </>
  )
}

export default ButtonDialog
