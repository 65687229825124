import restfulBase from 'services/negotiations/base'
import { BaseEntity } from 'shareds/types/baseEntity.type'

export interface CompanyGroup extends BaseEntity {
  name: string
  supportEmail: string
}

export const companyGrouptInitialState = {
  name: '',
  supportEmail: '',
}

export const companyGroupsService = restfulBase<CompanyGroup>('company-groups')

export default companyGroupsService
