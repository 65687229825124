import React, { useCallback, useEffect, useState } from 'react'
import { BoxInfo, DataTable, ShowHide, TextData, WrapperPage } from 'components'
import { useHistoryNavigator, useHistoryParams } from 'navigation'
import { ObjectType } from 'shareds/types'
import { companiesService, contactsService, addressesService } from 'services/negotiations'
import ICONS from 'shareds/constants/icons'
import { Company } from 'services/negotiations/endpoints/companies'
import { PERMISSIONS } from 'utils/checkPermission'
import { isEmpty } from 'lodash'

const Index: React.FC = () => {
  const { companyId } = useHistoryParams()
  const [company, setCompany] = useState<ObjectType>({})
  const navigate = useHistoryNavigator()

  const companyDTO = (foundCompany: ObjectType): ObjectType => ({
    cnpj: foundCompany.cnpj,
    name: foundCompany.name,
    group: foundCompany.companyGroup.name,
    groupId: foundCompany.companyGroup.id,
    nameResponsible: foundCompany.contact?.name,
    email: foundCompany.contact?.email,
    phone: foundCompany.contact?.phone,
  })

  const loadCompany = useCallback(async (): Promise<void> => {
    if (!companyId) {
      return
    }

    setCompany({})
    const company = (await companiesService.find(companyId)) || {}
    const contact = (await contactsService.list({ companyId }) || [{}]).find(
      contact => contact.isMainContact
    )

    setCompany(companyDTO({
      ...company,
      contact
    }))
  }, [companyId])

  useEffect(() => {
    (async () => loadCompany())()
  }, [loadCompany])

  return (
    <WrapperPage>
      <BoxInfo
        title='Dados da empresa'
        icon={ICONS.COMPANY}
      >
        <TextData
          loading={isEmpty(company)}
          data={[
            {
              label: 'CNPJ',
              text: company?.cnpj,
              sizeDefault: 30,
              sizeMedium: 100,
              type: 'cnpj'
            },
            {
              label: 'Nome da empresa',
              text: company?.name,
              sizeDefault: 70,
              sizeMedium: 100,
            },
            {
              label: 'Grupo',
              text: company?.group,
              sizeDefault: 100,
              sizeMedium: 100,
            },
            {
              label: 'Nome da(o) responsável',
              text: company?.nameResponsible,
              type: 'date',
              sizeDefault: 33.33,
              sizeMedium: 100,
            },
            {
              label: 'Email da(o) responsável empresa',
              text: company?.email,
              sizeDefault: 33.33,
              sizeMedium: 100,
            },
            {
              label: 'Telefone',
              text: company?.phone,
              type: (company?.phone ?? '').replace(/\D/g, '') ? 'mobileNumber' : 'text',
              sizeDefault: 33.33,
              sizeMedium: 100,
            },
          ]}
        />
      </BoxInfo>

      <BoxInfo
        title='Endereço'
        icon={ICONS.ADDRESS}
      >
        <TextData
          loading={isEmpty(company)}

          requestFn={async () => {
            const [address] = await addressesService.list({ companyId }) || [{}]
            return address
          }}

          data={[
            {
              label: 'CEP',
              path: 'zipCode',
              type: 'cep'
            },
            {
              label: 'Logradouro',
              path: 'location',
              sizeDefault: 50,
            },
            {
              label: 'Número',
              path: 'number',
              sizeDefault: 16.66,
            },
            {
              label: 'Bairro',
              path: 'neighborhood',
              sizeDefault: 50,
            },
            {
              label: 'Cidade',
              path: 'city',
              sizeDefault: 40,
            },
            {
              label: 'UF',
              path: 'state',
              sizeDefault: 10,
            },
            {
              label: 'Complemento',
              path: 'complement',
              sizeDefault: 100,
            }
          ]}
        />
      </BoxInfo>

      <ShowHide transition='slideToDown' visible={company?.groupId}>
        <BoxInfo
          title='Empresas do grupo'
          icon={ICONS.COMPANY}
        >
          <DataTable
            requestResponseRootPath='results'
            elevation={1}

            fnRequest={{
              index: (filters) =>
                companiesService.index({ ...filters, companyGroupId: company?.groupId }),
            }}

            columns={[
              {
                path: 'name',
                title: 'NOME',
              },
              {
                path: 'companyGroup.name',
                title: 'GRUPO',
              },
              {
                path: 'cnpj',
                title: 'CNPJ',
                type: 'cnpj',
                width: '20%',
              },
              {
                path: 'createdAt',
                title: 'CRIADA EM',
                type: 'dateTime',
              },
            ]}

            actionsByLine={({ id: companyId }: Company) => ([
              {
                label: 'Ver detalhes',
                onClick: () => {
                  navigate.push(`/companies/${companyId}`)
                  const scrollbar = document.querySelector('[class*="page_pages"] [class*="scrollbar_content"]')
                  if (scrollbar) {
                    scrollbar.scrollTop = 0
                  }
                },
                icon: 'eye-outline',
              },
              {
                label: 'Importar Base',
                onClick: () =>
                  navigate.push(`/companies/${companyId}/cadastral-position/actions/create`),
                icon: ICONS.UPLOAD,
              },
              {
                label: 'Ver beneficiários',
                onClick: () => navigate.push(`/companies/${companyId}/beneficiaries`),
                icon: ICONS.BENEFICIARIES,
              },
              {
                label: 'Posições Cadastrais',
                onClick: () => navigate.push(`/companies/${companyId}/cadastral-position`),
                icon: ICONS.CADASTRAL_POSITION
              },
              {
                label: 'Ver contratos',
                onClick: () => navigate.push(`/companies/${companyId}/contracts`),
                icon: ICONS.CONTRACT
              },
              {
                label: 'Ver movimentações',
                onClick: () => navigate.push(`/companies/${companyId}/moves`),
                icon: ICONS.MOVE,
                permissions: [
                  PERMISSIONS.MOVE_CREATE
                ],
              },
            ])}
            iconCard='briefcase-outline'
          />
        </BoxInfo>
      </ShowHide>
    </WrapperPage>
  )
}

export default Index
