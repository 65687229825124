import React from 'react'
import { Grid, Row } from 'vkit/lib/context'
import { Chip, Text } from 'vkit/lib/components'
import { Column } from 'components/dataList/hooks/useContentList'
import { StatusFamilyGroupMember } from 'pages/familyGroup/list/hooks/useList'

const useColumns = (): Column[] => ([
  {
    title: 'NOME',
    width: '15%',
    showOnMobile: true,
    value: function renderColumn({ name }: StatusFamilyGroupMember) {
      return (
        <Row style={{ padding: 0 }}>
          <Text value={name || 'Nome não informado'} title size='small' />
        </Row>
      )
    },
  },
  {
    title: 'STATUS',
    width: '15%',
    value: function renderColumn({ status }: StatusFamilyGroupMember) {
      return (
        <Grid>
          <Chip
            color={ status === 'Válido' ? 'success' : 'warning' }
            label={status}
            size='small'
            rounded
          />
        </Grid>
      )
    },
  },
  {
    title: 'DETALHES',
    value: function renderColumn({ details }: StatusFamilyGroupMember) {
      return (
        <>
          {
            details.map((detail, index) => (
              <Row key={`${detail} | ${index}`} style={{ padding: 0 }}>
                <Text padding='0 0 4px' value={detail} size='small' />
              </Row>
            ))
          }
        </>
      )
    },
  },
])

export default useColumns
