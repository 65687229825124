import { CustomList } from 'components'
import React from 'react'
import useContentList from '../../hooks/useContentList'
import { FinancialAdjustment } from 'services/negotiations/endpoints/financialAdjustments'

const innerHeight = window.innerWidth > 768 ? 60 : 80

type ContentListType = {
  data: FinancialAdjustment[] | null
  onDelete: (financialAdjustmentId: string) => void
  linesLoader?: string | number
}

const ContentList: React.FC<ContentListType> = ({
  data ,
  onDelete,
  linesLoader
}) => {
  const { useDataList, useListRef } = useContentList({
    onDelete,
  })

  return (
    <CustomList
      listRef={useListRef}
      linesLoader={linesLoader}
      data={data}
      innerHeight={innerHeight}
      dataList={useDataList}
    />
  )
}

export default ContentList
