import { useCallback, useEffect, useState } from 'react'
import {
  StatusFamilyGroup,
  statusFamilyGroupService,
} from 'services/dataTeam/endpoints/statusFamilyGroup'
import {
  statusCadastralPositionService,
} from 'services/dataTeam/endpoints/statusCadastralPosition'
import { removeInvalids } from 'utils'
import { ObjectType } from 'shareds/types'
import { useHistoryParams } from 'navigation'
import { CardNumberProps } from 'components/cardNumber'

export type StatusFamilyGroupMember = {
  name: string
  status: 'Válido' | 'Inválido'
  details: string[]
}

export type CollectionStatusFamilyGroup = {
  title: string
  familyGroup: StatusFamilyGroupMember[]
}

const useList = (): {
  changePage: (pageNumber: number) => void
  useCardNumbers: CardNumberProps[]
  useData: CollectionStatusFamilyGroup[] | null
  usePageExists: {
    next: boolean
    prev: boolean
  }
} => {
  const [useCurrentPage, setCurrentPage] = useState(0)
  const [useData, setData] = useState<CollectionStatusFamilyGroup[] | null>([])
  const [useCardNumbers, setCardNumbers] = useState<CardNumberProps[]>([])
  const [usePageExists, setPageExists] = useState({
    next: false,
    prev: false,
  })
  const { cadastralPositionId } = useHistoryParams()

  const translateErrorKey = (errorKey: string): string | undefined => {
    return {
      cnpjEmpresa: 'cnpjEmpresa ',
      cnpjOperadora: 'cnpjOperadora',
      codigoAns: 'pruductAnsCode',
      baseId: 'productBaseId',
      planoTipoIdIntegracao: 'planoTipoIdIntegracao',
      costCenter: 'costCenter',
      arquivoProcessado: 'fileName',
      arquivoLinhas: 'fileRows',
      arquivoDownload: 'fileDownload',
      arquivoExtensao: 'fileExtension',
      arquivoUploadAt: 'uploadAt',
      empresaIdSovereign: 'companyId',
      usuarioIdSovereign: 'userId',
      codigoPlano: 'productName',
      cpf: 'cpf',
      nome: 'nome',
      nomeMae: 'nome_mae',
      sexo: 'sexo',
      dtNascimento: 'dt_nascimento',
      estadoCivil: 'estado_civil',
      rg: 'rg',
      cartaoSus: 'cartao_sus',
      vinculo: 'vinculo',
      matricula: 'matricula',
      dtAdmissao: 'dtAdmissao',
      cargo: 'cargo',
      departamento: 'departamento',
      pisNis: 'pis_nis',
      status: 'status',
      email: 'email',
      dtInicioVigencia: 'dt_inicio_vigencia',
      dtFimVigencia: 'dt_fim_vigencia',
      certificado: 'certificado',
      contrato: 'contrato',
      linhaArquivo: 'linha_arquivo',
      logradouro: 'logradouro',
      uf: 'uf',
      cidade: 'cidade',
      cep: 'cep',
      complemento: 'complemento',
      bairro: 'bairro',
      numeroCartao: 'codigo_beneficiario',
      banco: 'banco',
      agencia: 'agencia',
      agenciaDigito: 'agencia_digito',
      conta: 'conta',
      contaDigito: 'conta_digito',
      tipo: 'tipo_tel',
      ddd: 'ddd',
    }[errorKey]
  }

  const translateErrorValue = (errorValue: string): string | undefined => {
    return {
      'required field': 'Campo obrigatório',
      'unknown field': 'Campo desconhecido',
    }[errorValue]
  }

  const translateErrors = useCallback((familyGroupErrors: ObjectType[]): string[] => {
    const extractedErrors: string[] = []

    for (const familyGroupError of familyGroupErrors) {
      for (const [key, value] of Object.entries(familyGroupError)) {
        const errorKey = translateErrorKey(key) || key
        const errorValue = translateErrorValue(value as string) || value

        extractedErrors.push(`${errorKey}: ${errorValue}`)
      }
    }

    return extractedErrors
  }, [])

  const extractStatusFamilyGroupLines = useCallback(
    (statusFamilyGroups: StatusFamilyGroup[]): CollectionStatusFamilyGroup[] => {
      const boxStatusFamilyGroup: CollectionStatusFamilyGroup[] = []

      for (const statusFamilyGroup of statusFamilyGroups) {
        const {
          mensagem = '{}',
          erro = '{}',
        } = statusFamilyGroup

        const {
          titular: holderInfo,
          dependentes: dependentInfos,
        } = JSON.parse(mensagem)

        const {
          titular: holderErrors = [],
          dependentes: dependentErrors = [],
        } = JSON.parse(erro)

        const lineStatusFamilyGroup: StatusFamilyGroupMember[] = []

        if (holderInfo) {
          lineStatusFamilyGroup.push({
            name: holderInfo.nome || 'Titular',
            status: holderErrors?.length ? 'Inválido' : 'Válido',
            details: translateErrors(holderErrors)
          })
        }

        if (dependentInfos) {
          for (const index in dependentInfos) {
            const amountErrors: ObjectType[] = []

            for (const dependentError of dependentErrors) {
              if (dependentError[index]) {
                amountErrors.push(...dependentError[index])
              }
            }

            lineStatusFamilyGroup.push({
              name: dependentInfos[index].nome || 'Dependente',
              status: amountErrors.length ? 'Inválido' : 'Válido',
              details: translateErrors(amountErrors)
            })
          }
        }

        boxStatusFamilyGroup.push({
          title: holderInfo?.nome || 'Titular',
          familyGroup: lineStatusFamilyGroup
        })
      }

      return boxStatusFamilyGroup
    },
    [translateErrors]
  )

  const getList = useCallback(async (updateFilters = {}): Promise<StatusFamilyGroup[]> => {
    const filters = removeInvalids({
      ...updateFilters,
      pageSize: 10,
    })

    const params = {
      cadastralPositionId
    }

    const { data } = (await statusFamilyGroupService.index(params, filters)) || {}

    return data
  }, [cadastralPositionId])

  const loadList = useCallback(async (updateFilters = {}): Promise<void> => {
    let statusFamilyGroups: CollectionStatusFamilyGroup[] | null = []

    setData([])

    try {
      const data = await getList(updateFilters)
      statusFamilyGroups = data?.length ? extractStatusFamilyGroupLines(data) : null
    } catch(error) {
      console.error(error)
      statusFamilyGroups = null
    } finally {
      setData(statusFamilyGroups)
    }
  }, [getList, extractStatusFamilyGroupLines])

  const loadCardNumbers = useCallback(async (): Promise<void> => {
    let cardNumbers: CardNumberProps[] = []
    setCardNumbers([])

    try {
      const processCadastralPosition =
        (await statusCadastralPositionService.find(cadastralPositionId))

      if (!Object.keys(processCadastralPosition).length) {
        return
      }

      const {
        totalMessagesWithError = 0,
        totalMessagesProcessed = 0,
        totalBeneficiariesBySov = 0,
        totalBeneficiariesInsertedOrUpdatedInDatabase = 0,
      } = processCadastralPosition

      cardNumbers = [
        {
          color: 'warning',
          number: totalMessagesWithError,
          text: 'Famílias com erro',
        },
        {
          color: 'default',
          number: totalMessagesProcessed,
          text: 'Famílias<br />processadas',
        },
        {
          color: 'default',
          number: totalBeneficiariesBySov,
          text: 'Beneficiários previstos para alteração ou criação',
        },
        {
          color: 'success',
          number: totalBeneficiariesInsertedOrUpdatedInDatabase,
          text: 'Beneficiários alterados<br />ou criados',
        },
      ]
    } catch(error) {
      console.error(error)
      cardNumbers = []
    } finally {
      setCardNumbers(cardNumbers)
    }
  }, [cadastralPositionId])

  const changePage = (pageNumber: number): void => {
    setCurrentPage((currentPage: number) => currentPage + pageNumber)
  }

  const checkOtherPageExists = useCallback(async (pageNumber: number): Promise<void> => {
    const data = await getList({ page: pageNumber + 1 })

    setPageExists({
      next: !!data?.length,
      prev: pageNumber > 0
    })
  }, [getList])

  useEffect(() => {
    (async () => {
      await loadList({ page: useCurrentPage })
      await checkOtherPageExists(useCurrentPage)
    })()
  }, [loadList, useCurrentPage, checkOtherPageExists])

  useEffect(() => {
    (async () => loadCardNumbers())()
  }, [loadCardNumbers])

  return {
    changePage,
    useCardNumbers,
    useData,
    usePageExists,
  }
}

export default useList
