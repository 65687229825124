import restfulService from 'utils/restful'
import { ObjectType } from 'shareds/types'

type Params = {
  cadastralPositionId: string,
}

type Filter<T = any> = Partial<T> & {
  pageSize?: number
  page?: number
}

type APIResponse<T> = {
  data:T[]
  details: ObjectType
  message: string
  status: number
}

export const restfulBase = <T = any, F = any>(
  service: string,
): {
  index: (
    params: Params,
    filter?: Filter<F>,
    uri?: string
  ) => Promise<APIResponse<T>>
  find: (
    id: string,
    uri?: string
  ) => Promise<T>
} => {
  const {
    REACT_APP_API_DATA_TEAM_URL = ''
  } = process.env
  
  const options = {
    uri: REACT_APP_API_DATA_TEAM_URL,
    headers: {
      authorization: `${localStorage.getItem('token')}`,
    },
  }
  
  return {
    index: async (
      params: Params,
      filter?: Filter<F>,
      uri?: string
    ): Promise<APIResponse<T>> => {
      const api = restfulService({
        ...options,
        service: service.replace(':cadastralPositionId', params.cadastralPositionId)
      })
      
      return api.get(filter, uri) as Promise<APIResponse<T>>
    },
    
    find: async (
      id:string,
      uri?: string
    ): Promise<T> => {
      const api = restfulService({
        ...options,
        service
      })
  
      const { data } = (await api.get({}, `${id}${uri || ''}`)) || {}
      
      return data
    }
  }
}

export default restfulBase
