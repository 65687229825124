import React from 'react';

import { ActionsLayer } from 'vkit/lib/components'
import { Grid } from 'vkit/lib/context'
import { ToastStatus, DialogConfirm } from 'components'

import DialogManageMarkups from 'modules/markups/views/DialogManageMarkups/DialogManageMarkups';
import { MarkupHeaderInfo } from 'modules/markups/components';

export interface ListActionsProps {
  onDelete?: (id: string) => void
  loading?: boolean,
  contractCode?: string,
  productName?: string,
  costCenterName?: string,
  idNegotiation: string
}

const ListActions: React.FC<ListActionsProps> = ({
  idNegotiation,
  contractCode,
  productName,
  costCenterName,
  onDelete,
}) => {
  const { toast, useToast } = ToastStatus();
  const [showDialogMarkups, setShowDialogMarkups] = React.useState(false);
  const [deleteNegotiation, setDeleteNegotiation] = React.useState(false);
  const [dialogMarkupsTitle, setDialogMarkupsTitle] = React.useState<MarkupHeaderInfo[]>([]);

  const useDeleteNegotiationCallBack = React.useCallback((isConfirmed: boolean): void => {
    if (isConfirmed) {
      onDelete?.(idNegotiation);
    }
    setDeleteNegotiation(false);
  }, [onDelete, idNegotiation])

  React.useEffect(() => {
    if (contractCode && productName && costCenterName) {
      setDialogMarkupsTitle(() => ([
        {
          title: 'Negociação:',
          value: contractCode
        },
        {
          title: 'Produto:',
          value: productName
        },
        {
          title: 'Centro de custo:',
          value: costCenterName
        }
      ]));
    }
  }, [contractCode, productName, costCenterName]);

  return (
    <>
      <Grid alignContent='right'>
        <ActionsLayer
          actions={[
            {
              label: 'Ver markups',
              onClick: () => { setShowDialogMarkups(true) },
              icon: 'eye-outline',
            },
          ]}
          vertical
        />
      </Grid>
      <DialogManageMarkups
        open={showDialogMarkups}
        idNegotiation={ idNegotiation }
        headerInfos={ dialogMarkupsTitle }
        onCallToast={ useToast }
        onClose={() => setShowDialogMarkups(false)}
      />
      <DialogConfirm
        open={ deleteNegotiation }
        fnCallback={ useDeleteNegotiationCallBack }
        text="Quer mesmo excluir essa negociação?"
        title="Atenção"
      />
      { toast }
    </>
  );
};

export default ListActions;
