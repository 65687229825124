import React, { useState } from 'react'
import {
  ButtonDialog,
  ChipSubstitle,
  DataTable, GridGroup,
  SelectValidate,
  ToastStatusGlobal,
  WrapperPage,
} from 'components'
import { FinancialAdjustment, financialAdjustmentsService } from 'services/negotiations/endpoints/financialAdjustments'
import { getCurrencyFormat } from 'utils/format'
import { AxiosError } from 'axios'
import { useHistoryNavigator, useHistoryParams } from 'navigation'
import { Button } from 'vkit/lib/components'
import { getToken } from 'utils/authentication'
import ICONS from 'shareds/constants/icons'

const List: React.FC = () => {
  const { toast } = ToastStatusGlobal()
  const [loading, setLoading] = useState(false)
  const { companyGroupId } = useHistoryParams()
  const navigate = useHistoryNavigator()

  const deleteFinancialAdjustment = async (
    financialAdjustmentId: string,
    refreshData: Function
  ): Promise<void> => {
    if (!companyGroupId) {
      return
    }

    try {
      setLoading(true)
      await financialAdjustmentsService.remove(financialAdjustmentId)
      await refreshData()
      toast('Pronto', 'O ajuste financeiro foi excluído.', 'success')
    } catch (error) {
      const {
        message
      } = (error as AxiosError).response?.data
      const messageError = message || 'Falha ao excluir o ajuste financeiro.'
      toast('Oops!', messageError, 'error')
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <WrapperPage>
      <DataTable
        loading={loading}

        fnRequest={{
          index: (filters: any) => financialAdjustmentsService.index({
            sort: '-date',
            companyGroupId,
            ...filters,
          })
        }}

        header={{
          right: (
            <Button
              onClick={() => navigate.push('financial-adjustments/actions/create')}
              label='Novo ajuste financeiro'
              color='default'
              icon={ICONS.FINANCIAL_ADJUSTMENTS}
            />
          )
        }}

        filter={{
          searchField: ({ useValues, onChange }) => (
            <GridGroup
              body={[
                {
                  default: 50,
                  component: (
                    <SelectValidate
                      small
                      name='contractId'
                      searchable
                      placeholder='Contrato'
                      useValues={useValues}
                      requestPageParam='page'
                      requestResponseRootPath='data.results'
                      requestResponseText='code'
                      requestResponseValue='id'
                      requestRouter='/contracts'
                      requestSearchParam='q'
                      autoRequest
                      requestUri={ process.env.REACT_APP_API_NEGOTIATIONS_URL }
                      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
                      onChange={onChange}
                    />
                  )
                },
                {
                  default: 50,
                  component: (
                    <SelectValidate
                      small
                      name='unit'
                      placeholder='Tipo de valor'
                      useValues={useValues}
                      onChange={onChange}
                      data={[
                        {
                          text: 'Porcentagem',
                          value: 'percentage',
                        },
                        {
                          text: 'Moeda',
                          value: 'currency',
                        },
                      ]}
                    />
                  )
                }
              ]}
            />
          )
        }}

        columns={[
          {
            title: 'DATA',
            path: 'date',
            type: 'date',
            sortable: true,
          },
          {
            custom: ({ value, unit }: FinancialAdjustment) => (
              <ChipSubstitle
                term={unit}
                substitles={{
                  percentage: {
                    text: `${value}%`,
                    color: 'light',
                  },
                  currency: {
                    text: getCurrencyFormat(value, 'BRL'),
                    color: 'light',
                  }
                }}
              />
            ),
            title: 'VALOR',
          },
          {
            title: 'CONTRATO',
            path: 'contract.code',
          },
          {
            title: 'DESCRIÇÃO',
            path: 'description',
          },
        ]}

        actionsByLine={({ id }: FinancialAdjustment, refresData) => (
          <ButtonDialog
            color='danger'
            label='Excluir'
            onClick={() => deleteFinancialAdjustment(id!, refresData)}
            outlined
            solo
            text='Quer mesmo excluir este ajuste financeiro?'
          />
        )}
      />
    </WrapperPage>
  )
}

export default List
