import companiesRoute from './companies'
import companyGroupsRoute from './companyGroups'
import usersRoute from './users'
import contractsRoute from './contracts'
import logoutRoute from './logout'

const routesGroups = [
  {
    title: '',
    routes: [
      companiesRoute,
      companyGroupsRoute,
      usersRoute,
      contractsRoute,
      logoutRoute,
    ],
  },
]

export default routesGroups
