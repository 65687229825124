import restfulBase from 'services/accessControl/base'

export interface AuthTOTPCredentials {
  email: string
  password: string
}

export interface AuthTOTPCredentialsResponse {
  seed: string
  totpUri: string
}

export const authTOTPCredentialsInitState: AuthTOTPCredentials = {
  email: '',
  password: '',
}

export const authTOTPCredentialsResponseInitState = {
  seed: '',
  totpUri: '',
}

export const authTOTPCredentialErrors = {
  Unauthorized: 'Usuário ou senha inválidos',
  'User already has MFA active': 'Usuário já possui uma conta válida.',
}

export const authTOTPCredentialsService = restfulBase<AuthTOTPCredentials, AuthTOTPCredentialsResponse>('auth/mfa/totp')
