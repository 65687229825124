import React, { useCallback } from 'react'
import { CardLastAccessList, DataTable, WrapperPage } from 'components'
import companyGroupService, { CompanyGroup } from 'services/negotiations/endpoints/companyGroups'
import { useHistoryNavigator } from 'navigation'
import ICONS from 'shareds/constants/icons'

const List: React.FC = () => {
  const navigate = useHistoryNavigator()

  const generateActions = useCallback(({ id: companyGroupId }: CompanyGroup) => ([
    {
      label: 'Ajustes financeiros',
      onClick: () => navigate
        .push(`/company-groups/${companyGroupId}/financial-adjustments`),
      icon: ICONS.FINANCIAL_ADJUSTMENTS
    },
    {
      label: 'Empresas do grupo',
      onClick: () => navigate
        .push(`/companies?filters.companyGroupId=${companyGroupId}`),
      icon: ICONS.COMPANY
    },
  ]), [navigate])

  return (
    <WrapperPage>
      <CardLastAccessList
        accessId={'companyGroups'}
        actions={generateActions}
        icon={ICONS.COMPANY_GROUP}
      />

      <DataTable
        iconCard={ICONS.COMPANY_GROUP}
        requestResponseRootPath='data'
        actionsByLine={generateActions}

        fnRequest={{
          index: (filters) => companyGroupService.index({
            sort: ['-createdAt'],
            ...filters
          }),
        }}

        filter={{
          searchField: 'q',
        }}

        columns={[
          {
            path: 'name',
            sortable: true,
            title: 'NOME',
            width: '40%'
          },
          {
            path: 'supportEmail',
            sortable: true,
            title: 'EMAIL DA(O) VITTANA(O) RESPONSÁVEL',
            width: '40%'
          },
          {
            path: 'createdAt',
            sortable: true,
            title: 'CRIADO EM',
            type: 'dateTime',
            width: '20%'
          },
        ]}
      />
    </WrapperPage>
  )
}

export default List
