import { useCallback, useEffect, useState } from 'react'

import { useHistoryParams } from 'navigation'

import { Contract, ContractFilter, contractsService } from 'services/negotiations/endpoints/contracts'
import { negotiationsService } from 'services/negotiations/endpoints/negotiations'

import { ObjectType } from 'shareds/types'
import { format, parseISO } from 'date-fns'
import { companiesService } from 'services/negotiations'

type useContractsProp = {
  onCallToast: Function
}

const useContracts = ({ onCallToast }: useContractsProp): {
  useData: Contract[] | null
  useLoading: ObjectType
  onDelete: {
    negotiation: (id: string) => Promise<void>
    contract: (id: string, validUntil: string) => Promise<void>
  }
  onUnlinkContract: (id: string) => Promise<void>
} => {
  const { companyId } = useHistoryParams()
  const [useData, setData] = useState<Contract[] | null>([])
  const [useLoading, setLoading] = useState<ObjectType>({})

  const loadContracts = useCallback(async (): Promise<void> => {
    try {
      if (!companyId) {
        return
      }

      const filters: Partial<ContractFilter> = {
        'negotiations.costCenter.companyId': companyId,
        sort: ['-validUntil']
      }

      const contracts = (await contractsService.listAll(filters)) || []

      setData(contracts?.length ? contracts : null)
    } catch (e) {
      onCallToast('Oops', 'Erro ao carregar contratos', 'error')
      console.error(e)
      setData(null)
    }
  }, [companyId, onCallToast])

  const deleteNegotiation = async (negotiationId: string): Promise<void> => {
    setLoading({ [negotiationId]: true })

    try {
      await negotiationsService.remove(negotiationId)
      await loadContracts()
      onCallToast('Pronto', 'A negociação foi deletada', 'success')
    } catch (e) {
      console.error(e)
      onCallToast('Oops', 'Erro ao deletar negociação', 'error')
    } finally {
      setLoading({ [negotiationId]: false })
    }
  }

  const deleteContract = async (contractId: string, validUntil: string): Promise<void> => {
    setLoading({ [contractId]: true })

    try {
      await contractsService.update(contractId, {
        validUntil: format(parseISO(validUntil), 'dd/MM/yyyy'),
        status: 'cancelled'
      })

      await loadContracts()
      onCallToast('Pronto', 'A data de cancelamento foi aplicada', 'success')
    } catch (e) {
      console.error(e)
      onCallToast('Oops', 'Erro ao aplicar data de cancelamento do contrato', 'error')
    } finally {
      setLoading({ [contractId]: false })
    }
  }

  const unlinkContract = async (contractId: string): Promise<void> => {
    setLoading({ [contractId]: true })

    try {
      await companiesService.remove(`${companyId}/contracts/${contractId}`)
      await loadContracts()
      onCallToast('Pronto', 'O contrato foi desvinculado', 'success')
    } catch (e) {
      console.error(e)
      onCallToast('Oops', 'Erro ao desvincular contrato', 'error')
    } finally {
      setLoading({ [contractId]: false })
    }
  }

  useEffect(() => {
    (async () => loadContracts())()
  }, [loadContracts])

  return {
    useLoading,
    useData,
    onDelete: {
      negotiation: deleteNegotiation,
      contract: deleteContract,
    },
    onUnlinkContract: unlinkContract
  }
}

export default useContracts
