import React from 'react'
import {
  WrapperPage,
  DataTable,
  GridGroup,
  TextFieldValidate,
  SelectValidate,
  ChipSubstitle,
} from 'components'
import { useHistoryNavigator, useHistoryParams } from 'navigation'
import { monitoringStatus } from 'shareds/objects'
import { SelectOption } from 'shareds/types'
import { MonitoringFile, monitoringFilesService } from 'services/monitoring/endpoints/monitoring/files'
import { Button } from 'vkit/lib/components'
import ICONS from 'shareds/constants/icons'

const List: React.FC = () => {
  const navigate = useHistoryNavigator()
  const { companyId } = useHistoryParams()

  const getStatus = (): SelectOption[] => {
    return Object.entries(monitoringStatus).map(([value, text]) => ({ text, value }))
  }

  return (
    <WrapperPage>
      <DataTable
        fnRequest={{
          index: (filters) => monitoringFilesService.index({
            companyId,
            sort:'-uploadedAt',
            type: ['pc'],
            ...filters,
          })
        }}

        header={{
          right: (
            <Button
              onClick={() => navigate.push('cadastral-position/actions/create')}
              label='Importar base'
              color='default'
              icon={ICONS.UPLOAD}
            />
          )
        }}

        filter={{
          searchField: ({ useValues, onChange }) => (
            <GridGroup
              body={[
                {
                  component: (
                    <TextFieldValidate
                      small
                      name='q'
                      iconDir='right'
                      icon='search-outline'
                      label='Buscar'
                      useValues={useValues}
                      onChange={onChange}
                    />
                  )
                },
                {
                  component: (
                    <SelectValidate
                      autoRequest
                      data={getStatus()}
                      name='status'
                      onChange={onChange}
                      label='Status'
                      small
                      useValues={useValues}
                    />
                  )
                },
                {
                  component: (
                    <TextFieldValidate
                      small
                      name='createdAtMin'
                      label='Data'
                      useValues={useValues}
                      onChange={onChange}
                      type='date'
                    />
                  )
                },
              ]}
            />
          )
        }}

        columns={[
          {
            title: 'NOME DO ARQUIVO',
            path: 'name',
            sortable: true,
          },
          {
            custom: ({ status }: MonitoringFile) => (
              <ChipSubstitle
                term={status}
                substitles={{
                  'not-initialized': {
                    text: 'Não iniciado',
                    color: 'warning',
                  },
                  'in-process': {
                    text: 'Processando',
                    color: 'info',
                  },
                  processed: {
                    text: 'Processado',
                    color: 'success',
                  }
                }}
              />
            ),
            title: 'STATUS',
          },
          {
            title: 'DATA DE UPLOAD',
            path: 'uploadedAt',
            sortable: true,
            type: 'dateTime',
          },
        ]}

        actionsByLine={({ id: monitoringId }) => ([
          {
            label: 'Ver detalhes da posição cadastral',
            onClick: () =>
              navigate.push(
                `/companies/${companyId}/cadastral-position/${monitoringId}`
              ),
            icon: 'people-outline',
          },
          {
            label: 'Ver erros de grupos familiares',
            onClick: () =>
              navigate.push(
                `/companies/${companyId}/cadastral-position/${monitoringId}/family-groups`
              ),
            icon: 'people-outline',
          },
        ])}
      />
    </WrapperPage>
  )
}

export default List
