import { Logout } from 'pages/auth/logout'

const route = {
  path: '/logout',
  nameMenu: 'Sair',
  icon: 'log-out-outline',
  exact: true,
  component: Logout,
}

export default route
