import axios, { AxiosInstance } from 'axios'

type ObjectType = { [key: string]: any }
type RequestConfigType = (...item: any) => Promise<ObjectType | undefined>

interface IRestfulService {
  apiInstance: AxiosInstance
  post: RequestConfigType
  remove: RequestConfigType
  update: RequestConfigType
  get: RequestConfigType
}

type RestfulServiceType = {
  service: string
  uri: string
  headers?: ObjectType
}

const restfulService = (options: RestfulServiceType): IRestfulService => {
  const {
    uri,
    service,
    headers
  } = options
  
  const api = axios.create({
    baseURL: uri,
    timeout: 60000,
    headers
  })
  
  api.interceptors.response.use(
    response => response.data
  )

  const post = async (
    data: object,
    resource: string,
  ): Promise<ObjectType | undefined> =>
    api.request({
      url: !!resource ? `/${service}/${resource}` : `/${service}`,
      method: 'POST',
      data
    })

  const remove = async (
    id: number,
    resource: string,
  ): Promise<ObjectType | undefined> =>
    api.request({
      url: !!resource ? `/${service}/${resource}/${id}` : `/${service}/${id}`,
      method: 'DELETE',
    })
  
  const update = async (
    data: object,
    id: number,
    resource: string,
  ): Promise<ObjectType | undefined> =>
    api.request({
      url: !!resource ? `/${service}/${id}/${resource}` : `/${service}/${id}`,
      data,
      method: 'PATCH'
    })
  
  const get = async (
    params: object,
    resource: string,
  ): Promise<ObjectType | undefined> =>
    api.request({
      url: !!resource ? `/${service}/${resource}` : `/${service}`,
      params
    })

  return {
    apiInstance: api,
    post,
    remove,
    update,
    get,
  }
}

export default restfulService
