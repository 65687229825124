import { useMemo } from 'react';
import { TFormData } from '../types/FormData.type';

export type ComponentsType = { [key: string]: any }

interface IUseComponentsVisibility {
  showMovimentationType: boolean,
  showDownloadUploadField: boolean,
  showAttachmentField: boolean,
  showFormComponents: boolean,
  showSubmitError: boolean,
  showSubmitSuccess: boolean
}

type UseComponentsVisibilityProps = {
  formData: TFormData,
  hasError: boolean,
  hasSuccess: boolean,
}

const useComponentsVisibility = ({
  formData,
  hasError,
  hasSuccess
}: UseComponentsVisibilityProps): IUseComponentsVisibility => {

  const showMovimentationType = useMemo(() => {
    return !!formData.provider
  }, [formData]);

  const showDownloadUploadField = useMemo(() => {
    return !!formData.movimentationType
  }, [formData]);

  const showAttachmentField = useMemo((): boolean => {
    const providerName = formData?.provider?.name.toLowerCase() || '';
    return showDownloadUploadField && !providerName.includes('bradesco');
  }, [formData?.provider?.name, showDownloadUploadField]);

  const showFormComponents = useMemo(() => {
    return !hasError && !hasSuccess;
  }, [hasError, hasSuccess]);

  const showSubmitError = useMemo(() => {
    return hasError && !hasSuccess
  }, [hasError, hasSuccess]);

  const showSubmitSuccess = useMemo(() => {
    return !hasError && hasSuccess
  }, [hasError, hasSuccess]);

  return {
    showMovimentationType,
    showDownloadUploadField,
    showAttachmentField,
    showFormComponents,
    showSubmitError,
    showSubmitSuccess
  }
}

export default useComponentsVisibility;
