import { useState, useEffect, useCallback } from 'react';
import { useHistoryParams } from 'navigation'

import providersService, { Provider } from 'services/negotiations/endpoints/providers'

type SelectableProviderList = {
  text: string,
  value: string,
}

interface IUseProviders {
  selectableProviderList: SelectableProviderList[],
  getProvider: (providerId: string) => Provider | null,
}

const useProviders = (): IUseProviders => {

  const { companyId } = useHistoryParams();

  const [list, setList] = useState<Provider[]>([])

  const [selectableProviderList, setProvidersList] = useState<SelectableProviderList[]>([]);

  const getProvidersList = useCallback(async (): Promise<void> => {
    try {
      const providers = await providersService.list({
        'products.negotiations.costCenter.companyId': companyId
      });
      const selectable = providers.map((provider) => {
        return {
          text: provider.name,
          value: provider.id,
        };
      });
      setList(providers);
      setProvidersList(selectable);
    } catch (e) {
      console.error(e);
    }
  }, [companyId]);

  const getProvider = (providerId: string ): Provider | null => {
    const provider = list.find((origin: Provider) => origin.id === providerId);
    if (provider) {
      return provider
    }
    return null;
  }

  useEffect(() => {
    getProvidersList();
  }, [getProvidersList]);

  return {
    selectableProviderList,
    getProvider
  }
}

export default useProviders;
