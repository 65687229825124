import restfulBase from 'services/negotiations/base'

export type Address = {
  id?: string
  zipCode: string
  city: string
  companyId: string
  complement: string
  neighborhood: string
  number: string
  location: string
  state: string
}

export const addressesService = restfulBase('addresses')

export default addressesService
