import React from 'react';

import { MarkupsModel } from '../Models/Markups.model';
import { MarkupService, SetMarkupsModel } from '../services/MarkupService';
import { Markup, MarkupContextType } from '../types';

export const MarkupsContext = React.createContext<MarkupContextType | null>(null);

export type MarkupsProviderProps = React.ReactNode & {
  idNegotiation: string
}

const MarkupsProvider: React.FC<MarkupsProviderProps> = (
  {
    idNegotiation,
    children
  }) => {

  const [markups, setMarkups] = React.useState<Markup[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const getCurrentMarkups = React.useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const resp = await MarkupService(idNegotiation).list();
      const normalizedList = SetMarkupsModel(MarkupsModel, resp);
      setMarkups(normalizedList);
    } catch (e) {
      console.error('Error: ', e);
    } finally {
      setLoading(false);
    }
  }, [idNegotiation])

  React.useEffect(() => {
    (async () => {
      getCurrentMarkups()
    })();
  }, [getCurrentMarkups])

  return (
    <MarkupsContext.Provider value={{ markups, loading }}>
      { children }
    </MarkupsContext.Provider>
  );
}

export default MarkupsProvider;
