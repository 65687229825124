import React from 'react'
import usePagination from './hooks/usePagination'
import { Grid } from 'vkit/lib/context'
import { ButtonGroup } from 'components/index'

interface PaginationInterface {
  page: number
  total: number
  itemsPerPage: number
  onChange: Function
  countVisiblePages?: number
}

const Pagination: React.FC<PaginationInterface> = ({
  page,
  total,
  itemsPerPage,
  onChange,
  countVisiblePages = 7,
}) => {
  const { usePages } = usePagination({
    page,
    total,
    itemsPerPage,
    onChange,
    countVisiblePages,
  })

  return total ? (
    <Grid alignContent='center' alignItems='center'>
      <div
        style={{
          backgroundColor: 'rgba(0, 123, 255, 0.1)',
          borderRadius: 8,
        }}
      >
        <ButtonGroup
          solo
          active={usePages.findIndex(({ value }) => +page === +value)}
          buttons={usePages.map((item) => ({
            label: item.value,
            onClick: item.onClick,
          }))}
        />
      </div>

    </Grid>
  ) : null
}

export default Pagination
