import React from 'react'

import { Select } from 'vkit/lib/components'

import { UploadContextType } from '../../types/UploadContext.types';
import { UploadContext } from '../../context/UploadContext';
import { FormDataSteps } from '../../enum/FormDataSteps';
import { useProviders } from '../../hooks';

const SelectProvider: React.FC<React.ReactNode> = () => {

  const { formData, setData, isLoading } = React.useContext(UploadContext) as UploadContextType;

  const {
    getProvider,
    selectableProviderList
  } = useProviders();

  const onChange = (value: string): void => {
    if (value) {
      setData(FormDataSteps.provider, getProvider(value));
    }
  }

  return (
    <Select
      disabled={ isLoading }
      data={ selectableProviderList }
      label='Operadora'
      onChange={ onChange }
      placeholder='Selecione uma operadora'
      required
      searchable
      value={ formData?.provider?.id || '' }
    />
  )
};

export default SelectProvider;
