import React from 'react';

import { Button, Dialog, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

import MarkupsProvider from '../../context/MarkupsContext';

import {
  MarkupHeader,
  MarkupHeaderInfo,
  MarkupsList
} from '../../components';

export interface DialogManageMarkupsProps {
  headerInfos: MarkupHeaderInfo[]
  idNegotiation: string
  open: boolean
  onClose: Function,
  onCallToast: Function,
}

const DialogManageMarkups: React.FC<DialogManageMarkupsProps> = ({
  headerInfos,
  idNegotiation,
  open,
  onClose,
}) => {
  const useRefDialog = React.useRef({ open: () => ({}), dismiss: () => ({}) })

  React.useEffect(() => {
    if (open !== undefined) {
      open ? useRefDialog.current.open() : useRefDialog.current.dismiss()
    }
  }, [open, headerInfos])

  return (
    <Dialog
      elevation={4}
      instanceEmitter={useRefDialog.current}
      outlined
      rounded
      onClose={ onClose }
      size="xLarger"
      persistent
      head={<Text size='larger' textAlign='center' title value='Markups' />}
      footer={
        <Grid alignContent='right' gap={8}>
          <Button label='Fechar' color='default' onClick={() => onClose() } />
        </Grid>
      }
    >
      <MarkupHeader infos={ headerInfos }/>
      <MarkupsProvider
        idNegotiation={ idNegotiation }
      >
        <MarkupsList />
      </MarkupsProvider>
    </Dialog>
  )
}

export default DialogManageMarkups;
