import jwtDecode from 'jwt-decode'

export const getToken = (): string | null => localStorage.getItem('token')

export const decodedJwtToken = (token = getToken()): any | null => {
  if (token) return jwtDecode(token)

  return null
}

export const removeStoredData = (): void => {
  localStorage.removeItem('token')
}

export const isAuthenticated = (): boolean => {
  const data = decodedJwtToken()
  if (data) {
    return Date.now() <= data?.exp * 1000
  }

  if (getToken()) {
    removeStoredData()
  }

  return false
}
