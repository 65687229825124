import restfulBase from 'services/accessControl/base'

export interface AuthConfirmEmail {
  code: string
  email: string
  newPassword: string
  userId: string
}

export interface AuthConfirmEmailResponse {
  token: string
}

export const authConfirmEmailInitState = {
  code: '',
  email: '',
  newPassword: '',
  userId: '',
}

export const authConfirmEmailService =
  restfulBase<AuthConfirmEmail, AuthConfirmEmailResponse>('auth/email-confirmations')
