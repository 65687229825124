import React from 'react'
import { Select } from 'vkit/lib/components'
import { CardLastAccessList, DataTable, GridGroup, TextFieldValidate, WrapperPage } from 'components'
import { useHistoryNavigator } from 'navigation'
import { getToken } from 'utils/authentication'
import { Company } from 'services/negotiations/endpoints/companies'
import { companiesService } from 'services/negotiations'
import { PERMISSIONS } from 'utils/checkPermission'
import ICONS from 'shareds/constants/icons'
import { Action } from 'components/dataTable'

const List: React.FC = () => {
  const navigate = useHistoryNavigator()

  const genarateActions = (company: Company): Action[] => ([
    {
      label: 'Ver detalhes',
      onClick: () => navigate.push(`/companies/${company.id}`),
      icon: 'eye-outline',
    },
    {
      label: 'Importar base',
      onClick: () =>
        navigate.push(`/companies/${company.id}/cadastral-position/actions/create`),
      icon: ICONS.UPLOAD,
    },
    {
      label: 'Ver beneficiários',
      onClick: () => navigate.push(`/companies/${company.id}/beneficiaries`),
      icon: ICONS.BENEFICIARIES,
    },
    {
      label: 'Posições cadastrais',
      onClick: () => navigate.push(`/companies/${company.id}/cadastral-position`),
      icon: ICONS.CADASTRAL_POSITION
    },
    {
      label: 'Ver contratos',
      onClick: () => navigate.push(`/companies/${company.id}/contracts`),
      icon: ICONS.CONTRACT
    },
    {
      label: 'Ver usuarios',
      onClick: () => navigate.push(`/companies/${company.id}/users`),
      icon: ICONS.USERS,
      permissions: [
        PERMISSIONS.COMPANY_USER_READ
      ],
    },
    {
      label: 'Ver movimentações',
      onClick: () => navigate.push(`/companies/${company.id}/moves`),
      icon: ICONS.MOVE,
      permissions: [
        PERMISSIONS.MOVE_CREATE
      ],
    },
  ])

  return (
    <WrapperPage>
      <CardLastAccessList
        accessId={'companies'}
        actions={genarateActions}
        icon={ICONS.COMPANY}
      />

      <DataTable
        actionsByLine={genarateActions}
        requestResponseRootPath='results'

        fnRequest={{
          index: (filters) => companiesService.index({
            sort: ['-createdAt'],
            ...filters,
          })
        }}

        filter={{
          searchField: ({ useValues, onChange }) => (
            <GridGroup
              body={[
                {
                  component: (
                    <TextFieldValidate
                      small
                      name='q'
                      iconDir='right'
                      icon='search-outline'
                      label='Buscar '
                      useValues={useValues}
                      onChange={onChange}
                    />
                  )
                },
                {
                  component: (
                    <Select
                      clearable
                      autoRequest
                      onChange={(value) => onChange('companyGroupId', value)}
                      label="Grupo de empresas"
                      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
                      requestPageParam='page'
                      requestResponseRootPath='data'
                      requestResponseText='name'
                      requestResponseValue='id'
                      requestRouter='/company-groups'
                      requestSearchParam='q'
                      requestParams={{ q: useValues.companyGroupId }}
                      requestUri={ process.env.REACT_APP_API_NEGOTIATIONS_URL }
                      searchable
                      small
                      value={useValues.companyGroupId}
                    />
                  )
                },
                {
                  component: (
                    <TextFieldValidate
                      small
                      name='cnpj'
                      iconDir='right'
                      icon='search-outline'
                      label='CNPJ'
                      useValues={useValues}
                      onChange={onChange}
                      mask='99.999.999/9999-99'
                    />
                  )
                },
              ]}
            />
          )
        }}

        columns={[
          {
            path: 'name',
            sortable: true,
            title: 'NOME',
            width: '35%',
          },
          {
            path: 'companyGroup.name',
            title: 'GRUPO',
            width: '30%',
          },
          {
            path: 'cnpj',
            sortable: true,
            title: 'CNPJ',
            type: 'cnpj',
            width: '15%',
          },
          {
            path: 'createdAt',
            sortable: true,
            title: 'CRIADA EM',
            type: 'dateTime',
            width: '20%',
          },
        ]}
      />
    </WrapperPage>
  )
}

export default List
