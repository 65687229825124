import React from 'react'
import { ContentList } from './components'
import { Box } from 'vkit/lib/context'
import { FinancialAdjustment } from 'services/negotiations/endpoints/financialAdjustments'

export interface ListProps {
  useData: FinancialAdjustment[]
  onDelete: (financialAdjustmentId: string) => void
}

const List: React.FC<ListProps> = ({
  useData,
  onDelete,
}) => (
  <Box type='sheet' elevation={4} outlined rounded>
    <ContentList
      onDelete={onDelete}
      data={useData}
    />
  </Box>
)

export default List
