import React from 'react'
import { WrapperPage, SelectValidate, TextFieldValidate, GridGroup, DataTable } from 'components'
import useBeneficiaries from './hooks/useBeneficiaries'
import { Chip, Text } from 'vkit/lib/components'
import { getToken } from 'utils/authentication'
import { Beneficiary } from 'services/beneficiaries/endpoints/beneficiaries'
import formatText from 'utils/formatText'
import { Grid } from 'vkit/lib/context'
import { statusList } from 'shareds/objects/beneficiaryStatus'

const List: React.FC = () => {
  const {
    useEntityByNegotiationId,
    getBeneficiaries,
    useContracts,
    useTypeOptions,
    useStatusOptions,
  } = useBeneficiaries()

  return (
    <WrapperPage>
      <DataTable
        showAlternativeType
        showSettings
        iconCard='person-outline'
        fnRequest={{
          index: getBeneficiaries
        }}
        filter={{
          searchField: ({ useValues, onChange }) => (
            <GridGroup
              body={[
                {
                  component: (
                    <TextFieldValidate
                      small
                      label='Nome'
                      name='name'
                      iconDir='right'
                      icon='search-outline'
                      useValues={useValues}
                      onChange={onChange}
                    />
                  )
                },
                {
                  component: (
                    <SelectValidate
                      small
                      name='benefitId'
                      searchable
                      label='Benefício'
                      useValues={useValues}
                      requestPageParam='page'
                      requestResponseRootPath='data'
                      requestResponseText='name'
                      requestResponseValue='id'
                      requestRouter='/benefits'
                      requestSearchParam='q'
                      autoRequest
                      requestUri={ process.env.REACT_APP_API_NEGOTIATIONS_URL }
                      requestHeaders={{ authorization: `Bearer ${getToken()}` }}
                      onChange={onChange}
                    />
                  )
                },
                {
                  component: (
                    <TextFieldValidate
                      small
                      name='cpf'
                      iconDir='right'
                      icon='search-outline'
                      label='CPF'
                      useValues={useValues}
                      onChange={onChange}
                      mask='999.999.999-99'
                    />
                  )
                },
              ]}
            />
          ),
          fields: ({ useValues, onChange }) => (
            [
              {
                component: (
                  <SelectValidate
                    small
                    name='contractId'
                    searchable
                    data={useContracts}
                    label='Contrato'
                    useValues={useValues}
                    onChange={onChange}
                  />
                )
              },
              {
                component: (
                  <SelectValidate
                    small
                    name='status'
                    data={useStatusOptions}
                    label='Status'
                    useValues={useValues}
                    onChange={onChange}
                  />
                )
              },
              {
                component: (
                  <SelectValidate
                    small
                    name='type'
                    data={useTypeOptions}
                    label='Tipo'
                    useValues={useValues}
                    onChange={onChange}
                  />
                )
              },
              {
                component: (
                  <TextFieldValidate
                    small
                    name='validFrom'
                    label='Início de vigência'
                    useValues={useValues}
                    onChange={onChange}
                    type='date'
                  />
                )
              },
              {
                component: (
                  <TextFieldValidate
                    small
                    name='updatedAt'
                    label='Data de alteração'
                    useValues={useValues}
                    onChange={onChange}
                    type='date'
                  />
                )
              },
              {
                component: (
                  <TextFieldValidate
                    small
                    name='validUntil'
                    label='Fim de vigência'
                    useValues={useValues}
                    onChange={onChange}
                    type='date'
                  />
                )
              },
            ]
          )
        }}
        columns={[
          {
            title: 'NOME',
            custom: ({ id, life, beneficiaryHolder }: Beneficiary) => (
              <>
                {life?.name}
                {
                  beneficiaryHolder && (
                    <Text
                      color={beneficiaryHolder.holderId === id ? 'success' : 'default'}
                      value={beneficiaryHolder.holderId === id ? 'Titular' : 'Dependente'}
                      rounded
                      size='minor'
                      title
                    />
                  )
                }
              </>
            ),
          },
          {
            title: 'CPF',
            custom: ({ life }: Beneficiary) => {
              const cpf = life?.documents.find((doc) => doc.type === 'cpf')
              return (
                <>
                  {formatText(cpf?.value || '', 'cpf')}
                </>
              )
            },
          },
          {
            title: 'STATUS',
            custom: ({ status }: Beneficiary) => (
              <Grid>
                <Chip
                  rounded
                  color='light'
                  label={statusList[status]}
                  size='small'
                />
              </Grid>
            )
          },
          {
            title: 'CARTEIRINHA',
            path: 'healthCard.number',
          },
          {
            title: 'CONTRATO',
            custom: ({ negotiationId }: Beneficiary) =>
              (useEntityByNegotiationId.contract[negotiationId]?.code)
          },
          {
            title: 'BENEFÍCIO',
            custom: ({ negotiationId }: Beneficiary) =>
              (useEntityByNegotiationId.product[negotiationId]?.benefit.name)
          },
          {
            title: 'INÍCIO VIGÊNCIA',
            path: 'validFrom',
            type: 'date',
          },
          {
            title: 'ALTERADO EM',
            path: 'updatedAt',
            type: 'date',
          },
          {
            title: 'FIM VIGÊNCIA',
            path: 'validUntil',
            type: 'date',
          },
        ]}
      />
    </WrapperPage>
  )
}

export default List
