import React, { useRef, useState } from 'react'
import { Grid, Row } from 'vkit/lib/context'
import { Icon, Loader, Text } from 'vkit/lib/components'
import { BoxInfo, ShowHide } from 'components'

const {
  REACT_APP_ORIGIN_URL_USER = '',
} = process.env

interface IFrameUsersProps {
  breadcrumb?: {
    name: string
    href?: string
  }[]
  loading?: boolean
  scopeId: string
  scopeType: 'broker' | 'company'
  title?: string
}

const IFrameUsers: React.FC<IFrameUsersProps> = (
  {
    breadcrumb,
    loading= false,
    scopeId,
    scopeType,
    title,
  }
) => {
  const useFrame = useRef<HTMLIFrameElement | null>()
  const [useShowError, setShowError] = useState(false)
  const [useIFrameLoading, setIFrameLoading] = useState(true)
  const sendTokenToFrame = (): void => {
    if (!useFrame.current?.contentWindow) {
      return setShowError(true)
    }

    useFrame.current.contentWindow.postMessage({
      'vkit-theme': localStorage.getItem('vkit-theme'),
      breadcrumb: breadcrumb && JSON.stringify(breadcrumb),
      title,
      token: localStorage.getItem('token'),
    }, REACT_APP_ORIGIN_URL_USER)
  }
  const onLoad = (): void => {
    sendTokenToFrame()
    setIFrameLoading(false)
  }

  return (
    <>
      <ShowHide transition='fade' visible={loading || useIFrameLoading}>
        <Loader color='default' full/>
      </ShowHide>

      <ShowHide visible={!useShowError && !loading}>
        <iframe
          src={`${REACT_APP_ORIGIN_URL_USER}/${scopeType}/${scopeId}/users`}
          ref={(frame) => useFrame.current = frame}
          onLoad={onLoad}
          style={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            right: 0
          }}
        />
      </ShowHide>

      <ShowHide visible={useShowError}>
        <BoxInfo>
          <Row>
            <Grid alignContent='center'>
              <Icon color='danger' size={64} name='close-circle-outline'/>
            </Grid>
          </Row>

          <Row style={{ textAlign: 'center' }}>
            <Text title color='danger' value='Erro ao carregar a página!' margin='0 0 8px'/>

            <Text
              size='medium'
              value='Parece que tivemos algum problema<br>ao carregar essa página, tente de novo mais tarde.'
            />
          </Row>
        </BoxInfo>
      </ShowHide>
    </>
  )
}

export default IFrameUsers
