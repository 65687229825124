import React from 'react'
import { IFrameUsers, WrapperPage } from 'components'
import { useHistoryParams } from 'navigation'
import useGetCompany from 'shareds/hooks/useGetCompany'

const List: React.FC = () => {
  const { companyId } = useHistoryParams()
  const { useCompany } = useGetCompany(companyId)

  return (
    <WrapperPage>
      <IFrameUsers
        loading={!useCompany.name}
        scopeType='company'
        scopeId={companyId}
        breadcrumb={[
          {
            name: 'Empresas',
            href: `${location.origin}/companies`
          },
          {
            name: useCompany.name,
            href: `${location.origin}/companies/${companyId}`
          },
        ]}
      />
    </WrapperPage>
  )
}

export default List
