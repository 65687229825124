import restfulService from '../restful'

export const restfulBase = {
  find: async (cep: string): Promise<any> => {
    let errorResponse = null

    const handleError = (error: Response): void => {
      errorResponse = error
    }

    const { get } = restfulService()

    const response = await get(cep, handleError)

    if (!response) {
      throw new Error('Falha na requisição')
    }

    if (errorResponse) return errorResponse

    return response?.data || response
  },
}

export default restfulBase
