import React from 'react'

import { Text, Button } from 'vkit/lib/components'
import { Grid } from 'vkit/lib/context'

import { downloadFileLink } from 'utils/common'

import { UploadContextType } from '../../types/UploadContext.types';
import { UploadContext } from '../../context/UploadContext';

import { useDownloadTemplate } from '../../hooks';
import { FormDataSteps } from '../../enum/FormDataSteps';

const TemplateDownload: React.FC<React.ReactNode> = () => {

  const {
    formData,
    setData,
    triggerToast
  } = React.useContext(UploadContext) as UploadContextType;

  const { getDownloadLink } = useDownloadTemplate();

  const [downloadLink, setDownloadLink] = React.useState<string | null>(null);

  const handleDownload = React.useCallback((): void => {
    if (downloadLink) {
      setData(FormDataSteps.templateDownloaded, true);
      downloadFileLink(downloadLink);
    } else {
      triggerToast('Oops!', 'Falha ao baixar arquivo.', 'error')
    }
  }, [downloadLink, setData, triggerToast])

  React.useEffect(() => {
    setDownloadLink(getDownloadLink(formData));
  }, [formData, getDownloadLink])

  return (
    <div>
      <Text
        value='Clique no botão abaixo para obter o layout da planilha'
        title
        padding='0 0 18px'
      />
      <Grid margin='0 0 24px 0'>
        <Button
          color='default'
          label='Baixar'
          onClick={() => handleDownload()}
          disabled={ !downloadLink }
        />
      </Grid>
    </div>
  )
};

export default TemplateDownload;
