import restfulBase from 'services/accessControl/base'
import { ObjectType } from 'shareds/types'

export interface AuthPasswordRecoveryUsers {
  code: string
  userId: string
}

export const authPasswordRecoveryUsersInitState = {
  code: '',
  userId: '',
}

export const authPasswordRecoveryUsersError: ObjectType = {
  'Password recovery confirmation .* failed because tried after expiration time.':
    'Tempo de validade de alteração de senha expirou.',
}

export const authPasswordRecoveryUsersService =
  restfulBase<AuthPasswordRecoveryUsers>('auth/password-recovery/users')
