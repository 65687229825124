import React, { useRef, useEffect } from 'react'
import { Dialog, Text, Button } from 'vkit/lib/components'
import { Grid } from 'vkit/lib/context'

type DialogConfirmType = {
  open?: boolean
  title?: string
  text?: string
  fnCallback?: (response: boolean) => void
}

const DialogConfirm: React.FC<DialogConfirmType> = (
  {
    open,
    title,
    text,
    fnCallback
  }
) => {
  const useRefDialog = useRef({ open: () => ({}), dismiss: () => ({}) })

  useEffect(() => {
    if (open !== undefined) {
      open ? useRefDialog.current.open() : useRefDialog.current.dismiss()
    }
  }, [open])

  return (
    <Dialog
      shadow
      outlined
      rounded
      persistent
      instanceEmitter={useRefDialog.current}
      elevation={2}
      size='small'
      title={
        <Text
          value={title || 'Atenção'}
          title
          size='medium'
          textAlign='left'
          iconSize={20}
        />
      }

      body={
        <Text textAlign='left' value={text || 'Adiocione um texto'} size='medium' />
      }

      footer={
        <Grid alignContent='right' gap={8}>
          <Button label='Deixa pra lá' solo color='light' onClick={() => fnCallback?.(false)} />
          <Button label='Quero' color='default' onClick={() => fnCallback?.(true)} />
        </Grid>
      }
    />
  )
}

export default DialogConfirm
