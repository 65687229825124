const ICONS = {
  ADDRESS: 'pin-outline',
  ATTACHMENTS: 'attach-outline',
  BENEFICIARIES: 'people-outline',
  CADASTRAL_POSITION: 'file-outline',
  COMPANY: 'briefcase-outline',
  COMPANY_GROUP: 'grid-outline',
  CONTRACT: 'clipboard-outline',
  DETAILS: 'eye-outline',
  EDIT: 'edit-outline',
  FAMILY_GROUP: 'people-outline',
  FINANCIAL_ADJUSTMENTS: 'options-outline',
  LOGOUT: 'log-out-outline',
  MOVE: 'flip-2-outline',
  NEGOTIATION: 'inbox-outline',
  UPLOAD: 'cloud-upload-outline',
  USER: 'person-outline',
  USERS: 'people-outline',
}

export default ICONS
